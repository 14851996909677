import _ from 'lodash';

import SpotlightService from './SpotlightService';
import ScheduledMediaService from './ScheduledMediaService';
import StateService from './StateService';

const TakeoverService = {
    timeout_schedules_check: 5000,
    timeout_schedule_id: null,
    getNextTakeOverSchedule: function () {
        let takeover_media_items = ScheduledMediaService.getActiveItemsByBehavior('takeover');
        let takeover_spotlights = SpotlightService.getActiveFullScreenSpotlights(true);

        StateService.takeover_failed_states.forEach( failed_state => {
            const schedule_type = _.get(failed_state, 'state.takeoverData.schedule_type');
            const schedule_id = _.get(failed_state, 'state.takeoverData.id');
            takeover_media_items = takeover_media_items.filter( schedule => schedule.id !== schedule_id && schedule.schedule_type !== schedule_type);
            takeover_spotlights = takeover_spotlights.filter( schedule => schedule.id !== schedule_id && schedule.schedule_type !== schedule_type);
        });

        if (!!takeover_spotlights[0]) {
            const first_sch_org = takeover_spotlights[0].organization_id;
            const same_org_schedules = takeover_spotlights.filter(schedule => schedule.organization_id === first_sch_org);
            const same_org_schedules_sorted = _.reverse(_.sortBy(same_org_schedules, ["created_at"]));
            let takeover_schedule = same_org_schedules_sorted[0];
            return takeover_schedule;
        }

        return takeover_media_items[0];
    },
    cleanTimeout: function () {
        clearTimeout(TakeoverService.timeout_schedule_id);
    },
    loadTakeoverService: function () {
        // Start running "thread" to check every 10 sec if the ui must avance state so a new schedule has to take over the screen or leave it.
        TakeoverService.timeout_schedule_id = setTimeout(() => {
            const play_full_video = _.get(StateService, "currentStateDef.takeoverData.play_full_video", false);

            if ( !play_full_video ) {
                TakeoverService.shouldAdvanceState();
            }

            TakeoverService.loadTakeoverService();
        }, TakeoverService.timeout_schedules_check);
    },
    shouldAdvanceState: function () {
        const next_takeover_schedule = TakeoverService.getNextTakeOverSchedule();
        const is_current_state_takeover = _.get(StateService, 'currentStateDef.takeover', false);
        const current_schedule_type = _.get(StateService, 'currentStateDef.takeoverData.schedule_type');
        const current_schedule_id = _.get(StateService, 'currentStateDef.takeoverData.id');
        const next_schedule_id = _.get(next_takeover_schedule, 'id');
        const next_schedule_type = _.get(next_takeover_schedule, 'schedule_type');

        if (!is_current_state_takeover && !!next_takeover_schedule) {
            StateService.advanceState();
        } else if (!!next_takeover_schedule && !!current_schedule_type &&
                ((next_schedule_id !== current_schedule_id && next_schedule_type === current_schedule_type)
                || (next_schedule_type !== current_schedule_type))) 
        {
            StateService.advanceState();
        } else if (!next_takeover_schedule && is_current_state_takeover) {
            // leave the screen
            StateService.advanceState();
        }
    }
}

export default TakeoverService;