import './debugger.scss';
import * as UP from '../../Upshow';

const Debugger = {
    logs: [],
    attached: false,

    attach: function () {
        if (Debugger.attached) {
            return;
        }
        Debugger.attached = true;


        let div = document.createElement('div');
        div.className = 'debugger';
        div.setAttribute('id', 'debugger-messages');
        document.body.insertBefore(div, document.getElementById("root"));

        window.onerror = function (message, url, lineNumber) {
            Debugger.log(`Error: ${message}, Url: ${url}, Line: ${lineNumber}`, 'error');
            return false;
        };

        Debugger.takeOverConsole();
    },

    takeOverConsole: function () {
        const console = window.console;
        if (!console) {
            Debugger.log("No console");
            return;
        } else {
            Debugger.log("Attached to console");
        }

        function intercept(method) {
            const original = console[method];
            console[method] = function () {
                // do sneaky stuff
                if (original.apply) {
                    // Do this for normal browsers
                    original.apply(console, arguments)
                }

                const arg = Array.from(arguments);
                Debugger.log(`${method} - ${arg}`, method);
            }
        }

        const methods = ['log', 'warn', 'error', 'info'];
        for (let i = 0; i < methods.length; i++) {
            intercept(methods[i])
        }
    },



    log: function (msg, level) {
        Debugger.logs.unshift({ msg: msg, level: level });
        Debugger.logs = Debugger.logs.slice(0, 11);

        const deviceInfo = UP.getDeviceInfo();

        const info = `
            <div class="dinfo">${deviceInfo.model} - ${deviceInfo.serial} - ${deviceInfo.version} - ${deviceInfo.uiVersion}<br>
            ${deviceInfo.userAgent}
            </div>        
`;

        document.getElementById('debugger-messages').innerHTML = info + Debugger.logs.reduce((p, v) => {
            const className = v.level === 'error' ? 'error' : '';
            return `${p}<div class='${className}'>${v.msg}</div>`;
        }, '');
    }
};

export default Debugger;