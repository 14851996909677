import React from 'react';
import './Iframe.scss';

export default class Iframe extends React.Component {
    getIframeRef() {
        return this.iframe;
    }

    render() {
        return (
            <div className="iframe">
                <iframe ref={(iframe) => { this.iframe = iframe; }} src={this.props.src} title={this.props.src}/>
            </div>
        );
    }

    componentWillUnmount(){
        this.iframe = null;
    }
}