import UpshowLogger from '../Logger'
import {getCurrentTimestamp} from '../Upshow'

class WatchDog {
    constructor() {
        this.lastPlayingTime = 0;
        this.lastPlayedSeconds = 0;
        this.playErrors = -1;

    }

    watchdogOK = (playing, playStart, playedSeconds, errorCallback) => {
        if (playing) {
            let currentTime = getCurrentTimestamp();


            let playingTime = (currentTime - playStart) / 1000;
            //            console.log('watchdog', 'playing', { playingTime }, 'isnan', isNaN(playedSeconds));
            if (playingTime > 3) {

                let elapsed = playingTime - this.lastPlayingTime;
                let advanced = playedSeconds - this.lastPlayedSeconds;
                if (advanced < elapsed * 0.9) {
                    this.playErrors++;
                } else if (this.playErrors > 0){
                    this.playErrors--;
                }

                //                console.log('watchdog', 'playing', { playingTime, advanced, elapsed, errors: this.playErrors });
                if (this.playErrors > 7 || isNaN(playedSeconds)) {
                    console.error('watchdog', 'Watchdog found error ', {
                        errorCallback,
                        advanced: advanced,
                        elapsed: elapsed,
                        errors: this.playErrors,
                        playedSeconds: playedSeconds
                    });
                    if (errorCallback) {
                        UpshowLogger.error('watchdog', 'Watchdog Error , calling error Callback ');
                        errorCallback('watchdog');
                    }
                    return false;
                }

                this.lastPlayingTime = playingTime;
                this.lastPlayedSeconds = playedSeconds;

            }
        }
        return true;
    }


}

export default WatchDog;
