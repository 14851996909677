import React from "react";
import PropTypes from "prop-types";

import "./ZoomImageCta.scss";
import UPshowLogger from "../../../../Logger";
import {teslaCacheX} from "../../../../Upshow";

class ZoomImageCta extends React.Component {

    constructor(props) {
        super(props);
        this.onImageError = this._onImageError.bind(this);
    }

    _onImageError(error) {
        UPshowLogger.error('ZoomImageCta', 'Error loading cta custom image. ' + error.message);
        this.props.onError();
    }

    render() {
        const { flipperRef, imageUrl, postAuthor, postNetwork} = this.props;

        const url = teslaCacheX(imageUrl);

        return (
            <div className="ZoomImageCta" ref={flipperRef}>

                <img
                    className='image'
                    src={url}
                    onError={this.onImageError}
                    alt=''
                />

                {!!postAuthor && 
                    <div className="attribution"> 
                        <div className="wrapper">
                            <i className={'icon-' + postNetwork}/> {postAuthor}
                        </div>
                    </div>
                }
            </div>
        );

    }
}

ZoomImageCta.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    postNetwork: PropTypes.string.isRequired,
    postAuthor: PropTypes.string.isRequired,
    flipperRef: PropTypes.func,
    onError: PropTypes.func,
};

ZoomImageCta.defaultProps = {
    flipperRef: () => null,
    onError: () => null,
};

export default ZoomImageCta;