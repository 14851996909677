import React from "react";
import PropTypes from "prop-types";
import {TimelineLite, TweenLite} from "gsap";

import "./ZoomCta.scss";
import ZoomImageCta from "./ZoomImageCta/ZoomImageCta";
import ZoomTextCta from "./ZoomTextCta/ZoomTextCta";
import SettingsService from "../../../services/SettingsService";
import HwFeatureService from "../../../services/HwFeatureService";

class ZoomCta extends React.Component {

    constructor(props) {
        super(props);

        this.cta = SettingsService.getCta();

        const showCaptionOnZoom = !!Number.parseInt(SettingsService.getUiSetting('show_caption_on_zoom'), 10);
        const imageUrl = SettingsService.getCtaImage(props.isVertical ? 'vertical-zoom' : 'zoom');

        this.state = {
            imageUrl: imageUrl,
            shouldFlip: showCaptionOnZoom && props.postDescription,
        };

        this.onError = this._onError.bind(this);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.isVertical !== this.props.isVertical) {
            this.onOrientationChange();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.playing && nextProps.playing !== this.props.playing && this.state.shouldFlip && !this.state.imageUrl) {
            this.flipper();
        }
    }

    onOrientationChange() {
        const imageUrl = SettingsService.getCtaImage(this.props.isVertical ? 'vertical-zoom' : 'zoom');
        this.setState({imageUrl});
    }

    flipper() {
        if (HwFeatureService.isSupported('caption_flip')) {
            const delay = this.props.duration / 2;

            TweenLite.set(this.flipperContainerRef, {transformStyle: 'preserve-3d', perspective: 900});
            TweenLite.set(this.flipperCtaRef, {rotationY: 180});
            TweenLite.set(this.flipperDescriptionRef, {rotationY: 0});

            const tl = new TimelineLite({paused: true});

            tl.to(this.flipperContainerRef, 1, {rotationY: -180, delay: delay})
                .play();
        }
    }


    _onError() {
        this.setState({imageUrl: null});
    }

    render() {
        const { postAuthor, postDescription, postNetwork} = this.props;
        const {imageUrl} = this.state;

        if (imageUrl) {
            return (
                <div className="ZoomCta">

                    <ZoomImageCta
                        flipperRef={el => this.flipperContainerRef = el}
                        imageUrl={imageUrl}
                        onError={this.onError}
                        postAuthor={postAuthor}
                        postNetwork={postNetwork}
                    />

                </div>
            );
        }

        let headerText = this.cta.alt_cta || (this.props.isVertical ? 'GET ON THIS<br>SCREEN!' :'GET ON<br>THIS<br>SCREEN!');

        if (headerText.indexOf("<br>") > 0) {
            headerText = headerText.split('<br>').map((line, idx) => (
                <span key={idx}>
                    {idx === 0 ? null : <br/>}
                    {line}
                </span>
            ));
        }

        return (
            <div className="ZoomCta">

                <ZoomTextCta
                    flipperContainerRef={el => this.flipperContainerRef = el}
                    flipperDescriptionRef={el => this.flipperDescriptionRef = el}
                    flipperCtaRef={el => this.flipperCtaRef = el}
                    postDescription={postDescription}
                    headerText={headerText}
                    longCta={this.cta.long_cta}
                    hashtags={this.cta.tags}
                />

            </div>
        );
    }

}

ZoomCta.propTypes = {
    postNetwork: PropTypes.string.isRequired,
    postAuthor: PropTypes.string.isRequired,
    postDescription: PropTypes.string,
    playing: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    isVertical: PropTypes.bool.isRequired,
};

export default ZoomCta;