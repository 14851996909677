import React, { Component } from 'react';
import TileGrid3 from '../../components/TileGrid3/TileGrid3';
import _ from 'lodash';
import timer from 'react-native-timer';
import DefaultCta from '../../components/Cta/DefaultCta/DefaultCta';
import MediaLikeTemplate from '../../components/MediaLikeTemplate/MediaLikeTemplate';
import SettingsService from '../../services/SettingsService';

import BackgroundMusicService from '../../services/BackgroundMusicService';
import { teslaCacheX } from '../../Upshow';
import SpotlightPlayer from '@upshow/spotlight-player';

class Media extends Component {

    constructor(props) {
        super(props);
        this.customLogo = _.get(this.props, "customLogo", null);

        this.state = {
            logo: this.customLogo,
            longCta: SettingsService.getCta().long_cta,
            hasImage: SettingsService.hasCtaImage('media'),
            playing: !!this.props.playing
        };

        this._toggleLogo = this._toggleLogo.bind(this);
        this._bigBox = this._bigBox.bind(this);
        this._renderBottom = this._renderBottom.bind(this);
        this._renderSide = this._renderSide.bind(this);
        this.onCtaImageError = this._onCtaImageError.bind(this);
    }

    componentDidMount() {
        if (this.state.longCta && !this.state.hasImage) {
            timer.setInterval(this, "toggle_logo", this._toggleLogo, 5000);
        }
    }

    componentWillUnmount() {
        timer.clearInterval(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.playing !== this.props.playing) {
            this.setState({ playing: nextProps.playing })
        }
    }

    _toggleLogo() {
        if (this.customLogo === null) {
            return; //No custom logo so nothing to toggle
        }

        this.state.logo === null ? this.setState({ logo: this.customLogo }) : this.setState({ logo: null });
    }

    _renderSide() {
        if (this.state.longCta && !this.state.hasImage) {
            return <TileGrid3 content={this.props.content}
                boxMargin={5} maxBoxSize={1} shouldTileFlip={true}
                gridWidth={480} gridHeight={800}
                duration={10} playing={this.state.playing}
                showCta={false} rows={3} columns={2} />
        } else {
            return <DefaultCta type='media' onCtaImageError={this.onCtaImageError} />
        }
    }

    _renderBottom() {
        if (this.state.longCta && !this.state.hasImage) {
            return <div className="MediaBottom LongCta">
                <DefaultCta type='media' longCta={true} />
                <div className={'Logo ' + (this.state.logo ? '' : 'defaultLogo')}>
                    <img alt="logo" src={this.state.logo} />
                </div>
            </div>

        } else {
            return <div className="MediaBottom">
                <div className={'Logo ' + (this.state.logo ? '' : 'defaultLogo')}>
                    <img alt="logo" src={this.state.logo} />
                </div>
                <TileGrid3 content={this.props.content} boxMargin={10}
                    gridWidth={1670} gridHeight={250} shouldTileFlip={true}
                    duration={10} playing={this.state.playing}
                    showCta={false} rows={1} columns={7} />
            </div>
        }
    }

    _bigBox () {
        if (this.props.spotlight) {
            const spotlight = this.props.spotlight;
            const isTakeover = false;

            const { backgroundType, backgroundUrl } = spotlight.background;

            spotlight.background.type = backgroundType;

            if ('youtube' === backgroundType) {
                spotlight.background.url = backgroundUrl;
            } else {
                spotlight.background.url = teslaCacheX(backgroundUrl);
            }
            const muted = (BackgroundMusicService.hasBackgroundMusic() && !SettingsService.hasTrueUiSetting('ui_disable_bgmusic_on_video_spotlight')) || SettingsService.hasTrueUiSetting('muted');

            return (<div className={'SpotlightV2Wrapper'}>
                    <SpotlightPlayer
                        playing={this.state.playing}
                        onReady={this.props.onReady}
                        onPlay={this.props.onPlay}
                        onEnded={this.props.onEnded}
                        onError={this.props.onError}
                        onPause={this.props.onPause}
                        onProgress={this.props.onProgress}
                        onDuration={this.props.onDuration}
                        spotlight={spotlight}
                        muted={muted}
                        loop={isTakeover}
                        clickable={true}
                    />
                </div>
            );

        }
    }

    _onCtaImageError() {
        this.setState({ hasImage: false });
    }

    render() {
        return (
            <MediaLikeTemplate
                bigbox={this._bigBox}
                side={this._renderSide}
                bottom={this._renderBottom}
            />
        );
    }
}

export default Media;
