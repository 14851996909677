import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";

import './SocialGrid3.scss';
import TileGrid3 from '../../components/TileGrid3/TileGrid3';
import SettingsService from '../../services/SettingsService';
import ScreenService from '../../services/ScreenService';
import {StatePreconditionError} from "../Errors";


class SocialGrid3 extends Component {

    constructor(props) {
        super(props);

        if (!this.props.content.length) {
            throw new StatePreconditionError('Content missing');
        }

        this.state = {
            longCta: SettingsService.getCta().long_cta,
            hasImage: SettingsService.hasCtaImage('grid'),
            slimCta: SettingsService.getCta().slim_cta,
            columns: _.get(this.props, 'columns', ScreenService.isVertical ? 4 : 7),
            rows: _.get(this.props, 'rows', ScreenService.isVertical ? 7 : 4),
            playing: this.props.playing
        };

        this.onCtaImageError = this.onCtaImageError.bind(this);
        this.onOrientationChange = this.onOrientationChange.bind(this);
    }

    componentWillReceiveProps(newProps){
        if (this.props.playing !== newProps.playing){
            this.setState({
                playing: newProps.playing
            });
        }
    }

    componentDidMount() {
        this.props.onMounted();
        window.addEventListener('screen_orientation_change', this.onOrientationChange);
    }

    componentWillUnmount() {
        window.removeEventListener('screen_orientation_change', this.onOrientationChange);
    }

    onCtaImageError() {
        this.setState({hasImage: false});
    }

    onOrientationChange() {
        this.setState({
            columns: ScreenService.isVertical ? 4 : 7,
            rows: ScreenService.isVertical ? 7 : 4,
        });
    }

    getCtaDimensions(hasImage, slimCta, longCta){
        const dimensions = {}

        // calculate ctaWidth and ctaHeight
        if (hasImage){
            dimensions.ctaWidth = 3;
            dimensions.ctaHeight = 3;
        } else if (slimCta) {
            dimensions.ctaWidth = 2;
            dimensions.ctaHeight = 3;
        } else if (longCta) {
            dimensions.ctaWidth = 4;
            dimensions.ctaHeight = 2;
        } else {
            dimensions.ctaWidth = 3;
            dimensions.ctaHeight = 3;
        }

        return dimensions;
    }

    render() {
        const {content, duration} = this.props;
        const {longCta, hasImage, columns, rows, slimCta} = this.state;

        const {ctaWidth, ctaHeight} = this.getCtaDimensions(hasImage, slimCta, longCta);

        return (
            <div className="SocialGrid3">
                <TileGrid3
                    playing={this.state.playing}
                    content={content}
                    duration={duration}
                    showCta={true}
                    maxBoxSize={_.get(this.props, 'maxBoxSize', 2)}
                    columns={columns}
                    rows={rows}
                    ctaHeight={ctaHeight}
                    ctaWidth={ctaWidth}
                    onCtaImageError={this.onCtaImageError}
                    shouldTileFlip={_.get(this.props, 'shouldTileFlip', true)}
                    relaxed={_.get(this.props, 'relaxed', false)}
                />
            </div>
        );
    }


}

SocialGrid3.propTypes = {
    onMounted: PropTypes.func,
    content: PropTypes.array.isRequired,
    duration: PropTypes.number.isRequired,
    relaxed: PropTypes.bool.isRequired,
};

SocialGrid3.defaultProps = {
    onMounted: () => null,
    relaxed: false
};

export default SocialGrid3;
