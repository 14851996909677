import React from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";

import SocialGrid3 from './SocialGrid3';
import StateFactory from '../StateFactory';
import SocialGrid3State from "./SocialGrid3State";
import ScreenService from "../../services/ScreenService";

class SocialGrid3SmallState extends SocialGrid3State {

    get name() {
        return 'socialgrid3small';
    }

    _render(resolve) {
        ReactDOM.render(
            <SocialGrid3
                content={this.state.contentArr}
                onMounted={this.onSocialGridMounted}
                playing={false}
                onError={this.raiseError}
                duration={this.state.duration}
                maxBoxSize={3}
                columns={5}
                rows={3}
                shouldTileFlip={false}
                relaxed={true}
            />,
            this.node
        );
    }

    static appliesTo(meta, node, state) {
        //only applies if screen is *not* vertical and layout is explicitly set as 'relaxed'
        return !ScreenService.isVertical && _.get(state, 'state.layout') === 'relaxed';
    }
}

StateFactory.register('socialgrid', SocialGrid3SmallState, 1);

export default SocialGrid3SmallState;
