import React from "react";
import ReactDOM from "react-dom";
import MediaPlayingState from "../MediaPlayingState";
import StateFactory from "../StateFactory";
import {teslaCacheXI} from '../../Upshow';
import SpotlightPlayer from '@upshow/spotlight-player';

import _get from 'lodash/get';
import {StatePreconditionError} from "../Errors";
import BackgroundMusicService from "../../services/BackgroundMusicService";
import SpotlightService from "../../services/SpotlightService";
import SettingsService from "../../services/SettingsService";
import ScriptService from "../../services/ScriptService";
import StateService from "../../services/StateService";
import TakeoverService from "../../services/TakeoverService";

class SpotlightState extends MediaPlayingState {
    constructor(node, state) {
        super(
            node,
            state,
            null,
            false,
            true,
            {track: false}
        );

        this.onDurationV2 = this.onDurationV2.bind(this);
        this.onProgressV2 = this.onProgressV2.bind(this);
        this.videoWillBeInterrupted = false;
    }

    get name() {
        return "spotlight";
    }

    get logImpressions() {
        return true;
    }

    async preload(){
        const igSpotlight = _get(this.state, "state.igSpotlight", false);
        const takeoverData = _get(this.state, "state.takeoverData", null);
        const scriptState = _get(this.state, "state", {});
        const currentId = ScriptService.getCurrentGranularityId(scriptState);
        this.state.spotlight = null;
        if(takeoverData){
            this.state.spotlight = takeoverData;
        } else if (igSpotlight) {
            this.state.spotlight = igSpotlight;
        } else if (!!currentId) {
            this.state.spotlight = await SpotlightService.getSpotlight(currentId);
        } else {
            this.state.spotlight = SpotlightService.getNextFullscreenSpotlight();
        }

        if (!this.state.spotlight) {
            throw new StatePreconditionError("Error loding SpotlightState without a valid spotlight");
        }

        if (this.state.spotlight) {
            this.state.spotlight = Object.assign({}, this.state.spotlight);
            this.state.allowsIg = !this.state.spotlight.no_content;
            if (this.state.spotlight.duration) {
                this.state.duration = this.state.spotlight.duration;
            }
        }

        let isVideoFile;
        let isYoutube;

        const backgroundType = _get(this.state.spotlight, 'background.backgroundType');
        isVideoFile = backgroundType === 'video';
        isYoutube = backgroundType === 'youtube';

        const isTakeover = 'takeover' === _get(this.state, 'state.takeoverData.behavior', 'script');

        const trackingType = isVideoFile ? 'video' : this.state.spotlight.type;

        if(SettingsService.hasTrueUiSetting('ui_disable_bgmusic_on_video_spotlight') && (isVideoFile || isYoutube)){
            BackgroundMusicService.setPause(true);
        } else {
            BackgroundMusicService.setPause(false);
        }

        this.setupMediaState(this.state.spotlight.spotlightUrl,
                            isVideoFile, isYoutube,
                            {
                                uid: this.state.spotlight.uid,
                                title: this.state.spotlight.title,
                                type: trackingType,
                                track: true
                            }, isTakeover && (isVideoFile || isYoutube));
        this._render();
        
        return this.state.readyPromise;
    }

    onProgressV2 (progress){
        const { playedSeconds } = progress;
        if((this.duration - playedSeconds) < 2 && !this.videoWillBeInterrupted){
            this.checkInterruptVideo();
        }
        this.onProgress(progress);
    }

    onDurationV2 (duration) {
        this.duration = duration;
        this.onDuration(duration);
    }

    checkInterruptVideo(){
        const next_takeover_schedule = TakeoverService.getNextTakeOverSchedule();
        const isTakeover = 'takeover' === _get(this.state, 'state.takeoverData.behavior', 'script');
        const play_full_video = _get(this.state, "state.takeoverData.play_full_video", false);
        const current_schedule_type = _get(this.state, 'state.takeoverData.schedule_type');
        const current_schedule_id = _get(this.state, 'state.takeoverData.id');
        const next_schedule_id = _get(next_takeover_schedule, 'id');
        const next_schedule_type = _get(next_takeover_schedule, 'schedule_type');

        if( typeof play_full_video === 'boolean' && play_full_video && isTakeover &&
            ((next_schedule_id !== current_schedule_id && next_schedule_type === current_schedule_type)
                || (next_schedule_type !== current_schedule_type))){
            this.videoWillBeInterrupted = true;
            StateService.advanceState();
        }

    }

    _render() {
        const spotlight = this.state.spotlight;
        let loop = 'takeover' === _get(this.state, 'state.takeoverData.behavior', 'script');

        const { backgroundType, backgroundUrl } = spotlight.background;

        spotlight.background.type = backgroundType;

        if ('youtube' === backgroundType) {
            spotlight.background.url = backgroundUrl;
        } else {
            const urlInfo = teslaCacheXI(backgroundUrl);
            spotlight.background.url = urlInfo.url;
            if(!urlInfo.from_cache){
                loop = false;
            }
        }
        const muted = (BackgroundMusicService.hasBackgroundMusic() &&
                            !SettingsService.hasTrueUiSetting('ui_disable_bgmusic_on_video_spotlight'))
                        || SettingsService.hasTrueUiSetting('muted');

        ReactDOM.render(
            <SpotlightPlayer
                spotlight={spotlight}
                playing={this.state.playing}
                onPlay={this.raisePlaying}
                onReady={this.raiseReady}
                onPause={this.raisePause}
                onEnded={this.raiseDone}
                onError={this.raiseError}
                onProgress={this.onProgressV2}
                onDuration={this.onDurationV2}
                muted={muted}
                loop={loop}
                clickable={true}
            />,
            this.node
        );
    }
}

StateFactory.register("spotlight", SpotlightState, 10);

export default SpotlightState;
