class UpshowLogger {

    static debug_level = 0;

    static writers = [
        (tags, payload, level, error, extra) => {
            const procTags = typeof tags === "string" ? tags.split(",") : tags;
            const m = `${procTags.map(t => `[${t}]`).join(' ')}: ${JSON.stringify(payload)}`;

            const l = function () {
                const l = {};
                if (error) {
                    l.error = error;
                }

                const ks = Object.keys(l);
                if (ks.length === 0) {
                    return null;
                } else if (ks.length === 1) {
                    return l[ks[0]];
                } else {
                    return l;
                }
            };

            switch (level) {
                case -2:
                    console.error(m, l(), extra);
                    break;
                case -1:
                    console.warn(m, l(), extra);
                    break;
                case 1:
                    console.info(m, l(), extra);
                    break;
                case 2:
                    console.debug(m, l(), extra);
                    break;
                case 0:
                default:
                    console.log(m, l(), extra);
                    break;
            }
        }
    ];

    static loglevelToString(level) {
        switch (level) {
            case -2:
                return "error";
            case -1:
                return "warning";
            case 1:
                return "info";
            case 2:
                return "debug";
            case 0:
            default:
                return "log";

        }
    }

    static sendLog(tags, payload, level, error, extra) {
        if (extra && typeof extra !== 'object') {
            extra = { extra_str: JSON.stringify(extra)};
        }

        return new Promise((resolve, reject) => {
            try {
                for (let i = 0; i < UpshowLogger.writers.length; i++) {
                    UpshowLogger.writers[i](tags, payload, level, error, extra);
                }

                resolve();
            } catch (e) {
                // window.logException(e);
                console.error(e);
                console.error(payload);
                reject(e);
            }
        });
    }

    static error(tags, payload, extra) {
        return UpshowLogger.sendLog(tags, payload, -2, (payload && payload.stack) ? payload : undefined, extra);
    }

    static warn(tags, payload, extra) {
        return UpshowLogger.sendLog(tags, payload, -1, (payload && payload.stack) ? payload : undefined, extra);
    }

    static log(tags, payload, extra) {
        // Log is always sent by default, can be disabled by setting debug_level to (int) < 0
        if (UpshowLogger.debug_level >= 0) {
            return UpshowLogger.sendLog(tags, payload, 0, null, extra);
        }

        return Promise.resolve();
    }

    static info(tags, payload, extra) {
        // Info is for getting more information out of the application
        if (UpshowLogger.debug_level >= 1) {
            return UpshowLogger.sendLog(tags, payload, 1, null, extra);
        }

        return Promise.resolve();
    }

    static debug(tags, payload, extra) {
        // Debug is for debugging the application (WARNING, GENERATES A LOT OF LOG)
        if (UpshowLogger.debug_level >= 2) {
            return UpshowLogger.sendLog(tags, payload, 2, null, extra);
        }

        return Promise.resolve();
    }

    static isDebugEnabled() {
        return UpshowLogger.debug_level >= 2;
    }
}

export default UpshowLogger;