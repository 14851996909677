import ReactDOM from 'react-dom';
import timer from 'react-native-timer';
import AbstractState from './AbstractState';
import Logger from '../Logger'
import TrackerService from "../services/TrackerService";
import {getCurrentTimestamp} from '../Upshow';

class UPshowState extends AbstractState {
    constructor(node, state) {
        super(node, state);
        this.node = node;
        this.state = state;
        this.state.playing = false;

        this.handlers = {};

        this.state.readyPromise = new Promise((resolve, reject) => {
            this.handlers.rejectReady = reject;
            this.handlers.resolveReady = resolve;

        });

        this.state.playPromise = new Promise((resolve, reject) => {
            this.handlers.rejectPlay = reject;
            this.handlers.resolvePlay = resolve;

        });

        this.state.pausePromise = new Promise((resolve, reject) => {
            this.handlers.rejectPause = reject;
            this.handlers.resolvePause = resolve;

        });

        this.state.donePromise = new Promise((resolve, reject) => {
            this.handlers.rejectDone = reject;
            this.handlers.resolveDone = resolve;

        });

        this.raiseReady = this.raiseReady.bind(this);
        this.raisePlaying = this.raisePlaying.bind(this);
        this.raiseDone = this.raiseDone.bind(this);
        this.raiseError = this.raiseError.bind(this);
        this.raisePaused = this.raisePaused.bind(this);


    }

    get name() {
        return 'upshowstate';
    }


    get mainContentUrl() {
        return this.state.mainContentUrl;
    }

    get video() {
        return !!this.state.isVideo;
    }

    get playThrough() {
        return !!this.state.isPlayThrough;
    }

    raiseReady(data) {
        Logger.debug(['upshowstate', 'ready'], 'Raise ready ' + this.name);

        if (this.state.logImpressions && !this.playThrough && !this.state.loggedImpression) {
            TrackerService.trackEvent('impression-' + this.correlativeNumber, {
                object: this.name,
                name: this.name,
                source: 'loaded',
                url: this.state.mainContentUrl,
                ...this.state.tracking
            });
            this.state.loggedImpression = true;
        }
        this.handlers.resolveReady(data);
    }

    raisePlaying(data) {
        Logger.debug(['upshowstate', 'playing'], 'Raise playing ' + this.name);
        this.handlers.resolvePlay(data);
    }

    raisePaused(data) {
        Logger.debug(['upshowstate', 'paused'], 'Raise paused ' + this.name);
        this.handlers.resolvePause(data);
    }

    raiseDone(data) {
        Logger.debug(['upshowstate', 'done'], 'Raise done ' + this.name);

        if (this.state.logImpressions && this.playThrough && !this.state.loggedImpression) {
            TrackerService.trackEvent('impression-' + this.correlativeNumber, {
                object: this.name,
                name: this.name,
                source: 'done',
                url: this.state.mainContentUrl,
                ...this.state.tracking
            });
            this.state.loggedImpression = true;
        }

        this.handlers.resolveDone(data);
    }

    raiseError(data) {

        //TODO only reject promises that were returned to a client

        Logger.error(['upshowstate'], 'raise error, reject all ' + this.name);
        this.handlers.rejectReady(data);
        this.handlers.rejectPlay(data);
        this.handlers.rejectPause(data);
        this.handlers.rejectDone(data);
    }

    preload() {
        this._render(this.handlers.resolveReady);

        return this.state.readyPromise;

    }

    setTimeout = (...args) => {
        return timer.setTimeout(this, ...args);
    };

    clearTimeout = (...args) => {
        return timer.clearTimeout(this, ...args);
    };

    setInterval = (...args) => {
        return timer.setInterval(this, ...args);
    };

    clearInterval = (...args) => {
        return timer.clearInterval(this, ...args);
    };


    _render(resolve) {
        Logger.debug(['upshowstate', '_render'], '_render ' + this.name);
        resolve();
    }

    _playDuration() {

        this.state.playStart = getCurrentTimestamp();

        if (this.playThrough) {
            return;
        }

        //Set a timer to complete the state when duration has elapsed
        if (this.state.duration && this.state.duration > 0) {

            Logger.info(['upshowstate', 'playduration'], "Completed Duration " + this.name + ' duration: ' + this.state.duration);

            const durationTimeOut = () => {
                Logger.info(['upshowstate', 'playduration'], "Completed Duration " + this.name + ' duration: ' + this.state.duration);
                this.raiseDone('duration');
                this.clearInterval('duration');
            };

            this.setTimeout('duration', durationTimeOut, this.state.duration * 1000);
        }

        this.setInterval('duration', () => {
            this.state.lastTick = getCurrentTimestamp();
            Logger.debug(['upshowstate', 'playduration'], "Playing  " + this.name + ' tick: ' + (this.state.lastTick - this.state.playStart) / 1000);
        }, 10000);

    }

    play() {
        Logger.info(['upshowstate', 'play'], "Called play " + this.name);

        this.state.playing = true;

        this._playDuration();

        this._render(this.handlers.resolvePlay);

        return this.state.playPromise;
    }

    done() {
        Logger.info(['upshowstate', 'done'], "Called done " + this.name);
        return this.state.donePromise;

    }

    _pauseDuration() {
        this.clearTimeout('duration');
        this.clearInterval('duration');

    }

    pause() {
        Logger.info(['upshowstate', 'pause'], "Called pause - default behavior " + this.name);
        this.state.playing = false;
        this._render(this.handlers.resolvePlay);
        this._pauseDuration();
        this.raisePaused();
        return this.state.pausePromise;
    }

    stop() {
        Logger.info(['upshowstate', 'stop'], "Called stop " + this.name);
        return this.pause();
    }

    destroy() {
        Logger.info(['upshowstate', 'destroy'], "Called destroy, clear timers " + this.name);
        this.clearInterval();
        this.clearTimeout();
        let parentNode = this.node.parentNode;
        if (parentNode) {
            parentNode.removeChild(this.node);
        }
        let nodeRemove = ReactDOM.unmountComponentAtNode(this.node);
        this.node = null;
        return nodeRemove;
    }

    static appliesTo() {
        return true;
    }

}

export default UPshowState;
