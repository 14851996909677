import React from 'react';
import './TouchTunes.scss';

export default class TouchTunes extends React.Component {
    render() {
        const className = this.props.isFireos === true ? "touchtunes_frame touchtunes_container_fireos" : "touchtunes_frame";
        return (
            <div className={className} ref={this.props.inputRef}>

            </div>
        );
    }
}