import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';

import TouchTunes from './TouchTunes';
import wrapInMediaLayout from './MediaLayout';

import TouchTunesService from '../../services/TouchTunesService';

import Logger from '../../Logger'

import * as UP from '../../Upshow';

import HwFeatureService from "../../services/HwFeatureService";
import ScreenService from "../../services/ScreenService";
import BrowserInfoService from "../../services/BrowserInfoService";
import {StatePreconditionError} from "../Errors";

export default class TouchTunesState extends UPshowState {

    currentWidget = null;

    renderTTInto = null;

    get name() {
        return 'touchtunes';
    }

    preload() {
        const TouchTunesComponent = this.state.state.layout === 'fullscreen' || !HwFeatureService.isSupported("video-in-media-layout")
            ? TouchTunes : wrapInMediaLayout(TouchTunes);

        const deviceInfo = UP.getDeviceInfo();

        if (deviceInfo.model.startsWith("AF")) {
            let div = document.createElement('div');
            div.setAttribute('id', 'tt-detached');
            div.style.width = "100%";
            div.style.height= "100%";
            div.style.background = 'transparent';
            div.style.zIndex = 1000;
            div.style.position = 'absolute';
            div.style.visibility = 'hidden';
            document.body.insertBefore(div, document.getElementById("root"));

            this.renderTTInto = div;
        }


        return new Promise((resolve, reject)=>{

            ReactDOM.render(<TouchTunesComponent
                layout={this.state.state.layout}
                customLogo={this.state.settings.custom_logo}
                content={this.state.contentArr}
                ref={ el => this.ttRef = el }
                inputRef={(node)=>{
                    if (this.renderTTInto === null) {
                        this.renderTTInto = node;
                    }
                }}
            />, this.node, resolve);
        })
        .then(()=>{
            if (!TouchTunesService.ready) {
                throw new StatePreconditionError("Tried to start TouchTunes state when TouchTunes not ready. Does the account have the 'touchtunes' flag?");
            }

            return TouchTunesService.nextWidget(this.renderTTInto)
                .then((w)=>this.currentWidget=w);
        });
    }

    destroy() {
        Logger.info(['upshowstate', 'destroy'], "Called destroy, clear timers " + this.name);
        this.clearInterval();
        this.clearTimeout();
        ReactDOM.unmountComponentAtNode(this.node);
        if (this.renderTTInto !== null) {
            this.renderTTInto.remove();
            this.renderTTInto = null;
        }
        return this.node.remove()
    }

    play() {
        Logger.info(['touchtunesstate', 'play'], "Called play " + this.name);

        this.state.playing = true;

        return this.currentWidget.play().then(()=>{

            if(!!this.ttRef && typeof this.ttRef.playTile === 'function'){
                this.ttRef.playTile();
            }

            if (UP.getDeviceInfo().model.startsWith("AF")) {
                this.renderTTInto.style.visibility = 'visible';
            }
        });
    }


    done() {
        Logger.info(['touchtunesstate', 'done'], "Called done " + this.name);
        return Promise.race([this.currentWidget.done().then(()=>{
            if (UP.getDeviceInfo().model.startsWith("AF")) {
                this.renderTTInto.style.visibility = 'hidden';
            }
        }), new Promise((resolve, reject)=>setTimeout(reject, 45000))]);
    }

    static appliesTo() {
        return BrowserInfoService.supportsObjectFitWithTransform && !ScreenService.isVertical;
    }

}

StateFactory.register('touchtunes', TouchTunesState, 10);
