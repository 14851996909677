import React, {Component} from 'react';

import "./Debug.scss";

export default class Debug extends Component {
    render() {
        return (
            <ul className="debug-console">
                <li>DEBUG Console</li>
                { this.props.messages.map((l)=><li>{l}</li>) }
            </ul>
        );
    }
}
