import React from 'react';
import ReactDOM from 'react-dom';
import { default as UPshowNow } from '@upshow/upshownow';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import UPshowNowService from '../../services/UPshowNowService';
import ScheduledMediaService from "../../services/ScheduledMediaService";
import _ from 'lodash';
import ScreenService from "../../services/ScreenService";
import ScriptService from "../../services/ScriptService";
import UpshowLogger from "../../Logger";
import SwearJarService from "../../services/SwearJarService";
import { StatePreconditionError } from "../Errors";


class UPshowNow2State extends UPshowState {

    get name() {
        return 'upshownow';
    }

    _render(resolve, watchdog = () => null)  {

        ReactDOM.render(<UPshowNow
            ref={(el) => { this.component = el }}
            content={this.state.upshownowContent}
            nextContent={this.state.nextUpshownowContent}
            onMount={(loaddata) => {
                this.raiseReady(loaddata);
                this.raisePlaying(loaddata);
            }}
            onError={this.raiseError}
            onEnd={this.raiseDone}
            title={this.media.channel.title}
            channel_thumbnail={this.media.channel.thumbnail}
            onEnded={this.raiseDone}
            playing={this.state.playing}
            feeds={this.extraData}
            disableIntro={false} //to-do, set this value right
            disableQR={false} //to-do, set this value right
            logError={(message) => UpshowLogger.error(['upshownow', 'version2'], message)}
            logWarning={(message) => UpshowLogger.warn(['upshownow', 'version2'], message)}
            logDebug={(message) => UpshowLogger.debug(['upshownow', 'version2'], message)}
            watchdog={watchdog}
        />, this.node);

        return resolve;
    }

    async stop() {
        UpshowLogger.info(['UpshowNowState', 'pause'], "Called stop");
        this.state.playing = false;
        try {
            await this._render(this.handlers.resolvePlay);
            this._pauseDuration();
            this.raisePaused();
        } catch(error){
            UpshowLogger.info(['UpshowNowState', 'pause'], "Error - calling raise error");
            this.raiseError(error);
        }
        return this.state.pausePromise;
    }

    async preload() {
        const defaultSettings = {
            disableIntro: false,

            disableGrid: false,
            gridDuration: 10,

            zoomSteps: 5,
            zoomStepDuration: 12, //Only available for images, NOT video
            disableZoomVideos: false,

            disableOutro: false,
            randomContent: false
        };
        this.extraData = {};

        const takeoverData = _.get(this.state, "state.takeoverData", null);
        const scriptState = _.get(this.state, "state", {});
        const currentId = ScriptService.getCurrentGranularityId(scriptState);

        if (!!takeoverData) {
            this.media = takeoverData;
        } else if (!!currentId) {
            const response = await UPshowNowService.getChannel(currentId);
            const { upshownow_channel: channel, error, server_error } = response;
            if (error || server_error) {
                if (server_error) {
                    UpshowLogger.error(['UPshowNowState'], server_error);
                } else if (error) {
                    UpshowLogger.warn(['UPshowNowState'], error);
                }
            } else {
                this.media = { channel };
            }
        } else {
            const [next, following ] = ScheduledMediaService.getNextMediaItems('script', 'upshownow');
            this.media = next;
            this.extraData.upshownowFeed = this.media;
            this.extraData.upshownowFeeds = _.uniqBy(ScheduledMediaService.getActiveMedia(['upshownow']), feed => feed?.channel?.id);

            this.extraData.upshownowFeedIdx = this.extraData.upshownowFeeds.findIndex(fd=>(fd.id === this.media.id));

            this.extraData.nextUpshownowFeed = !!following ? following.channel : null;
        }
        defaultSettings.disableOutro = !!takeoverData || !!currentId;
        defaultSettings.disableIntro = !!takeoverData || !!currentId;

        if (!this.media) {
            throw new StatePreconditionError('No UPshownow channel provided');
        }
        this.state.settings = _.defaults(this.state.state, defaultSettings);
        const metadata_raw = _.get(this.media, 'channel.metadata', '{}');
        try {
            const metadata = JSON.parse(metadata_raw);
            if (_.get(metadata, 'random', false)) {
                this.state.settings.randomContent = true;
            }
        } catch (err) {
            UpshowLogger.error(['upshownow', 'metadata', 'decode'], err);
        }

        if (!(this.state.settings.zoomStepDuration > 0)) {
            throw new StatePreconditionError("Invalid settings for zoomStepDuration");
        }

        if (!currentId) {
            await UPshowNowService.getChannelContent(this.media.channel);
        }

        let nextContent = null;
        if (!!this.extraData.nextUpshownowFeed) {
            nextContent = await UPshowNowService.getChannelContent(this.extraData.nextUpshownowFeed);
        }

        const content = _.get(this.media, "channel.content");

        if (!Array.isArray(content) || content.length === 0) {
            throw new StatePreconditionError("No content provided to upshownow");
        }

        const filteredContent = (content) => {
            return content.filter(c => {
                const hasSwear = SwearJarService.hasSwears(c.description);
                if (hasSwear) {
                    UpshowLogger.info(["upshownow", "censor"], `Censoring post ${c.id} because of swearword`);
                }
                return !hasSwear;
            });
        }

        this.state.upshownowContent = this.state.settings.randomContent ? _.shuffle(filteredContent(content)) : filteredContent(content);
        if (!!nextContent) {
            this.state.nextUpshownowContent = this.state.settings.randomContent ? _.shuffle(filteredContent(nextContent)) : filteredContent(nextContent);
        }


        this._render();

        return this.state.readyPromise;
    }

    play() {

        const watchdog = (view, time, payload = {}) => {
            
            UpshowLogger.debug(['upshownow_watchdog', 'version2'], `view: ${view} - time: ${time}`)

            this.clearTimeout("upshownow_watchdog");

            this.setTimeout("upshownow_watchdog", () => {
                UpshowLogger.error(["upshownow", "timeout"],`Timeout UPSHOWNOW in channel: ${_.get(this,"media.channel.title")} - view: ${view} - payload: ${payload}`)
                this.raiseError("Upshownow timeout");
            }, time)

        };

        watchdog("play_upshownow_state", 2*1000);

        UpshowLogger.info(['upshowstate', 'play'], "Called play " + this.name);

        this.state.playing = true;

        if (this.component) {
            //this.component.starrt();
        } else {
            this.raiseError('ERROR - upshownow tried to play, but component was not created - channel '
                + _.get(this, 'media.channel.title', 'channel_title_not_found'));
        }

        if (_.get(this.state, "state.takeoverData.behavior") === 'takeover') {
            this.setInterval('checkschedule', () => {
                let takeOverSchedule = ScheduledMediaService.getActiveItemsByBehavior('takeover', ['upshownow'])[0];
                if (!_.isEqual(_.get(takeOverSchedule, "channel.id"), _.get(this.state, "state.takeoverData.channel.id"))) {
                    this.raiseDone('Schedule has finished');
                }
            }, 10000);
        }

        this._render(this.handlers.resolvePlay, watchdog);

        return this.state.playPromise;
    }

    static appliesTo() {
        return !ScreenService.isVertical;
    }

}


StateFactory.register('upshownow', UPshowNow2State, 10);

export default UPshowNow2State;
