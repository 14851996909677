const TOUCH_TUNES_URL = "https://tt-web-content.tsp.cld.touchtunes.com/2.6.3/touchtunes-sdk.js";
const PLAYERJS_URL = "https://cdn.embed.ly/player-0.1.0.min.js";

export function loadTouchTunes(url) {
    if (typeof TouchTunesSDK !== "undefined") {
        return Promise.resolve(window.TouchTunesSDK);
    }

    return new Promise((resolve, reject)=>{
        _loadLib(url || TOUCH_TUNES_URL, ()=>{
            resolve(window.TouchTunesSDK);
        }, reject);
    });
}

export function loadPlayerJS() {
    if (typeof window.playerjs !== 'undefined') {
        return Promise.resolve(window.playerjs);
    }

    return new Promise((resolve, reject)=>{
        _loadLib(PLAYERJS_URL, ()=>{
            resolve(window.playerjs);
        }, reject);
    });
}


function _loadLib(url, callback, errorCallback) {
    const script = document.createElement('script');
    script.src = url;
    document.head.appendChild(script);
    script.onload = callback;
    script.onerror = errorCallback;

}

