import UPshowState from "./UPshowState";
import WatchDog from "./WatchDog";
import TrackerService from "../services/TrackerService";

const DURATION_NOT_YET_KNOWN = -1;
const MIN_IMPRESSION_SECONDS = 5;

class MediaPlayingState extends UPshowState {

    constructor(node,
                state,
                mainContentUrl,
                isVideoFile,
                isYoutube,
                tracking,
                loopVideo) {
        super(node, state);
        this.setupMediaState(mainContentUrl,
                            isVideoFile,
                            isYoutube,
                            tracking,
                            loopVideo);
    }

    get name() {
        return "mediaplaying";
    }

    get mediaRemaining() {
        if (this.state.mediaDuration <= 0) {
            return -1;
        }
        return Math.max(
            0,
            this.state.mediaDuration - this.state.mediaProgress.playedSeconds
        );
    }

    get mediaProgress() {
        return this.state.mediaProgress;
    }

    get mediaDuration() {
        return this.state.mediaDuration;
    }

    setupMediaState(mainContentUrl,
                    isVideoFile,
                    isYoutube,
                    tracking,
                    loopVideo){
        this.state.mainContentUrl = mainContentUrl;
        this.state.isVideoFile = !!isVideoFile;
        this.state.isYoutube = !!isYoutube;
        this.state.isVideo = this.state.isVideoFile || this.state.isYoutube;

        this.state.isVideo = this.state.isVideoFile || this.state.isYoutube;
        this.state.isPlayThrough = this.state.isVideo && !loopVideo;

        this.state.mediaProgress = {playedSeconds: 0};
        this.state.mediaDuration = this.state.isVideo
            ? DURATION_NOT_YET_KNOWN
            : null;
        this.state.tracking = tracking;
        this.state.logImpressions = tracking && tracking.track;
        this.state.loggedImpression = false;
    }

    onProgress = progress => {
        Object.assign(this.state.mediaProgress, progress);
    };

    onDuration = duration => {
        this.state.mediaDuration = duration;
    };

    _playDuration() {
        super._playDuration();

        this.state.watchdog = new WatchDog();

        if (this.playThrough) {
            this.setInterval(
                "watchdog",
                () => {
                    if (
                        !this.state.watchdog.watchdogOK(
                            this.state.playing,
                            this.state.playStart,
                            this.state.mediaProgress.playedSeconds
                        )
                    ) {
                        this.raiseError("watchdog");
                    } else if (
                        this.state.playing &&
                        this.state.logImpressions &&
                        !this.state.loggedImpression &&
                        this.state.mediaProgress.playedSeconds > MIN_IMPRESSION_SECONDS
                    ) {
                        TrackerService.trackEvent('impression-' + this.correlativeNumber, {
                            object: this.name,
                            name: this.name,
                            source: 'played',
                            url: this.state.mainContentUrl,
                            ...this.state.tracking
                        });
                        this.state.loggedImpression = true;
                    }
                },
                1000
            );
        }
    }

    _pauseDuration() {
        super._pauseDuration();
        //Set a timer to complete the state whe duration has elapsed

        this.clearInterval("watchdog");
        this.state.watchdog = null;
    }
}

export default MediaPlayingState;
