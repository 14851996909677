import _get from 'lodash/get';
import UpshowLogger from "../Logger";
import * as UP from '../Upshow';
import scheduleIsValid from "../libs/schedules/scheduleIsValid";
import SettingsService from "./SettingsService";
import StateService from "./StateService";

import _ from 'lodash';

const formatSequence = (sequence) => {
    return sequence?.map( (state, idx) => ({...state, idx}) ) ?? [];
}

const ScriptService = {
    granular_states: new Map(),
    scripts: null,
    timeout_schedules_check: 15000,
    timeout_schedule_id: null,
    loadSchedulesScripts: function (scripts) {
        ScriptService.scripts = scripts;
    },
    getActiveScheduledScript: function () {
        const schedules_scripts = _.get(ScriptService, 'scripts.schedules_scripts', null);
        const org_default_script = _.get(ScriptService, 'scripts.org_default_script', null);
        
        const default_tz = UP.getTimezone();
        const currentIsoDate = UP.getCurrentISODate();

        const apply_to_now = Array.isArray(schedules_scripts) ? schedules_scripts.filter( schedule => {
            return scheduleIsValid(currentIsoDate, schedule, default_tz);
        }) : [];

        if(apply_to_now.length > 0){
            const locked = apply_to_now.filter( schedule => !!schedule.lock);
            if(locked.length > 0) {
                const deepest_locked_acestor_schedule = _.minBy(locked, 'org_distance');
                return _.cloneDeep(deepest_locked_acestor_schedule['script']);
            } else {
                const schedule = _.maxBy(apply_to_now, 'org_distance');
                return _.cloneDeep(schedule['script']);
            }
        } else if (!!org_default_script) {
            return _.cloneDeep(org_default_script);
        } else {
            UpshowLogger.error(['ScriptService', 'getActiveScheduledScript'], `There is not a schedule or default script to load.`);
        }
    },
    getScript: function () {
        const script = SettingsService.getUiSetting('script', []);
        if (!script || script?.length === 0) {
            const sequence = ScriptService.getActiveScheduledScript();
            return formatSequence(sequence);
        }
        return formatSequence(_.cloneDeep(script));
    },
    stopScriptService: function () {
        clearTimeout(ScriptService.timeout_schedule_id);
    },
    startScriptService: function () {
        ScriptService.timeout_schedule_id = setTimeout(() => {
            const spotlightOnly = SettingsService.hasTrueUiSetting('spotlight_only');
            if (spotlightOnly){
                return;
            }
            const nextScript = ScriptService.getScript();
            const nextScriptStringified = JSON.stringify(nextScript);

            if ( !_.isEqual(nextScriptStringified, StateService.script_stringified) ) {
                ScriptService.granular_states.clear();
                StateService.setScript(nextScript);
                StateService.advanceState();
            }

            ScriptService.startScriptService();
        }, ScriptService.timeout_schedules_check);
    },
    getCurrentGranularityId: function (state) {
        const meta_ids = _get(state, "metadata.id");
        const idx = _get(state, "idx");

        if(!!meta_ids && !Array.isArray(meta_ids) && isFinite(meta_ids)){
            return meta_ids;
        }

        if(Array.isArray(meta_ids) && meta_ids.length > 0){
            const pastIdx = ScriptService.granular_states.get(idx) ?? meta_ids.length - 1;
            const nextIdx = (pastIdx + 1) % meta_ids.length;
            ScriptService.granular_states.set(idx, nextIdx);
            return meta_ids[nextIdx];
        }

        return null;
    }
};

export default ScriptService;
