import * as UP from "./Upshow";

const MAX_ANALYTICS_BUFFER_LENGTH = 2000;
const MAX_ANALYTICS_EVENTS = 50;

class AppEvents {
    analyticsBuffer = [];
    accessToken = null;
    getDeviceInfo = null;
    commandProcessor = null;
    setNetworkStatus = null;

    constructor(accessToken, getDeviceInfo, commandProcessor, setNetworkStatus) {
        this.accessToken = accessToken;
        this.getDeviceInfo = getDeviceInfo;
        this.commandProcessor = commandProcessor;
        this.setNetworkStatus = setNetworkStatus;

        this.addEvent("Started app events");

        this.pollBackend();
    }

    addEvent(name, extra) {
        //no logs for webview
    }

    logAnalyticsEvent(id, event) {
        if (window.st) return; // Do not store on analytics Buffer if we are in static mode

        if (event && event.uid) {
            event.uid = String(event.uid);
        }

        this.analyticsBuffer.push({ts: Date.now(), analytic: event, id: UP.sessionId + '-' + id});
    }

    pollBackend() {
        if (window.st) return; // Do not poll backend if we are in static mode
        const analytics = this.analyticsBuffer.splice(0, MAX_ANALYTICS_EVENTS);

        if (analytics.length > 0) {
            const url = process.env.REACT_APP_ANALYTICS_URL;
            const bodyObj = {
                accessToken: this.accessToken,
                events: [],
                ts: Date.now(),
                analytics: analytics.length > 0 ? analytics : undefined
            };

            let request = new Request(url, {
                method: "POST",
                body: JSON.stringify(bodyObj)
            });

            let fetchPromise = fetch(request).then(function (r) {
                return r.json();
            });

            let timeouter = new Promise(function (resolve, reject) {
                setTimeout(reject, 5000, "timeouter");
            });

            Promise.race([fetchPromise, timeouter])
                .catch(error => {
                    console.error(error);

                    this.analyticsBuffer = analytics.concat(this.analyticsBuffer);
                    //Drop messages if buffer is too long
                    if (this.analyticsBuffer.length > MAX_ANALYTICS_BUFFER_LENGTH) {
                        this.analyticsBuffer = this.analyticsBuffer.slice(
                            -MAX_ANALYTICS_BUFFER_LENGTH
                        );
                    }
                })
                .then(() => window.setTimeout(() => this.pollBackend(), 3000));
        } else {
            window.setTimeout(() => this.pollBackend(), 3000)
        }
    }
}

export default AppEvents;