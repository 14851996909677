import moment from 'moment';
import 'moment-timezone';
import SettingsService from "./SettingsService";
import UpshowLogger from "../Logger";

const VistarService = {
    getAdUrl: 'https://api.vistarmedia.com/api/v1/get_ad/json',
    apiKey: undefined,
    networkId: undefined,
    venueId: undefined,
    supportedMedia: undefined,

    init: function () {
        if (VistarService.apiKey !== undefined) {
            //already loaded
            return;
        }

        VistarService.getAdUrl = SettingsService.getUiSetting('vistar_url', 'https://api.vistarmedia.com/api/v1/get_ad/json');
        VistarService.apiKey = SettingsService.getUiSetting('vistar_apiKey', '31ed8d5d-16ef-48e9-8e67-1bd3ecf94d3c');
        VistarService.networkId = SettingsService.getUiSetting('vistar_networkId', 'bpeMs2pMTUq1Ppy7hVKX8g');
        VistarService.venueId = SettingsService.getUiSetting('vistar_venueId', false);
        VistarService.supportedMedia = SettingsService.getUiSetting('vistar_supported_media', 'image,video');
    },

    getAd: function getAd(width, height, minDuration, maxDuration, displayId) {
        VistarService.init();

        if (!VistarService.apiKey) {
            return Promise.reject("apiKey must be set before requesting an ad");
        }

        if (!VistarService.networkId) {
            return Promise.reject("networkId must be set before requesting an ad");
        }

        if (!VistarService.venueId) {
            return Promise.reject("venueId must be set before requesting an ad");
        }

        //Sanity check
        width = parseInt(width, 10);
        height = parseInt(height, 10);
        minDuration = parseInt(minDuration, 10);
        maxDuration = parseInt(maxDuration, 10);

        if (isNaN(width)) { throw new Error(`width must be integer`)}
        if (isNaN(height)) { throw new Error(`height must be integer`)}
        if (isNaN(minDuration)) { throw new Error(`minDuration must be integer`)}
        if (isNaN(maxDuration)) { throw new Error(`maxDuration must be integer`)}

        const mimeTypes = VistarService.getMimeTypes(VistarService.supportedMedia);

        if (mimeTypes.length < 1) { throw new Error(`at least one type of image or video must be provided`) }

        //All set, move on

        const adRequest = {
            "device_id": "VistarDisplay0",
            "direct_connection": false,
            "device_attribute": [],
            "display_area": [
                {
                    "id": "display-0",
                    "supported_media": mimeTypes,
                    "allow_audio": false,
                    "width": width,
                    "height": height,
                    "static_duration": maxDuration,
                    "min_duration": minDuration,
                    "max_duration": maxDuration
                }
            ],
            "display_time": VistarService.getDisplayTime(),
            "network_id": VistarService.networkId,
            "api_key": VistarService.apiKey,
            "venue_id": VistarService.venueId
        };

        UpshowLogger.log(['vistar', 'ad-request'], adRequest);

        return fetch(VistarService.getAdUrl, {
            method: 'post',
            body: JSON.stringify(adRequest)
        }).then(function(response) {
            return response.json();
        });
    },

    sendProofOfPlay: function sendProofOfPlay(ad) {
        const popUrl = `${ad.proof_of_play_url}&display_time=${VistarService.getDisplayTime()}`;
        UpshowLogger.log(['vistar', 'pop'], `Sending proof of play to: ${popUrl}`)
        return fetch(popUrl, {
            method: 'get',
        }).then(function(response) {
            return response.json();
        });
    },

    getMimeTypes: function getMimeTypes(types) {
        //We take the supported media settings as a string from ui setting, we need to parse it
        let typesArr = [];
        try{
            typesArr = types.split(',');
        } catch (e) {
            UpshowLogger.warn(['vistar', 'ad-request'], `Error parsing supported_media setting, using default 'image,video' ${e}`);
            typesArr = ['image', 'video']
        }

        const mimeTypes = [];

        if (typesArr.includes('image')) {
            mimeTypes.push("image/jpeg", "image/png");
        }

        if (typesArr.includes('video')) {
            mimeTypes.push("video/mp4");
        }

        return mimeTypes;
    },

    getDisplayTime: function getDisplayTime() {
        return moment.tz("UTC").unix();
    }

};
export default VistarService;