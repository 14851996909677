import React from 'react';
import ReactDOM from 'react-dom';
import _get from "lodash/get";
import qs from "qs";

import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import Logger from '../../Logger';

import iframeWithWhisper from '../iframe/WhisperIframe';
import Iframe from "../iframe/Iframe";

import SettingsService from '../../services/SettingsService';
import ScriptService from '../../services/ScriptService';
import ScheduledMediaService from "../../services/ScheduledMediaService";
import BrowserInfoService from "../../services/BrowserInfoService";
import {StatePreconditionError} from "../Errors";
import {getDeviceCode, getDeviceInfo} from "../../Upshow";
import ApplicationService from '../../services/ApplicationService';

class ApplicationState extends UPshowState {

    get name() {
        return `app-${_get(this.appInstance, 'application.name', 'application')}`;
    }

    async preload() {
        const takeoverApplicationInstance = _get(this.state, "state.takeoverData.applicationInstance");
        const scriptState = _get(this.state, "state", {});
        const currentId = ScriptService.getCurrentGranularityId(scriptState);
        if(takeoverApplicationInstance){
            this.appInstance = takeoverApplicationInstance;
        } else if (!!currentId) {
            this.appInstance = await ApplicationService.getApplicationInstance(currentId);
        } else {
            const schedule = ScheduledMediaService.getNextMediaItem('script', 'application');
            this.appInstance = _get(schedule, "applicationInstance");
        }

        if(!this.appInstance){
            throw new StatePreconditionError("Application Instance was no provided");
        }

        this.instanceSettings =  _get(this.appInstance, "settings");
        this.appSettings = _get(this.appInstance, 'application.settings', []);
        this.iframeRef = null;
        this.iframeUrl = null;

        const appUrl = _get(this.appInstance, 'application.url');
        const appKey = _get(this.appInstance, 'app_key');

        let upshowAppsUrl = SettingsService.getUiSetting("app_url") || SettingsService.getUiSetting("default_app_url");
        upshowAppsUrl = upshowAppsUrl.replace(/http:\/\/|https:\/\//gi, "");

        if (appUrl && appKey) {
            let deviceCodePromise = Promise.resolve(null);

            if (_get(this.appInstance, 'application.mobile_url', false)) {
                deviceCodePromise = getDeviceCode(this.appInstance.id)
                    .catch(() => {
                        throw new StatePreconditionError("Error trying to get device code");
                    });
            }

            return deviceCodePromise.then(deviceCode => {
                this.iframeUrl = this.buildIframeUrl(appUrl, appKey, _get(deviceCode, 'code', null), upshowAppsUrl);
                performance.mark(`state-preload-${this.name}`);
                return super.preload();
            })
        } else {
            throw new StatePreconditionError("url and app key are required");
        }
    }

    raiseReady(data) {
        let preloadMark = performance.getEntriesByName(`state-preload-${this.name}`, "mark");

        if (!!preloadMark[0]) {
            performance.mark(`state-ready-${this.name}`);

            performance.measure('state-ready', `state-preload-${this.name}`, `state-ready-${this.name}`);

            const measures = performance.getEntriesByName("state-ready");
            const measure = measures[0];

            Logger.log(['state-ready-measure', this.name], `${this.name} state ready measure millis: ${measure.duration}`);

            // Clean up the stored markers.
            performance.clearMarks();
            performance.clearMeasures();
        }

        return super.raiseReady(data);
    }

    play() {
        Logger.debug('ApplicationState', `Will load url ${this.iframeUrl}`);

        try {
            const iframePromise = this.iframeRef.invoke("play");
            const timeOutPromise = new Promise((r, e) => setTimeout(() => e("timeout"), 5000));

            return Promise.race([iframePromise, timeOutPromise])
                .then((r) => {
                    Logger.debug('ApplicationState', `Called play and got ${r}`);
                    Logger.log(['ApplicationState', 'play'], `Called play ${this.name} with url ${this.iframeUrl}`);
                    this.state.playing = true;
                    return this.raisePlaying();
                })
                .catch((e) => {
                    Logger.error('ApplicationState', `errored with ${e}`);
                    this.raiseError("error application play");
                });
        } catch (e) {
            Logger.error('ApplicationState', `Error ${e} calling play`);
            this.raiseError("error application play");
        }

    }

    raiseDone(data) {
        super.raiseDone(data);
        this.iframeRef = null;
        Logger.info('ApplicationState', `ApplicationState ended.`);
    }

    raiseError(error) {
        super.raiseError(error);
        this.iframeRef = null;
    }

    pause() {
        Logger.info(['ApplicationState', 'pause'], "Called pause - default behavior " + this.name);
        this.state.playing = false;
        this._pauseDuration();
        this.raisePaused();
        return this.state.pausePromise;
    }

    _render(resolve) {
        const IframeComponent = iframeWithWhisper(Iframe);
        ReactDOM.render(<IframeComponent
            ref={(el) => this.iframeRef = el}
            disableWatchdog={true}
            onReady={this.raiseReady}
            onDone={this.raiseDone}
            onError={this.raiseError}
            src={this.iframeUrl}
        />, this.node);
    }

    static appliesTo(meta) {
        Logger.debug(['ApplicationState'], 'Checking if ApplicationState has a valid configuration');
        return BrowserInfoService.supportsApplication;
    }

    buildIframeUrl(appUrl, appKey, deviceCode, upshowAppsUrl) {
        const params = (appUrl.match(/#([^#])+#/g) || []);
        
        const paramsValues = this.appSettings
                .filter(x => !x.query)
                .reduce((acc, x) => ({
                    ...acc,
                    [x.name]: this.getAppSettingValue(x.name, x.internal, x.default_value)
                }), {});

        let url = new URL(params.reduce((url, p) => {
            const paramValue = paramsValues[p.slice(1, -1)];
            return url.replace(p, paramValue);
        }, appUrl));

        const queryParams = {
            ...qs.parse(url.search.slice(1)),
            ...this.appSettings
                .filter(x => x.query)
                .reduce((acc, x) => ({
                    ...acc,
                    [x.name]: this.getAppSettingValue(x.name, x.internal, x.default_value)
                }), {}),
            'app_key': appKey,
            'deviceId': getDeviceInfo()['serial'],
            'app_url': upshowAppsUrl,
            'device_code': deviceCode,
        };

        return `${url.origin}${url.pathname}?${qs.stringify(queryParams)}`;
    }

    getAppSettingValue(name, isInternal, defaultValue) {
        let value;

        if (isInternal) {
            if (SettingsService.getUiSetting(name) !== undefined) {
                value = SettingsService.getUiSetting(name);
            } else if (getDeviceInfo()[name] !== undefined) {
                value = getDeviceInfo()[name];
            } else if (SettingsService.getSetting(name) !== undefined) {
                value = SettingsService.getSetting(name);
            } else {
                value = defaultValue;
            }
        } else {
            value = this.instanceSettings[name];
        }

        return value;
    }

}

StateFactory.register('application', ApplicationState, 10);

export default ApplicationState;
