// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.


import {Workbox, messageSW} from 'workbox-window';

export default function register() {
    if ('serviceWorker' in navigator) {
        const wb = new Workbox(`${process.env.PUBLIC_URL}/sw.js`);

        console.log('[SW NUEVO]', ' new code');

        const showSkipWaitingPrompt = (event) => {
            console.log('[SW]', 'new version ready, will install and reload');

            wb.addEventListener('controlling', (event) => {
              window.location.reload();
            });

            messageSW(event.sw, {type: 'SKIP_WAITING'});
        };

        wb.addEventListener('waiting', showSkipWaitingPrompt);
        wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

        wb.register();

    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        // navigator.serviceWorker.ready.then(registration => {
        //   registration.unregister();
        // });
        console.warn('unregister not implemented!');
    }
}
