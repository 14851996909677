import React from 'react';
import ReactDOM from 'react-dom';
import Media from './Media';
import MediaPlayingState from '../MediaPlayingState';
import StateFactory from '../StateFactory';
import ScreenService from "../../services/ScreenService";
import BrowserInfoService from '../../services/BrowserInfoService';
import SettingsService from "../../services/SettingsService";

import _get from 'lodash/get'
import { StatePreconditionError } from "../Errors";

class MediaState extends MediaPlayingState {

  constructor(node, state) {
    if (SettingsService.getSetting("operator") === 'none') {
      throw new StatePreconditionError("Error loding MediaState without content");
    }
    if (!_get(state, 'mediaspotlight.spotlightUrl', false)) {
      throw new StatePreconditionError("Error loding MediaState without a valid spotlightUrl");
    }
    const isVideoFile = state.mediaspotlight.spotlightUrl.endsWith(".mp4");
    const trackingType = isVideoFile ? 'video' : state.mediaspotlight.type;
    super(
      node,
      state,
      state.mediaspotlight.spotlightUrl,
      isVideoFile,
      state.mediaspotlight.type === 'video',
      {uid: state.mediaspotlight.uid, title: state.mediaspotlight.title, type: trackingType, name: 'media', object: 'spotlight',track: true}
    );
    this.state.contentArr.shuffle();
  }

  get name() {
    return 'media';
  }


  _render() {
    ReactDOM.render(
      <Media
        spotlight={this.state.mediaspotlight}
        playing={this.state.playing}
        onPlay={this.raisePlaying}
        onReady={this.raiseReady}
        onPause={this.raisePause}
        onEnded={this.raiseDone}
        onError={this.raiseError}
        onProgress={this.onProgress}
        onDuration={this.onDuration}
        content={this.state.contentArr}
        customLogo={this.state.settings.custom_logo}
      />, this.node);
  }

    static appliesTo() {
        return BrowserInfoService.supportsObjectFitWithTransform && !ScreenService.isVertical;
    }

}


StateFactory.register('media', MediaState, 10);

export default MediaState;
