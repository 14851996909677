import * as UP from '../Upshow';
import { TimelineLite, TweenLite } from "gsap";
import SettingsService from "./SettingsService";
import UpshowLogger from "../Logger";

const defaultDuration = 2;

export const Animators = {
    scrollRight: {
        animate(current, old) {
            const time = defaultDuration;

            const tl = new TimelineLite({ paused: true });

            TweenLite.set(current.node, { x: -1980, opacity: 1 });

            return tl
                .to(current.node, time, { x: 0 })
                .to(old.node, time, { x: 1980 }, 0);
        },

        get isSupported() {
            return true;
        }
    },

    scrollLeft: {
        animate(current, old) {
            const time = defaultDuration;

            const tl = new TimelineLite({ paused: true });

            TweenLite.set(current.node, { opacity: 1, x: 1980 });

            return tl
                .to(current.node, time, { x: 0 })
                .to(old.node, time, { x: -1980 }, 0);

        },

        get isSupported() {
            return true;
        }
    },

    replace: {
        animate(current, old) {
            current.node.className = 'container';
            old.node.className = 'container hidden';
        },

        get isSupported() {
            return false;
        },
    },

    appear: {
        animate(current, old) {
            const time = defaultDuration;

            const tl = new TimelineLite({ paused: true });

            return tl.to(current.node, time, { opacity: 1 })
                .to(old.node, time, { opacity: 0 }, 0);
        },

        get isSupported() {
            return !UP.getDeviceInfo().model.startsWith("AF");
        }
    },
};

function getAnimator(animation_name) {
    if (SettingsService.getUiSetting("disable_transitions", false)) {
        return false;
    }

    const supported = Object.keys(Animators).filter(k => Animators[k].isSupported);

    if (supported.length === 0) {
        //if we get here and don't have animators available this constitutes an error. Always!
        UpshowLogger.error(["Animations", "getAnimator"], "No animations available");
        return false;
    }

    if (supported.find( el => el === animation_name)) {
        return Animators[animation_name].animate;
    } else {
        const randomAnimation = supported[Math.floor(Math.random() * supported.length)];

        return randomAnimation.animate;
    }

}

export default getAnimator;