import Logger from '../Logger'
import _ from 'lodash';
import * as UP from '../Upshow';
import scheduleIsValid from "../libs/schedules/scheduleIsValid";

const _GetItemsByFirstIndex = (items, firstIndex) => {
    let itemsSorted = [];
    for (let i = 0; i < items.length; i++) {
        itemsSorted.push(Object.assign({}, items[(firstIndex + i) % items.length]))
    }
    return itemsSorted
}

const ScheduledMediaService = {
    media: [],
    stateKeeper: {
        plutotv: null,
        upshownow: null,
        application: null,
        jukin: null,
        trivia: null,
    },
    loadMedia: (media) => {
        ScheduledMediaService.media = media;
        Logger.info(['scheduledmedia', 'loadMedia'], "Loaded ScheduledMedia " + media.length);
    },

    getAllActive: (types = []) => {

        //always enforce scheduling
        let activeMedia = ScheduledMediaService.getActiveMedia(types);
        Logger.debug(['scheduledmedia', 'getcurrent'], "Number of active media items " + activeMedia.length + " of " + ScheduledMediaService.media.length);
        return activeMedia;
    },
    getNextMediaItem: function getNextMediaItem(behavior, state) {
        var activeItems = ScheduledMediaService.getActiveItemsByBehavior(behavior, [state]);

        if (ScheduledMediaService.stateKeeper[state] == null) {
            ScheduledMediaService.stateKeeper[state] = activeItems.length > 0 ? activeItems[0] : null;
            return activeItems.length > 0 ? activeItems[0] : null;
        }

        var index = (_.findIndex(activeItems, ScheduledMediaService.stateKeeper[state]) + 1) % activeItems.length;

        ScheduledMediaService.stateKeeper[state] = activeItems[index];

        return Object.assign({}, ScheduledMediaService.stateKeeper[state]);
    },
    getNextMediaItems: function getNextMediaItems(behavior, state) {
        
        const activeItems = ScheduledMediaService.getActiveItemsByBehavior(behavior, [state]);
        
        if (activeItems.length === 0) return null;

        if (ScheduledMediaService.stateKeeper[state] == null) {
            ScheduledMediaService.stateKeeper[state] = activeItems[0];        
            return _GetItemsByFirstIndex(activeItems, 0);
        }

        const indexPrev = activeItems.findIndex(fd => (fd.id === ScheduledMediaService.stateKeeper[state].id));
        const index = (indexPrev + 1) % activeItems.length;

        ScheduledMediaService.stateKeeper[state] = activeItems[index];
        
        return _GetItemsByFirstIndex(activeItems, index);
    },
    getActiveItemsByBehavior: (behavior = 'takeover', schedules_type = []) => {
        let schedules = ScheduledMediaService.getActiveMedia(schedules_type).filter(sch => sch.behavior === behavior);
        return Array.isArray(schedules) ? schedules : [];
    },

    isOn: () => {
        return !!ScheduledMediaService.getCurrentTakeoverItem()
    },
    getActiveMedia: (schedules_type) => {
        //get current datetime to filter media by schedule
        let currentIsoDate = UP.getCurrentISODate();
        let activeMedia = ScheduledMediaService.filterMedia(ScheduledMediaService.media, currentIsoDate, schedules_type);

        activeMedia = _.sortBy(activeMedia, 'position');

        return activeMedia;
    },
    filterMedia: (allMedia, currentIsoDate, schedules_type) => {

        // Guard against uninitialized or missing media
        if (!allMedia) {
            return [];
        }
        // No connection? no Scheduled Media for you!
        const connected = UP.getDeviceInfo().connected;
        const onlineOnlyMediaTypes = ['trivia', 'plutotv'];
        const dayFields = ['sundays', 'mondays', 'tuesdays', 'wednesdays', 'thursdays', 'fridays', 'saturdays'];
        const default_tz = UP.getTimezone() || 'America/Chicago'; 

        return allMedia.filter((item) => {
            // If specified, filter by types
            if (schedules_type && schedules_type.length > 0 && !schedules_type.includes(item.schedule_type)) {
                return false;
            }

            if (!connected && (onlineOnlyMediaTypes.includes(item.schedule_type))) {
                return false;
            }

            const schedule = {
                validFrom: item.valid_from || item.validFrom,
                validFromTime: item.valid_from_time || item.validFromTime,
                validThrough: item.valid_through || item.validThrough,
                validThroughTime: item.valid_through_time || item.validThroughTime,
                ..._.pick(item, dayFields)
            };

            return scheduleIsValid(currentIsoDate, schedule, item.tz || default_tz);
        });
    }
}

window.smservice = ScheduledMediaService;
export default ScheduledMediaService;

