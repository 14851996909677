import React from "react";
import { Textfit } from "react-textfit";
import SettingsService from '../../../../services/SettingsService';
import HwFeatureService from "../../../../services/HwFeatureService";
import { TimelineMax, Linear, Bounce, Elastic } from "gsap";

const slimCtaClassName = "slimCta";
const longCtaClassName = "longCta";

class SlimAnimatedGridCta extends React.Component {

        getCustomClassName(){
            const slimCta = SettingsService.getCta().slim_cta;
            const longCta = SettingsService.getCta().long_cta;

            if(slimCta){
                return slimCtaClassName;
            } else if (longCta) {
                return longCtaClassName;
            } else {
                return '';
            }

        }

        slidingTwistAnimation() {
            const initialTime = 3;
            const headerDelay = 6;
            const animationTime = 3;
    
            if (!this.HeaderText || !this.IconIG || !this.IconTW || !this.Hashtags){
                return;
            }
    
            const tl = new TimelineMax({paused: true});
    
            tl.add("initialTime", initialTime)
                .add("headerDelay", headerDelay)
                .fromTo(this.IconIG, animationTime, { x: '-900px'}, { x: '0px', ease: Linear.easeNone}, "initialTime")
                .fromTo(this.IconTW, animationTime + 0.5, { x: '-900px'}, { x: '0px', ease: Linear.easeNone }, "initialTime")
                .fromTo(this.Hashtags, animationTime, { x: '900px' }, { x: '0px', ease: Linear.easeNone }, "initialTime")
                .fromTo(this.HeaderText, animationTime, { y: '-900px' ,directionalRotation:"180_cw" }, {  y: '0px',  ease: Bounce.easeOut }, "initialTime")
                .to(this.HeaderText, animationTime, { directionalRotation:"+=180_cw",  ease:  Elastic.easeOut.config(1, 0.3) }, "headerDelay");
            
            if(this.Bar){
                tl.fromTo(this.Bar, animationTime, { opacity: 0 }, { opacity: 1, ease:Linear.easeNone }, "initialTime");
            }

            try{
                tl.play();
            } catch ( error ) {
                console.error(error);
            }
        }

        dropAndTwistAnimation() {
            const initialTime = 3;
            const headerDelay = 6;
            const animationTime = 3;
    
            if(!this.CTA){
                return;
            }
    
            const tl = new TimelineMax({paused: true});
    
            tl.add("initialTime", initialTime)
                .add("headerDelay", headerDelay)
                .set(this.CTA, { directionalRotation:"+=180_cw"})
                .fromTo(this.CTA, animationTime, { y: '-1000px'}, { y: '0px', ease: Elastic.easeOut.config(1, 0.3)}, "initialTime")
                .to(this.CTA, animationTime, { directionalRotation:"+=180_cw",  ease:  Elastic.easeOut.config(1, 0.3) }, "headerDelay");
            
            try{
                tl.play();
            } catch ( error ) {
                console.error(error);
            }
        }
    
        slidingAnimation() {
            const initialTime = 3;
            const headerDelay = 6;
            const animationTime = 3;
    
            if (!this.HeaderText || !this.IconIG || !this.IconTW || !this.Hashtags){
                return;
            }

            const tl = new TimelineMax({paused: true});
    
            tl.add("initialTime", initialTime)
                .add("headerDelay", headerDelay)
                .fromTo(this.IconIG, animationTime, { x: '-900px'}, { x: '0px', ease: Linear.easeNone}, "initialTime")
                .fromTo(this.IconTW, animationTime + 1, { x: '-900px'}, { x: '0px', ease: Linear.easeNone }, "initialTime")
                .fromTo(this.Hashtags, animationTime, { x: '900px' }, { x: '0px', ease: Linear.easeNone }, "initialTime")
                .fromTo(this.HeaderText, animationTime - 1, { y: '-900px' }, {  y: '0px',  ease: Bounce.easeOut }, "initialTime");
            
            if(this.Bar){
                tl.fromTo(this.Bar, animationTime, { opacity: 0 }, { opacity: 1, ease:Linear.easeNone }, "initialTime");
            }
            
            try{
                tl.play();
            } catch ( error ) {
                console.error(error);
            }
        }
    
        slideLeftAnimation() {
            const initialTime = 0;
            const animationTime = 2;
    
            if(!this.CTA){
                return;
            }
    
            const tl = new TimelineMax({paused: true});
    
            tl.add("initialTime", initialTime)
                .add("initialTimeDelay", initialTime + 2)
                .set(this.CTA, { x: '-900px' }, "initialTime")
                .to(this.CTA, animationTime, { x: '0px', ease: Linear.easeOut}, "initialTimeDelay");
            
            try{
                tl.play();
            } catch ( error ) {
                console.error(error);
            }
        }
    
        slideRightAnimation() {
            const initialTime = 0;
            const animationTime = 2;
    
            if(!this.CTA){
                return;
            }
    
            const tl = new TimelineMax({paused: true});
    
            tl.add("initialTime", initialTime)
                .add("initialTimeDelay", initialTime + 2)
                .set(this.CTA, { x: '900px' }, "initialTime")
                .to(this.CTA, animationTime, { x: '0px', ease: Linear.easeOut}, "initialTimeDelay");
            
            try{
                tl.play();
            } catch ( error ) {
                console.error(error);
            }
        }
    
        bounceAppearAnimation() {
            const initialTime = 0;
            const animationTime = 4;
    
            if(!this.CTA){
                return;
            }
    
            const tl = new TimelineMax({paused: true});
    
            tl.add("initialTime", initialTime)
                .add("initialTimeDelay", initialTime + 2)
                .set(this.CTA, { scale: 0 }, "initialTime")
                .set(this.CTA, { opacity: 0 }, "initialTime")
                .to(this.CTA, animationTime, { scale: 1, ease: Elastic.easeOut}, "initialTimeDelay")
                .to(this.CTA, animationTime -2 , { opacity: 1 ,  ease: Linear.easeNone }, "initialTimeDelay");
            
            try{
                tl.play();
            } catch ( error ) {
                console.error(error);
            }
        }
    
        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }
          
    
        randomAnimation(){
            const animations = [
                this.slidingAnimation.bind(this),
                this.slidingTwistAnimation.bind(this),
                this.bounceAppearAnimation.bind(this),
                this.slideRightAnimation.bind(this),
                this.slideLeftAnimation.bind(this),
                this.dropAndTwistAnimation.bind(this)
            ];
            let randomInt = this.getRandomInt(animations.length);
            animations[randomInt]();
            
        }
    
        componentDidMount(){
            if (HwFeatureService.isSupported('cta-animations')
                && SettingsService.getCta().animated_cta
                && !this.props.relaxed)
            {
                this.randomAnimation();
            }
        }

        renderAltTextGridCta(){
            const { className, headerText, hashtags } = this.props;
            return (<div ref={ ref => this.CTA = ref } className={`DefaultTextCta ${className} altCta ${this.getCustomClassName()} `} >
                    <div className="header" ref={ ref => this.HeaderText = ref } >
                        <Textfit
                            className={'CtaText'}
                            max={1000}>
                                {headerText}
                        </Textfit>
                    </div>
                    <div className="footer" >
                            <div className="top" style={ this.getCustomClassName() === longCtaClassName ? { width: '110px', marginRight : '5px' } : { }}>
                                    <span  ref={ ref => this.IconIG = ref } className="icon network-ig" />
                                    <span  ref={ ref => this.IconTW = ref } className="icon network-tw" />
                            </div>
                            <div className="bottom" ref={ ref => this.Hashtags = ref } >
                                <Textfit
                                    className="CtaHashtags"
                                    max={1000}>
                                    <span className="hashtags">
                                        {hashtags.map((hashtag, i) => <div key={i}>#{hashtag}</div>)}
                                    </span>
                                </Textfit>
                            </div>
                    </div>  
                </div>)
        }
        
        renderGridCta(){
            const {isWithBoth,type, className, headerText, hashtags } = this.props;
            return (<div ref={ ref => this.CTA = ref } className={`DefaultTextCta ${className} ${type} ${ this.getCustomClassName() } `} >
                        <div className="header" ref={ ref => this.HeaderText = ref } >
                            <Textfit
                                className={'CtaText'}
                                max={1000}>
                                    {headerText}
                            </Textfit>
                        </div>
                        <div className="footer" >
                                <div className="top" >
                                    <span  ref={ ref => this.IconIG = ref } className="icon network-ig" />
                                    <span  ref={ ref => this.IconTW = ref } className="icon network-tw" />
                                </div>
                                <div className="bar"  ref={ ref => this.Bar = ref }  />
                                <div className="bottom"  ref={ ref => this.Hashtags = ref } >
                                    <Textfit
                                        mode="single"
                                        max={1000}
                                        forceSingleModeWidth={false}
                                        className="postWith">
                                        <span>Post With { isWithBoth ? "Both " : " "}</span>
                                    </Textfit>
                                    <Textfit
                                        className="CtaHashtags"
                                        max={1000}>
                                        <span className="hashtags">
                                            {hashtags.map((hashtag, i) => <div key={i} >#{hashtag}</div>)}
                                        </span>
                                    </Textfit>
                                </div>
                        </div>  
                    </div>)
            
        }
    
        render(){
            const {isAltCta} = this.props;

            if (isAltCta){
                return this.renderAltTextGridCta();
            } else {
                return this.renderGridCta();
            }
        }
}


export default SlimAnimatedGridCta;