import React, {Component} from 'react';

import './MediaLikeTemplate.scss';

class
MediaLikeTemplate extends Component {
    render() {
        return (
            <div className="MediaLikeTemplate">
                <div className="MediaTop">
                    <div className="bigbox">
                        {this.props.bigbox()}
                    </div>
                    <div className="side">
                        {this.props.side()}
                    </div>
                </div>
                {this.props.bottom()}
            </div>
        );
    }
}

export default MediaLikeTemplate;
