import moment from "moment-timezone";

export default function timeRangeIsValid(ISODate, range, schedule_tz) {
    const {start, end} = range;

    if (!start || !end) {
        throw new Error('Range must have both "start" and "end" dates');
    }

    const startMoment = moment.tz(start, schedule_tz);
    const endMoment = moment.tz(end, schedule_tz);
    const currentMoment = moment(ISODate).tz(schedule_tz);

    if (endMoment.isBefore(startMoment)) {
        throw new Error('End date can\'t be before start date');
    }

    return currentMoment.isSameOrAfter(startMoment) && currentMoment.isSameOrBefore(endMoment);
}
