import {loadTouchTunes} from './ExternalLibService';
import Logger from '../Logger';
import {teslaCacheX, getCurrentTimestamp} from '../Upshow';
import SettingsService from "./SettingsService";

const TouchTunesService = {
    deviceId: '',
    deviceModel: '',
    widgets: [],
    envName: 'production',
    env: 'production',
    enableLogs: false,
    sdk: null,
    ready: false,
    token: false,
    analyticsToken: false,
    currentWidget: false,
    widgetId: 0,
    initPromise: null,

    //We are only reporting boot events and their source, so it makes no sense to be able to hold more than one at a time
    bootEvent: null,

    availableWidgets: [
        'tv-jukebox-awareness',
        'tv-mobile-awareness',
        'tv-now-playing-with-artist-images',
        'tv-now-playing-with-album-art',
        'tv-top-10-artists',
        'tv-queue-status-cta',
        'tv-hot-at-venue',
        'tv-personalised-setlist'
    ],

    logAnalyticsEvent: function (event) {
        if (typeof event !== 'undefined') {
            TouchTunesService.bootEvent = event;
        }

        if (!TouchTunesService.ready) {
            //If TT is not ready we don't send analytic events. TouchTunes may never be ready if it's not configured
            //for the account as it will set itself up upon first display. We can improve this with a feature flag
            return;
        }

        if (TouchTunesService.bootEvent === null) { //nothing to send
            return;
        }

        if (TouchTunesService.bootEvent.type !== 'boot') { //if type of event is not boot, don't know what to do. Trow away
            TouchTunesService.bootEvent = null;
            return;
        }

        let TTEvents = [];

        /**
         * Little helper to push events to TTEvents queue
         *
         * @param event
         * @param message
         */
        function createAndPushEvent(event, message) {
            TTEvents.push({
                eventSource: "tvContent",
                eventType: event,
                eventData: {
                    deviceId: TouchTunesService.deviceId,
                    deviceType: TouchTunesService.deviceModel,
                    logs:[{
                        timestamp: Math.round(getCurrentTimestamp() / 1000),
                        message: message
                    }]
                }
            });
        }

        //Map to TT event types
        let source = TouchTunesService.bootEvent.source.toLowerCase();

        switch (source) {
            case 'boot':
                createAndPushEvent("uiRestart", "boot");
                createAndPushEvent("applicationRestart", "boot");
                break;
            case 'user':
                createAndPushEvent("uiRestart", "user started");
                createAndPushEvent("applicationRestart", "user started");
                break;
            case 'restart':
                createAndPushEvent("uiRestart", "app restarted");
                createAndPushEvent("applicationRestart", "app restarted");
                break;
            case 'ui-restart':
                createAndPushEvent("uiRestart", "ui restart issued");
                break;
            case 'update':
                createAndPushEvent("uiRestart", "");
                break;
            case 'frozen':
                createAndPushEvent("uiFreeze", "app restarted");
                createAndPushEvent("uiRestart", "app restarted");
                createAndPushEvent("applicationRestart", "app restarted");
                break;
            case 'crash':
                createAndPushEvent("uiRestart", "Application has crashed");
                createAndPushEvent("applicationRestart", "Application has crashed");
                break;
            default:
                Logger.warn(["TT", "test", "boot_event"], `Unsupported event: ${source}`);
                //unsupported. Just throw away
                TouchTunesService.bootEvent = null;
                return;
        }

        Logger.log(["TT", "test", "boot_event"], TTEvents);


        TouchTunesService.bootEvent = null;
        TouchTunesService.sendLogEvent(TTEvents);

    },

    /***
     * This funcitn will consume events from TTEvent one at a time. It will try to send each event only once and move
     * to the next. If no events are found in the queue it will exit.
     *
     * NOTE: if this method is called more than once a new "thread" will be started independently sending all events
     * in *that* queue.
     *
     * @param TTEvents
     * @returns {Promise<T>}
     */
    sendLogEvent: function (TTEvents) {
        if (TTEvents.length === 0) {
            return; //nothing more to process
        }

        const TTEvent = TTEvents.pop();

        return TouchTunesService.getAnalyticsToken()
            .then((token)=>{
                return fetch(TouchTunesService.env.analyticsEndpoint, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(TTEvent)
                })
            })
            .then(d=>d.json())
            .then((r)=>console.log(r))
            .catch(e=>Logger.error("TT", "Analytics-Request", e))
            .then(()=>TouchTunesService.sendLogEvent(TTEvents))
        ;
    },

    load: function load(url, id, widgets, env, enableLogs) {
        TouchTunesService.deviceId = id;
        TouchTunesService.widgets = widgets;
        TouchTunesService.envName = env;
        TouchTunesService.env = TouchTunesService.getEnv(env);
        TouchTunesService.enableLogs = enableLogs;

        return loadTouchTunes(url).then((TouchTunesSDK) => {
            Logger.log("TOUCHTUNES LOAD", TouchTunesSDK);
            TouchTunesService.sdk = new TouchTunesSDK();
        });
    },

    init: function init(url, id, widgets, env, enableLogs, showDebug, model) {
        if (TouchTunesService.initPromise !== null) {
            return TouchTunesService.initPromise;
        }

        TouchTunesService.deviceModel = model;

        TouchTunesService.initPromise = TouchTunesService.load(url, id, widgets, env, enableLogs).then(()=>{
            Logger.debug(['tt-sdk'], `Initializing touchtunes with
             url: ${url}
             deviceId: ${TouchTunesService.deviceId}
             widgets: ${JSON.stringify(widgets)}
             env: ${env}
             logs: ${enableLogs ? 'enabled' : 'disabled'}
             debug: ${showDebug ? 'enabled' : 'disabled'}
             `);
            TouchTunesService.sdk.init({
                deviceId: TouchTunesService.deviceId,
                environment: TouchTunesService.envName,
                showDebug: !!showDebug,
            }).then(()=> {
                //Logger.log("[tt-sdk] Successfully loaded TT Sdk");
                TouchTunesService.widgets = widgets;
                TouchTunesService.ready = true;
                TouchTunesService.logAnalyticsEvent();
            }).catch(function (err) {
                TouchTunesService.ready = false
            });
        });

        return TouchTunesService.initPromise;
    },

    nextWidget: function nextWidget(renderInto) {
        Logger.info(['tt-sdk'], "[tt-sdk] NEXT WIDGET");
        const widget = TouchTunesService.widgets[TouchTunesService.widgetId % TouchTunesService.widgets.length];
        Logger.log(['tt-sdk'], "[tt-sdk] Loading widget: " + widget);
        try {
            let wc = {
                getToken: TouchTunesService.getToken,
                getATVToken: TouchTunesService.getAnalyticsToken,
                enableLogs: TouchTunesService.enableLogs,
                environment: TouchTunesService.envName,
                proxy: teslaCacheX
            };

            return Promise.race([TouchTunesService.sdk.render(widget, renderInto, wc), new Promise(function (resolve, reject) {
                setTimeout(function () {
                    reject("render timeout");
                }, 30000);
            })]).then(function (prepared_widget) {
                TouchTunesService.currentWidget = prepared_widget;
                Logger.log(['tt-sdk'], "[tt-sdk] widget ready " + widget);
                ++TouchTunesService.widgetId;
                return prepared_widget;
            }).catch(function (e) {
                Logger.error(['tt-sdk'], "[tt-sdk][nextWidget] render error promise failed: " + e);
                ++TouchTunesService.widgetId;
                return Promise.reject(e);
            })
        } catch (e) {
            Logger.error(['tt-sdk'], "[tt-sdk][nextWidget] render error general: " + e);
            ++TouchTunesService.widgetId;
            return Promise.reject(e);
        }
    },

    getToken: function getToken() {
        if (TouchTunesService.token) {
            Logger.info(['tt-sdk','token'], "Using token previously stored");
            return Promise.resolve(TouchTunesService.token);
        } else {
            Logger.info(['tt-sdk','token'], "[tt-sdk] [token] Getting TouchTunesService access token");

            return fetch(TouchTunesService.env.apiToken.url, {
                method: "POST",
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                body: "grant_type=password&client_id="+ TouchTunesService.env.apiToken.credentials.client_id +
                "&client_secret=" + TouchTunesService.env.apiToken.credentials.client_secret +
                "&username=" +  TouchTunesService.env.apiToken.credentials.username +
                "&password=" + TouchTunesService.env.apiToken.credentials.password
            }).then(function (data) {
                Logger.info(['tt-sdk','token'], '[tt-sdk] [token] got token');
                return data.json().then(function (tokens) {
                    Logger.debug(['tt-sdk','token'], '[tt-sdk] [token] storing token for: ' + tokens.expires_in.toString());

                    window.setTimeout(function () {
                        TouchTunesService.token = false;
                    }, tokens.expires_in * 100);
                    TouchTunesService.token = tokens.access_token;
                    return tokens.access_token;
                })
            });
        }
    },

    getAnalyticsToken: function getAnalyticsToken() {
        if (TouchTunesService.analyticsToken) {
            Logger.info(['tt-sdk','analyticsToken'], "[tt-sdk] [analyticsToken] Using token previously stored");
            return Promise.resolve(TouchTunesService.analyticsToken);
        } else {
            Logger.info(['tt-sdk','analyticsToken'], "[tt-sdk] [analyticsToken] Getting TouchTunesService access token");

            return fetch(TouchTunesService.env.analyticsToken.url, {
                method: "POST",
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                body: "grant_type=password&client_id="+ TouchTunesService.env.analyticsToken.credentials.client_id +
                "&client_secret=" + TouchTunesService.env.analyticsToken.credentials.client_secret +
                "&username=" +  TouchTunesService.env.analyticsToken.credentials.username +
                "&password=" + TouchTunesService.env.analyticsToken.credentials.password
            }).then(function (data) {
                Logger.info(['tt-sdk','analyticsToken'], '[tt-sdk] [analyticsToken] got token');
                return data.json().then(function (analyticsToken) {
                    Logger.debug(['tt-sdk','analyticsToken'], '[tt-sdk] [analyticsToken] storing token for: ' + analyticsToken.expires_in.toString());

                    window.setTimeout(function () {
                        TouchTunesService.analyticsToken = false;
                    }, analyticsToken.expires_in * 100);
                    TouchTunesService.analyticsToken = analyticsToken.access_token;
                    return analyticsToken.access_token;
                })
            });
        }
    },

    getEnv: function (env) {
        let settings = {};
        switch (env) {
            case "production":
                settings =  {
                    apiToken: {
                        "url": "https://ext-elb-tsp-api-security-main-us.tsp.cld.touchtunes.com/security/v1/oauth/token",
                        "credentials": {
                            "grant_type": "password",
                            "username": "atvplayer-us-prod",
                            "password": "Ju8ieng2ee",
                            "client_id": "atvplayer-us-prod",
                            "client_secret": "2kR8REJpwRsin4vFVPnB80h4R8N6H3"
                        }
                    },
                    analyticsToken: {
                        "url": "https://ext-elb-tsp-api-security-atv-us.tsp.cld.touchtunes.com/security/v1/oauth/token",
                        "credentials": {
                            "grant_type": "password",
                            "username": "atvplayer-us-prod",
                            "password": "Ju8ieng2ee",
                            "client_id": "atvplayer-us-prod",
                            "client_secret": "2kR8REJpwRsin4vFVPnB80h4R8N6H3"
                        }
                    },
                    analyticsEndpoint: "https://prod-us-eventsapi.scaffold-workers-ext-main-us.tsp.cld.touchtunes.com/events/v1"
                };
                break;
            case "development":
                settings =  {
                    apiToken: {
                        "url": "https://ext-elb-tsp-api-security-main-us.dev.cld.touchtunes.com/security/v1/oauth/token",
                        "credentials": {
                            "grant_type": "password",
                            "username": "operator",
                            "password": "okok",
                            "client_id": "CP_fuNbdSbeWKj3w2cT",
                            "client_secret": "NJ7g9N5xrBxc7N6M8vdn1UYJeX8lr1bX"
                        }
                    },
                    analyticsToken: {
                        "url": "https://ext-elb-tsp-api-security-atv-us.dev.cld.touchtunes.com/security/v1/oauth/token",
                        "credentials": {
                            "grant_type": "password",
                            "username": "attracttv_us_dev",
                            "password": "secret",
                            "client_id": "f2570f77-02c9-40bd-821a-6901b4330991",
                            "client_secret": "d7a2d9b2-d6ba-4d1b-95d2-bf76edd7c8f5"
                        }
                    },
                    analyticsEndpoint: "https://stable-eventsapi.scaffold-workers-ext-test-us.dev.cld.touchtunes.com/events/v1"
                };
                break;
            case "test":
                settings =  {
                    apiToken: {
                        "url": "https://ext-elb-tsp-api-security-main-us.test.cld.touchtunes.com/security/v1/oauth/token",
                        "credentials": {
                            "grant_type": "password",
                            "username": 'atvplayer-us-test',
                            "password": 'qlz5oPjc9R',
                            "client_id": 'atvplayer-us-test',
                            "client_secret": 'eMKYf7i0zVIhJrbLElBKJeiB6L8fQ0'
                        }
                    },
                    analyticsToken: {
                        "url": "https://ext-elb-tsp-api-security-atv-us.test.cld.touchtunes.com/security/v1/oauth/token",
                        "credentials": {
                            "grant_type": "password",
                            "username": 'atvplayer-us-test',
                            "password": 'qlz5oPjc9R',
                            "client_id": 'atvplayer-us-test',
                            "client_secret": 'eMKYf7i0zVIhJrbLElBKJeiB6L8fQ0'
                        }
                    },
                    analyticsEndpoint: "https://staging-us-eventsapi.scaffold-workers-ext-staging-us.test.cld.touchtunes.com/events/v1"
                };
                break;
            default:
                //Logger.error("[tt-sdk] Unknown environment: " + env);
                throw new Error("Unknown environment: " + env);
        }

        //we want to be able to override just the endpoint for testing
        settings.analyticsEndpoint = SettingsService.getUiSetting('touchtunes_analytics_endpoint', settings.analyticsEndpoint);

        return settings;
    }


};
export default TouchTunesService;
