class Activation {
    accessToken = null;
    activating = false;

    constructor(UP) {
        this.UP = UP;
        this.start = this.start.bind(this);
    }

    start() {
        if (this.activating) {
            return Promise.reject("already activating");
        }

        if (this.UP.getLocationQueryParam('api_token')) {
            // I got a Token in the query string
            this.accessToken = this.UP.getLocationQueryParam('api_token');
        } else if (window.localStorage.getItem('access-token')) {
            // I have a Token saved in Local Storage
            this.accessToken = window.localStorage.getItem('access-token');
        }

        if (this.accessToken) {
            this.UP.setToken(this.accessToken);
            return this.UP.getSettings(false).then((settings) => {
                return Promise.resolve(this.accessToken);
            });
        } else {
            return Promise.reject('NO ACCESS TOKEN PROVIDED')
        }
    }

    cleanup(div) {
        this.accessToken = null;
        window.localStorage.removeItem('access-token');
        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                document.body.removeChild(div);
                resolve(this.start());
            }, 60000);
        })
    }
}

export default Activation;
