import { gsap } from '@upshow/gsap';
import { getThermometerHeight } from './boh_spotlights';
export const getInitialTimeline = () => gsap.timeline({
  paused: true
});
export const gsapSelector = (ref, cssSelector) => {
  if (!ref || !ref.current) return null;
  return cssSelector ? ref.current.querySelectorAll(cssSelector) : ref.current;
};
export const fadeIn = (target, varsTo) => gsap.fromTo(target, {
  opacity: 0
}, {
  opacity: 1,
  ...varsTo
});
export const fadeOut = (target, varsTo) => gsap.fromTo(target, {
  opacity: 1
}, {
  opacity: 0,
  ...varsTo
});
export const BoHThermometerAnimation = (salesData, salesGoalsConditions, salesGoalsNodes) => {
  const {
    goal,
    stretch_goal,
    sales
  } = salesData;
  const {
    showStretchGoal,
    showTotalSales,
    showGoalUnreached
  } = salesGoalsConditions;
  const {
    goalThermometerNode,
    stretchThermometerNode,
    totalSalesNode,
    goalUnreachedMessageNode
  } = salesGoalsNodes;
  const goalThermometerPercentage = sales < goal ? sales * 100 / goal : 100;
  const goalThermometerHeight = getThermometerHeight(goalThermometerPercentage);
  const stretchThermometerPercentage = sales < stretch_goal ? sales * 100 / stretch_goal : 100;
  const stretchThermometerHeight = getThermometerHeight(stretchThermometerPercentage);
  const tl = getInitialTimeline(); // Fill Goal Thermometer

  tl.to(gsapSelector(goalThermometerNode, '.thermometer-tube-container'), {
    duration: 0.8,
    height: `${goalThermometerHeight}px`
  }, 0.5);

  if (goalThermometerPercentage === 100) {
    tl.add(fadeOut(gsapSelector(goalThermometerNode, '.thermometer-message'), {
      duration: 0.5,
      x: -70
    }), '>');
    tl.add(fadeIn(gsapSelector(goalThermometerNode, '.thermometer-description__text--reached'), {
      duration: 0.5
    }), '>');
  }

  if (showGoalUnreached) {
    tl.to(gsapSelector(goalThermometerNode), {
      duration: 0.8,
      x: -250
    }, '>');
    tl.add(fadeIn(gsapSelector(goalUnreachedMessageNode), {
      duration: 0.8,
      x: +450
    }), '<');
  }

  if (showStretchGoal) {
    // Show Stretch Goal Thermometer
    tl.to(gsapSelector(goalThermometerNode), {
      duration: 0.8,
      x: -450
    }, '>');
    tl.add(fadeIn(gsapSelector(stretchThermometerNode), {
      duration: 0.8,
      x: +450
    }), '<');
    tl.to(gsapSelector(stretchThermometerNode, '.thermometer-tube-container'), {
      duration: 0.8,
      height: `${stretchThermometerHeight}px`
    }, '>');

    if (stretchThermometerPercentage === 100) {
      tl.add(fadeOut(gsapSelector(stretchThermometerNode, '.thermometer-message'), {
        duration: 0.5,
        x: -70
      }), '>');
      tl.add(fadeIn(gsapSelector(stretchThermometerNode, '.thermometer-description__text--reached'), {
        duration: 0.5
      }), '<');
      tl.to(gsapSelector(stretchThermometerNode, '.thermometer-description'), {
        duration: 0.3,
        flexDirection: 'column'
      }, '<');
      tl.to(gsapSelector(stretchThermometerNode, '.thermometer-value'), {
        duration: 0.3,
        marginTop: '50px'
      }, '<');
    }

    if (showTotalSales) {
      // Show Total Sales
      tl.add(fadeIn(gsapSelector(totalSalesNode), {
        duration: 1
      }), '>');
      tl.to(gsapSelector(totalSalesNode, '.total-sales-amount'), {
        duration: 1
      }, '<');
      tl.fromTo(gsapSelector(totalSalesNode, '.total-sales-text'), {
        duration: 1,
        y: 200
      }, {
        duration: 0.5,
        y: 0
      }, '<');
    }
  }

  return tl;
};