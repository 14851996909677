import UpshowLogger from '../Logger';
import * as UP from '../Upshow';
import _ from 'lodash';
import ScreenService from './ScreenService';
import format_spotlight_ig from '../libs/format_spotlight_ig';
import scheduleIsValid from '../libs/schedules/scheduleIsValid';
import { randomStart } from '../libs/randomize_start';

const _isVideoSpotlight = (spt) => spt.background.backgroundType === 'video';

const SpotlightService = {
    allSpotlights: [],
    currentMediaSpotlight: null,
    currentFullscreenSpotlight: null,
    loadSpotlights: function loadSpotlights (spotlights) {
        console.log(SpotlightService.spotlights);

        SpotlightService.spotlights = spotlights
            .filter((spt) => !_isVideoSpotlight(spt))
            .map((spotlight) => {
                let spt = Object.assign({}, spotlight);
                spt.schedule_type = 'spotlight';

                spt.requiresConnection = spt.background.type === 'youtube';

                spt.spotlightUrl = spt.background.backgroundUrl;
                return spt;
            });
    },
    getSpotlight: async (spotlightId) => {
        const requiredProperties = ScreenService.isVertical ? 'clockwise_rotated' : 'fullscreen';
        const spotlight = SpotlightService.spotlights?.find( (spot) => {
            return spot.uid === 'org_' + spotlightId && spot.properties?.includes(requiredProperties) 
        });

        if(!!spotlight){

            if (_isVideoSpotlight(spotlight)) {
                return false
            }

            return spotlight;
        }

        const spotlightData = await UP.getSpotlightData(spotlightId);
        const {server_error, error} = spotlightData;
        if(server_error){
            UpshowLogger.error(['SpotlightService','getSpotlight'], server_error);
        } else if (error) {
            UpshowLogger.warn(['SpotlightService','getSpotlight'], error);
        }

        const response = format_spotlight_ig(spotlightData, ScreenService.isVertical);

        if (_isVideoSpotlight(response)) {
            return false
        }

        return response;
    },
    getNextFullscreenSpotlight: function getNextFullscreenSpotlight() {

        var activeSpotlights = SpotlightService.getActiveFullScreenSpotlights();
        if (SpotlightService.currentFullscreenSpotlight == null) {
            SpotlightService.currentFullscreenSpotlight = activeSpotlights.length > 0 ? activeSpotlights[0] : null;
            return activeSpotlights.length > 0 ? activeSpotlights[0] : null;
        }

        var index = (_.findIndex(activeSpotlights, (spt) => spt.id === SpotlightService.currentFullscreenSpotlight?.id) + 1) % activeSpotlights.length;

        SpotlightService.currentFullscreenSpotlight = activeSpotlights[index];

        UpshowLogger.info('[spotlights]', '[fullscreen] getNextFullScreenSpotlight Set next spotlight: ' + SpotlightService.currentFullscreenSpotlight.spotlightUrl);

        return Object.assign({}, SpotlightService.currentFullscreenSpotlight);
    },
    getNextMediaSpotlight: function getNextMediaSpotlight() {

        var activeSpotlights = SpotlightService.getActiveMediaSpotlights();

        if (SpotlightService.currentMediaSpotlight == null) {
            SpotlightService.currentMediaSpotlight = activeSpotlights.length > 0 ? activeSpotlights[0] : null;
            return activeSpotlights.length > 0 ? activeSpotlights[0] : null;
        }

        var index = (_.findIndex(activeSpotlights, SpotlightService.currentMediaSpotlight) + 1) % activeSpotlights.length;

        SpotlightService.currentMediaSpotlight = activeSpotlights[index];

        UpshowLogger.info('[spotlights]', '[media] getNextMediaSpotlight Set next spotlight: ' + SpotlightService.currentMediaSpotlight.spotlightUrl);

        return Object.assign({}, SpotlightService.currentMediaSpotlight);

    },
    getActiveMediaSpotlights: function getActiveMediaSpotlights() {
        //get current datetime to filter spotlights by schedule
        var currentIsoDate = UP.getCurrentISODate();
        var activeMediaSpotlights = SpotlightService.filterSpotlights(SpotlightService.spotlights, currentIsoDate, ['media']);

        UpshowLogger.debug('[spotlights][media] getActiveMediaSpotlights, had ' + _.size(SpotlightService.spotlights) + ', for datetime: ' + currentIsoDate + ', filtered to ' + _.size(activeMediaSpotlights));

        return activeMediaSpotlights;
    },
    getActiveFullScreenSpotlights: function getActiveFullScreenSpotlights(takeover) {
        //get current datetime to filter spotlights by schedule
        var currentIsoDate = UP.getCurrentISODate();
        var requiredProperties = ScreenService.isVertical ? ['clockwise_rotated'] : ['fullscreen'];
        var activeFullscreenSpotlights = SpotlightService.filterSpotlights(SpotlightService.spotlights, currentIsoDate, requiredProperties, takeover);

        UpshowLogger.debug(`[spotlights]${requiredProperties} getActiveFullscreenSpotlights, had ${_.size(SpotlightService.spotlights)}, for datetime: ${currentIsoDate}, filtered to ${_.size(activeFullscreenSpotlights)}`);

        return activeFullscreenSpotlights;
    },
    filterSpotlights: function filterSpotlights(allSpotlights, currentIsoDate, requiredProperties, takeover = false) {

        // Guard against uninitialized or missing list of spotlights
        if (!allSpotlights) {
            return [];
        }

        if (requiredProperties) {
            allSpotlights = _.filter(allSpotlights, {'properties': requiredProperties});
        }

        // No connection? no streaming for you!
        let connected = UP.isConnected();
        const default_tz = UP.getTimezone();

        let filtered = allSpotlights.filter(function onlyActive(spot) {

            if (!connected && spot.requiresConnection) {
                return false;
            }

            if (!spot.enabled) {
                return false;
            }

            if (currentIsoDate < '2015-01-01T01:01:01.000Z') {
                UpshowLogger.error('[spotlight]', ' [error] scheduling: system date ' + currentIsoDate + '  looks wrong, will ignore weekly scheduling.');
                return true;
            }

            const schedule = _.pick(spot, [
                'validFrom',
                'validFromTime',
                'validThrough',
                'validThroughTime',
                'sundays',
                'mondays',
                'tuesdays',
                'wednesdays',
                'thursdays',
                'fridays',
                'saturdays',
                'tz'
            ]);

            if(takeover && spot.behavior !== 'takeover'){
                return false;
            }

            return scheduleIsValid(currentIsoDate, schedule, default_tz);
        });

        // Randomize ( if required ) and return that array
        return randomStart(_.uniqBy(filtered, 'uid'))
        
    }
}

export default SpotlightService;

