import UpshowLogger from '../Logger';
import * as UP from '../Upshow';
import _find from "lodash/find";
import ScheduledMediaService from "./ScheduledMediaService";

const ApplicationService = {
    getApplicationInstance: async (id) => {
        const { applicationInstance } = _find(ScheduledMediaService.getActiveMedia(['application']),
                                         sch => sch?.applicationInstance === id) ?? {} ;
        if(!!applicationInstance){
            return applicationInstance;
        }
        const response = await UP.getApplicationInstance(id);
        const {server_error, error} = response;
        if(server_error){
            UpshowLogger.error(['ApplicationService','getApplicationInstance'], server_error);
        } else if (error) {
            UpshowLogger.warn(['ApplicationService','getApplicationInstance'], error);
        }
        return response.application_instance;
    },
}

export default ApplicationService;

