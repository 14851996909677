import moment from "moment-timezone";

export default function validFromIsValid(currentISODate, schedule, schedule_tz) {
    let isValid = true;
    const {validFrom, validFromTime} = schedule;

    if (validFrom) {
        const date = validFrom;
        const time = validFromTime || "00:00:00";

        const validFromMoment = moment.tz(`${date} ${time}`, schedule_tz);
        const currentMoment = moment(currentISODate).tz(schedule_tz);

        isValid = !currentMoment.isBefore(validFromMoment);
    }

    return isValid;
};