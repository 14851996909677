import React from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import SocialZoom from './SocialZoom';
import UpshowLogger from '../../Logger';
import MediaPlayingState from '../MediaPlayingState';
import StateFactory from '../StateFactory';
import TrackerService from "../../services/TrackerService";
import {getCurrentDate, canReportTts} from '../../Upshow';
import {StatePreconditionError} from "../Errors";

class SocialZoomState extends MediaPlayingState {

    constructor(node, state) {

        if (!state.currentPost) {
            throw new StatePreconditionError('Content missing');
        }

        const isVideo =  _.get(state, "currentPost.type", 1) === 2;

        super(
            node,
            state,
            state.currentPost.url,
            isVideo,
            false,
            {
                uid: state.currentPost.id,
                title: state.currentPost.permalink,
                type: isVideo ? 'video' : 'image',
                track: false
            }
        );
    }

    get name() {
        return 'socialzoom';
    }

    _render(resolve) {
        ReactDOM.render(
            <SocialZoom
                post={this.state.currentPost}
                ref={el => this.state.component = el}
                playing={this.state.playing}
                duration={this.state.duration}
                onPlay={this.raisePlaying.bind(this)}
                onReady={this.raiseReady.bind(this)}
                onEnded={this.raiseDone.bind(this)}
                onError={this.raiseError.bind(this)}
                onPause={this.raisePaused.bind(this)}
                onProgress={this.onProgress.bind(this)}
                onDuration={this.onDuration.bind(this)}
                customLogo={this.state.settings.zoom_logo}
            />, this.node);

    }


    play() {
        let post = this.state.currentPost;
        // Update TTS
        if (canReportTts() && post.new_content) {
            try {
                const posted = new Date(post.postedAt.date + '+0');
                const collected = new Date(post.createdAt.date + '+0');
                const device = new Date(post.got_content_at);
                const screen =  getCurrentDate();

                const metrics = {
                    name: 'tts',
                    content_id: post.id,
                    posted_date: posted.toISOString(),
                    collected_date: collected.toISOString(),
                    device_date: device.toISOString(),
                    screen_date: screen.toISOString(),
                    network: post.network
                };

                metrics.ptd = device.getTime() - posted.getTime();
                metrics.pts = screen.getTime() - posted.getTime();

                metrics.ctd = device.getTime() - collected.getTime();
                metrics.cts = screen.getTime() - collected.getTime();

                metrics.dts = screen.getTime() - device.getTime();

                TrackerService.trackEvent(post.id, metrics);
            } catch (err) {
                UpshowLogger.error(['TTS','SocialZoom'], err);
            }

            post.new_content = false;
        }
        return super.play();
    }

}

StateFactory.register('socialzoom', SocialZoomState, 10);

export default SocialZoomState;
