import React from 'react';
import { IframeBridge } from 'wisper-rpc';
import _ from 'lodash';

export default function steadyservWhisper(WrappedComponent) {
    return class WhisperSteadyServ extends React.Component {
        bridge = null;

        invoke(method, ...args) {
            if (this.bridge === null) {
                return Promise.reject("SteadyServ Iframe not ready");
            }

            return this.bridge.invoke(method, [...args]);
        }

        componentDidMount() {
            this.bridge = new IframeBridge(this.iframe.getIframeRef().contentWindow);

            if (typeof this.props.onMounted === 'function') {
                this.props.onMounted();
            }

            this.bridge.exposeFunction('onReady', (pages) => {
                _.invoke(this.props, 'onReady', pages);

                return true;
            });

        }

        componentWillUnmount(){
            this.bridge.close();
            this.iframe = null;
            this.bridge = null;
        }

        render() {
            return <WrappedComponent ref={(iframe)=>{this.iframe = iframe}} {...this.props} />
        }
    }
}