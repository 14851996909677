import _ from "lodash";
import UpshowLogger from "../Logger";

export function StatePreconditionError(message) {
    this.name = 'StatePreconditionError';
    this.message = message;
    this.upshowType = 'warning';
    this.stack = (new Error()).stack;
}

StatePreconditionError.prototype = new Error();

export function isUpshowErrorWarning(error) {
    return _.get(error, 'upshowType') === 'warning';
}

export function processUpshowError(e, tags) {
    if (isUpshowErrorWarning(e)) {
        UpshowLogger.warn(enforceTag(tags, 'warning'), e.message !== undefined ? e.message : e);
    } else {
        UpshowLogger.error(enforceTag(tags, 'error'), e);
    }

    return e;
}

function enforceTag(tags, tag) {
    const newTags = Array.isArray(tags) ? Object.assign(tags) :
        typeof tags !== 'undefined' ? [tags] : [];

    if (!newTags.includes(tag)) {
        newTags.push(tag);
    }

    return newTags;
}