import registerServiceWorker from './registerServiceWorker';
import 'react-app-polyfill/stable';
import UpshowLogger from './Logger'
import AppEvents from './AppEvents'
import Activation from './Activation'
import './index.scss';
import CommandProcessor from './CommandProcessor'
import _ from 'lodash';

import * as UP from './Upshow';
import { processBootSource} from './Upshow';
import SpotlightService from './services/SpotlightService'
import StateService from './services/StateService'
import ScheduledMediaService from './services/ScheduledMediaService'
import SettingsService from './services/SettingsService';
import ScriptService from './services/ScriptService';
import BackgroundMusicService from './services/BackgroundMusicService';
import TrackerService from './services/TrackerService';
import './Common'

import css_var_compat from './libs/css_var_compat';
import fit_ui_container from './libs/fit_ui_container';
import Debugger from "./states/debugger/Debugger";
import TouchTunesService from "./services/TouchTunesService";
import ScreenService from "./services/ScreenService";
import TakeoverService from "./services/TakeoverService";

import waitForUser from "./libs/waitForUser";
import powered_by_upshow_light from "./assets/powered_by_upshow_light.svg";

css_var_compat(); // support themes for older browsers
fit_ui_container(); // Fits the body to have the correct aspect ratio (also handles overscan)

window.onresize = fit_ui_container;

registerServiceWorker();

var appEvents = null;
var commandsProcessor = new CommandProcessor(StateService);

try {
    if (JSON.parse(UP.$_GET['ui.show_debugger']) === true || JSON.parse(UP.$_GET['ui.show_debugger']) === 1) {
        Debugger.attach();
    }
} catch (e) {
}

UpshowLogger.debug('index', 'commandprocessor initialized', commandsProcessor);

// Log uncaught errors
window.addEventListener('error', function (e) {
    UpshowLogger.error('UncaughtError', e.error);
});

window.upshowcommands = commandsProcessor;

const activator = new Activation(UP);
activator.start().then(async (token) => {
    UP.setToken(token);
    UP.setActivator(activator);
    appEvents = new AppEvents(token, UP.getDeviceInfo, (c) => {

        if (c.hasOwnProperty("commands")) {
            for (let i = 0; i < c.commands.length; i++) {
                commandsProcessor.process(c.commands[i]);
            }
        }
    }, (s) => StateService.setNetworkStatus(s));

    TrackerService.appEvents = appEvents;

    UpshowLogger.writers.push((tags, payload, level, error, extra) => {
        let procTags = typeof tags !== "string" ? tags.join(",") : tags;

        const errorPayload = (error && error.stack && level !== -1) ? (error.message ? error.message + "\n" + error.stack : error.stack) : typeof payload === 'string'?payload:JSON.stringify(payload);

        appEvents.addEvent(UpshowLogger.loglevelToString(level) + ":: " + procTags + ":: " + errorPayload, extra);
    });

    //Touchtunes
    UpshowLogger.writers.push((tags, payload, level, error) => {
        if (tags.includes("tt-analytics")) {
            TouchTunesService.logAnalyticsEvent(payload);
        }
    });

    UpshowLogger.log("autoactivate", "API TOKEN " + token);

    StateService.showLoadingState();

    const initialTime = (new Date()).getTime();

    const shouldAdvanceState = () => {
        waitForUser.then(()=>{
            const currentTime = (new Date()).getTime();
            if((currentTime - initialTime) >= 4000){
                TakeoverService.loadTakeoverService();
                ScriptService.startScriptService();
                StateService.advanceState();
            } else {
                setTimeout(() => shouldAdvanceState(), 1000);
            }
        });
    };

    const initializeApplication = (resolutions) => {
        const [content, spotlights, scheduledmedia] = resolutions;
        BackgroundMusicService.play();

        const random_start = SettingsService.hasTrueUiSetting('random_start_script');

        StateService.updateContent(content, random_start);

        if (SettingsService.hasTrueUiSetting('show_debugger')) {
            Debugger.attach();
        }

        if (SettingsService.hasUiSetting('debug_level')) {
            UpshowLogger.debug_level = SettingsService.getUiSetting('debug_level');
        }

        function reportScreenOrientation() {
            UpshowLogger.log(['screen_orientation'], {isVertical: ScreenService.isVertical})
        }
        reportScreenOrientation();
        window.addEventListener('screen_orientation_change', reportScreenOrientation);

        if (SettingsService.hasTrueUiSetting('performance_memory')) {
            window.setInterval(function() {
                UpshowLogger.debug('performance.memory','', window.performance.memory);
            }, 5000);
        }

        if (SettingsService.hasUiSetting('forced_iso_date')) {
            UP.setCurrentISODate(SettingsService.getUiSetting('forced_iso_date'));
        }

        ScheduledMediaService.loadMedia(scheduledmedia);

        SpotlightService.loadSpotlights(spotlights);

        waitForUser.then(async () => {
            const script = ScriptService.getScript();
            StateService.setScript(script);
        });

        window.upstate = StateService;
        window.UP = UP;

        //set theme
        if (SettingsService.getUiSetting('theme') !== undefined) {
            document.documentElement.className += " theme-" + SettingsService.getUiSetting('theme');
            const event = new CustomEvent('theme_changed', {detail: SettingsService.getUiSetting('theme')});

            window.dispatchEvent(event);
        }
        waitForUser.then(() => {
            UpshowLogger.log("index", "Initialized application ");
            shouldAdvanceState();
        });

        UP.attachMessageListener();
    };

    const apiResources = {
        settings: {},
        content: {},
        spotlights: {},
        scheduledmedia: {},
    };

    const gotResourceFromApi = {
        settings: false,
        spotlights: false,
        content: false,
        scheduledmedia: false,
        script_schedules: false,
    };

    const apiSettingsOrCachePromise = await UP.getSettings()
        .then(settings => {
            gotResourceFromApi.settings = true;
            return settings;
        })
        .catch((error) => {
            gotResourceFromApi.settings = false;
            UpshowLogger.error(['index', 'apiSettingsOrCachePromise', 'settings'], "Error getting settings from API. Will Initialize From Cache. " + error);
            return UP.getCachedApiResourceJson('settings').then(settings => {
                return settings.settings;
            });
        });
    SettingsService.loadSettings(apiSettingsOrCachePromise);

    const scriptSchedulesOrCachePromise = await UP.getScriptSchedules()
        .then( data => {
            gotResourceFromApi.script_schedules = true;
            return data;
        })
        .catch((error) => {
            gotResourceFromApi.script_schedules = false;
            UpshowLogger.error(['index', 'scriptSchedulesOrCachePromise', 'scripts'], "Error getting schedules scripts from API. Will Initialize From Cache. " + error);
            return UP.getCachedApiResourceJson('scripts_schedules');
        });
    ScriptService.loadSchedulesScripts(scriptSchedulesOrCachePromise);

    const cachedContentPromise = UP.getCachedContent()
        .catch((error) => {
            if (error === UP.ERROR_CACHE_NOT_FOUND) {
                UpshowLogger.log(['index', 'cachedSpotlightsPromise', 'content'], "Cache not found for content, will initialize from API ");
            } else {
                UpshowLogger.error(['index', 'cachedSpotlightsPromise', 'content'], error);
            }
            apiResources.content.apiCall = UP.getContent()
                .then(content => {
                    gotResourceFromApi.content = true;
                    return content;
                })
                .catch(error => {
                    UpshowLogger.error(['index', 'cachedContentPromise', 'content'], 'Got Error Fetching Content Without Cache', error);
                    gotResourceFromApi.content = false;
                    return [];
                });
            return apiResources.content.apiCall;
        });

    const cachedSpotlightsPromise = UP.getCachedSpotlights()
        .catch((error) => {
            if (error === UP.ERROR_CACHE_NOT_FOUND) {
                UpshowLogger.log(['index', 'cachedSpotlightsPromise', 'spotlights'], "Cache not found for spotlights, will initialize from API ");
            } else {
                UpshowLogger.error(['index', 'cachedSpotlightsPromise', 'spotlights'], error);
            }
            apiResources.spotlights.apiCall = UP.getSpotlights()
                .then(content => {
                    gotResourceFromApi.spotlights = true;
                    return content;
                })
                .catch(error => {
                    UpshowLogger.error(['index', 'cachedSpotlightsPromise', 'content'], 'Got Error Fetching Content Without Cache', error);
                    gotResourceFromApi.spotlights = false;
                    return [];
                });

            return apiResources.spotlights.apiCall;
        });

    const cachedScheduledmediaPromise = UP.getCachedScheduledMedia()
        .catch((error) => {
            if (error === UP.ERROR_CACHE_NOT_FOUND) {
                UpshowLogger.log(['index', 'cachedScheduledmediaPromise', 'scheduledmedia'], "Cache not found for scheduledmedia, will initialize from API ");
            } else {
                UpshowLogger.error(['index', 'cachedScheduledmediaPromise', 'scheduledmedia'], error);
            }
            apiResources.scheduledmedia.apiCall = UP.getScheduledMedia()
                .then(content => {
                    gotResourceFromApi.scheduledmedia = true;
                    return content;
                })
                .catch(error => {
                    UpshowLogger.error(['index', 'cachedScheduledmediaPromise', 'content'], 'Got Error Fetching Scheduledmedia Without Cache', error);
                    gotResourceFromApi.scheduledmedia = false;
                    return [];
                });

            return apiResources.scheduledmedia.apiCall;
        });

    const refreshFromApiIfCached = (resourceName, apiCall, postAction) => {
        if (!gotResourceFromApi[resourceName]) {
            apiResources[resourceName].apiCall = apiCall();
            apiResources[resourceName].apiCall.then(postAction);
            return true;
        } else {
            return false;
        }

    };

    Promise
        .all([
            cachedContentPromise,
            cachedSpotlightsPromise,
            cachedScheduledmediaPromise,
        ])
        .then(initializeApplication)
        .catch(error => {
            console.error("error in loading screen", error);
            UpshowLogger.error("Error on LOADING screen.", error);
        })
        .then(() => {
            refreshFromApiIfCached('content', UP.getContent, StateService.updateContent);
            refreshFromApiIfCached('spotlights', UP.getSpotlights, SpotlightService.loadSpotlights);
            refreshFromApiIfCached('scheduledmedia', UP.getScheduledMedia, ScheduledMediaService.loadMedia);
            if (!gotResourceFromApi.settings) {
                StateService.updateSettings();
            }

            //After all settings are loaded and if the TT flag is set, we init touchtunes

            UP.getSettings().then((settings)=> {
                if (_.get(settings, 'features.touchtunes', false)) {
                    const ttWidgets = _.get(settings, 'ui_settings.tt_catalog', TouchTunesService.availableWidgets);
                    const ttEnv = _.get(settings, 'ui_settings.touchtunes_env', 'production').trim();
                    const ttLogs = !!parseInt(_.get(settings, 'ui_settings.touchtunes_logs', 1), 10);
                    const ttSdkUrl = _.get(settings, 'ui_settings.touchtunes_url', false);
                    const ttDebug = _.get(settings, 'ui_settings.touchtunes_debug', false);

                    const deviceInfo = UP.getDeviceInfo();

                    TouchTunesService.init(ttSdkUrl, deviceInfo.serial, ttWidgets, ttEnv, ttLogs, ttDebug, deviceInfo.model)
                        .catch((error)=>{
                            UpshowLogger.error(["TouchTunes", "init"], "Error initializing TouchTunes, TT Will be unavailable!");
                            UpshowLogger.error(["TouchTunes", "init"], error);
                        })
                }

                processBootSource();
            });

            StateService.poll()

        })
        .catch(error => {
            console.error("error after loading screen", error);
            UpshowLogger.error("Error after LOADING screen initialization.", error);
        });
})
    .catch(error => {
        let div = document.createElement('div');
        div.innerHTML = '<div class="instructions">DEACTIVATED</div><div class="code">📵</div><div class="logo"><img alt="logo" src="' + powered_by_upshow_light + '" /></div>';
        div.className = 'activation';
        document.body.insertBefore(div, document.getElementById("root"));
        return activator.cleanup(div).then(activator.start);
    });


window.pingCallback = function pingCallback(callback, payload) {
    UpshowLogger.debug('pingCallback', payload);
    setTimeout(() => _.invoke(window, callback, payload), 0);
};

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', function (event) {
        const data = event.data;

        const action = _.get(data, 'action', false);

        switch (action) {
            case 'log':
                const logLevel = _.get(data, 'logLevel', false);

                const logData = _.get(data, 'logData', '');

                if (logData.length === 0) {
                    break;
                }

                switch (logLevel) {
                    case 'log':
                        UpshowLogger.log(['SW'], logData);
                        break;
                    case 'error':
                        UpshowLogger.log(['SW'], logData);
                        break;
                    default:
                        UpshowLogger.debug(['SW','default'], logData);
                        //stupid lint
                        break;
                }
                break;
            default:
                UpshowLogger.debug(['SW','action'], action);
                break;
        }
    });
}
