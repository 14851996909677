import UpshowLogger from '../Logger'

export default () => {
    const default_theme = "default";
    const id = "CssThemeLink";

    const changeTheme = (theme) => {
        if (!!document.getElementById(id)){
            document.querySelector('head').removeChild( document.getElementById(id) );
        }
        let node = document.createElement("link");
        node.href = `https://cdn.upshow.tv/compat_css/${!!theme ? theme : default_theme}-theme.scss`;
        node.rel = "stylesheet";
        node.id = id;
        node.onerror = () => {
            node.href = `https://cdn.upshow.tv/compat_css/${default_theme}-theme.scss`;
        };
        document.querySelector('head').appendChild(node);
    };

    UpshowLogger.log("CSS", "Will check for CSS support.");
    if (!window.CSS.supports('--fake-var', 0)) {

        changeTheme(default_theme);

        window.addEventListener("theme_changed", function (event) {
            UpshowLogger.log("CSS", "theme_changed: " + (event ? event.detail : "no theme"));
            const themeName = event.detail;

            changeTheme(themeName);
        }, false);
    }
}