import SettingsService from "../services/SettingsService";

const randomizeArrayStart = arr => {
  const arrayLength = arr?.length ?? 0;

  const randomValue = Math.round(Math.random() * arrayLength);

  // Spliting array in two halfs from a random point
  const firstHalf = arr.slice(0, randomValue);
  const secondHalf = arr.slice(randomValue);

  // Reversing halfs order
  return secondHalf.concat(firstHalf);
};

export const randomStart = arr => {
  // Getting random-start-script param from ui settings
  const randomStart = SettingsService.hasTrueUiSetting("random_start_script");
  
  // If random start script is disabled or array length is less than 2 just return it in the same order
  const arrayLength = arr?.length ?? 0;
  if( !randomStart || arrayLength < 2 ) return arr;

  // Otherwise change the start point of the array
  return randomizeArrayStart( arr )

};
