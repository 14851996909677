import UpshowLogger from '../Logger'
import _ from 'lodash';
//import moment from 'moment';
import 'moment-timezone';
import SettingsService from '../services/SettingsService'
import {getCurrentDate} from '../Upshow';

const UpNextService = {
    upNext: [],
    content: [],

    removeCurrent: () => {
        if (UpNextService.current) {
            UpshowLogger.info('up_next', 'removing content: ' + UpNextService.current.id);

            UpNextService.upNext.splice(_.findIndex(UpNextService.upNext, post => post.id === UpNextService.current.id), 1);

            if (UpNextService.upNext.length <= 3) {
                UpNextService.upNext = _.concat(UpNextService.upNext, UpNextService.content);
            }
        }

    },

    pop: () => {
        let post = UpNextService.getNext();
        UpNextService.removeCurrent();
        return post;
    },

    getNext: () => {
        UpNextService.setNext();
        return UpNextService.current;
    },

    setNext: () => {
        if (UpNextService.upNext.length > 0) {
            UpNextService.current = UpNextService.upNext[0];
            UpshowLogger.info('up_next', 'up next is: ' + UpNextService.current.id);
        }
    },

    updateContent: (content, added, remaining, removed, random_start = false) => {

        // If random start is enabled, randomize start of the posts list
        const first_post = random_start && content.length > 1 ? Math.floor(Math.random() * content.length -1) : 0; 

        let gotNew = false;
        if (UpNextService.upNext.length === 0) {
            UpshowLogger.log('up_next', 'Setting up up_next for first time');
            UpNextService.upNext = content.slice(first_post);
        } else {
            // Remove removedContent
            UpNextService.upNext = UpNextService.upNext.filter(function (post) {
                try {
                    var found = _.find(removed, ['id', post.id]);
                    if (found) {
                        UpshowLogger.info('up_next', 'Removing content from up next: ' + post.id.toString());
                    }
                    return !found;
                } catch (e) {
                    UpshowLogger.error('up_next,error', 'Problem checking content to remove from up next: ' + JSON.stringify(post));
                    return false;
                }
            });

            // From right to left, add double dip and new content or add to end
            _.eachRight(added, function (post) {
                if ((getCurrentDate() - (new Date(post.createdAt.date + '+0'))) > (6 * 60 * 60 * 1000)) {
                    UpshowLogger.info('up_next', 'Adding to the bottom: ' + post.id.toString());
                    UpNextService.upNext.push(post);
                } else {
                    gotNew = true;
                    if (!SettingsService.hasTrueUiSetting('disable_ig')) {
                        UpshowLogger.info('up_next', 'Adding double dip: ' + post.id.toString());
                        var doubleDipPost = Object.assign({}, post, {isAddedContent: true});
                        UpNextService.upNext.splice(2, 0, doubleDipPost);
                    }

                    var igPost = Object.assign({}, post,
                        {
                            isAddedContent: true,
                            new_content: true,
                            got_content_at: Date.now()
                        });

                    UpshowLogger.info('up_next', 'Adding content as first in up next: ' + post.id.toString());
                    UpNextService.upNext.unshift(igPost);
                }
            });
        }
        UpNextService.content = content.slice(0);

        return (gotNew);

    }
};

export default UpNextService;

