import React from 'react';
import ReactDOM from 'react-dom';
import AudioPlayer from 'react-responsive-audio-player';
import UpshowLogger from "../Logger";
import SettingsService from './SettingsService';
import HwFeatureService from "./HwFeatureService";
import {teslaCacheY} from "../Upshow";


const BackgroundMusicService = {
    bgMusicWrapper: document.getElementById('bg-music'),
    playerRef: null,
    play() {
        if (BackgroundMusicService.hasBackgroundMusic()) {

            const cacheList = async function () {
                return Promise.all(SettingsService.getUiSetting('bg-music-playlist').map(async (src, index) => {
                    let cachedUrl = src;
                    try {
                        cachedUrl = await teslaCacheY(src);
                    } catch (e) {
                        UpshowLogger.warn(['BackgroundMusicService'], `can't cache ${cachedUrl} because ${e}`);
                    }
                    return {url: cachedUrl, title: index}
                }));
            }();

            cacheList.then((playlist) => {
                ReactDOM.render(<AudioPlayer
                    ref={ (el) => {BackgroundMusicService.playerRef = el}}
                    playlist={playlist}
                    autoplay={true}
                    cycle={true}
                />, BackgroundMusicService.bgMusicWrapper);
            }).catch(e => UpshowLogger.warn(['BackgroundMusicService', 'cacheList', `Error playing playlist ${e}`]));


            UpshowLogger.log(['BackgroundMusicService'], "Starting to play");
            return true;
        }
        return false;
    },
    setPause(pause) {
        BackgroundMusicService.playerRef && BackgroundMusicService.playerRef.togglePause(pause);
    },
    hasBackgroundMusic(){
        return HwFeatureService.isSupported('background_music') &&
                            SettingsService.hasTrueUiSetting('bg-music') &&
                            !SettingsService.hasTrueUiSetting('muted') &&
                            SettingsService.hasUiSetting('bg-music-playlist') &&
                            Array.isArray(SettingsService.getUiSetting('bg-music-playlist'));
    }
};

window.bgMusicService = BackgroundMusicService;

export default BackgroundMusicService;
