import React, {Component} from 'react';
import "./Transparent.scss";
import Debug from "../../components/Debug/Debug";
import _ from "lodash";

export default class Transparent extends Component {
    render() {
        return (
            <div className="transparent-state">
                <Debug messages={_.map(this.props.debug, (v,p)=>`${p}: ${v}`)}/>
            </div>
        );
    }
}