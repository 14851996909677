import React from "react";
import PropTypes from "prop-types";

import "./DefaultCta.scss";
import DefaultImageCta from "./DefaultImageCta/DefaultImageCta";
import DefaultTextCta from "./DefaultTextCta/DefaultTextCta";
import SettingsService from "../../../services/SettingsService";

class DefaultCta extends React.Component {

    constructor(props) {
        super(props);

        this.cta = SettingsService.getCta();
        this.operator = SettingsService.getSetting("operator");

        this.state = {
            imageUrl: SettingsService.getCtaImage(props.type),
        };

        this.onError = this._onError.bind(this);
    }

    _onError() {
        this.setState({ imageUrl: null });
        this.props.onCtaImageError();
    }

    render() {
        const { className, type, relaxed } = this.props;
        const { imageUrl } = this.state;

        if (imageUrl) {
            return (
                <DefaultImageCta
                    className={`Cta DefaultCta ${className}`}
                    imageUrl={imageUrl}
                    onError={this.onError}
                />
            );
        }

        const { hidePowered, longCta } = this.props;

        let isLongDefaultText = SettingsService.getCta().long_cta && !SettingsService.getCta().slim_cta;

        let defaultText = isLongDefaultText ? "GET ON THIS<br>SCREEN!" : "GET ON<br>THIS<br>SCREEN!";

        let headerText = this.cta.alt_cta || defaultText;
        

        if (headerText.indexOf("<br>") > 0 && type !== 'media') {
            headerText = headerText.split('<br>').map((line, idx) => (
                <span key={idx}>
                    {idx === 0 ? null : <br />}
                    {line}
                </span>
            ));
        } else if (headerText.indexOf("<br>") > 0 && type === 'media') {
            headerText = headerText.split('<br>').join(' ');
        }

        return (
            <div className="Cta DefaultCta">
                <DefaultTextCta
                    className={className}
                    headerText={headerText}
                    type={type}
                    operator={this.operator}
                    longCta={longCta}
                    hashtags={this.cta.tags}
                    relaxed={relaxed}
                    isAltCta={SettingsService.hasSetting('alt_cta')}
                />
                { !hidePowered && <div className="pwby-logo"/>}
            </div>
        );
    }
}

DefaultCta.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['default', 'zoom', 'media', 'grid']),
    longCta: PropTypes.bool,
    hidePowered: PropTypes.bool,
    onCtaImageError: PropTypes.func,
    relaxed: PropTypes.bool,
};

DefaultCta.defaultProps = {
    className: '',
    type: 'default',
    longCta: false,
    hidePowered: false,
    relaxed: false,
    onCtaImageError: () => null,
};

export default DefaultCta;