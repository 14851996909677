import StateService from '../services/StateService'
import {$_GET, setTimezone} from "../Upshow";
import UpshowLogger from "../Logger";

const SettingsService = {
    settings: {cta: ''},

    loadSettings: function (settings = {}) {
        SettingsService.settings = settings;
        SettingsService.settings.account_name = settings.title;
        setTimezone(settings.tz);

        //Set overscan if specified
        StateService.setOverscan(SettingsService.hasTrueUiSetting("overscan"));
        StateService.setFitToScreen(SettingsService.hasTrueUiSetting("fittoscreen"));
        StateService.setBlackBackground(SettingsService.hasTrueUiSetting("blackbackground"));

    },

    getCta: () => {

        const cta = {};

        if (SettingsService.hasSetting('alt_cta_tags')) {
            cta.tags = SettingsService.getSetting('alt_cta_tags').split(',');
        } else {
            cta.tags = SettingsService.getSetting('cta').split(',');
        }

        cta.max_tag_length = cta.tags.reduce(function (length, value) {
            return length > value.length ? length : value.length;
        }, 0);

        cta.long_cta = cta.max_tag_length > 10;

        cta.slim_cta = SettingsService.hasTrueUiSetting('slim_cta');

        cta.animated_cta = !SettingsService.hasTrueUiSetting('no_moving_cta');

        cta.alt_cta = SettingsService.hasSetting('alt_cta') ? SettingsService.getSetting('alt_cta') : false;

        return cta;
    },

    hasCtaImage: (context) => {
        return !!SettingsService.getCtaImage(context);
    },

    getCtaImage: (context) => {
        const typeImageMap = new Map([
            ['media', 'cta_media_image'],
            ['grid', 'cta_grid_image'],
            ['zoom', 'cta_zoom_image'],
            ['vertical-zoom', 'cta_vertical_zoom_image'],
        ]);

        const settingName = typeImageMap.get(context);

        if (settingName && SettingsService.hasUiSetting(settingName)) {
            return SettingsService.getUiSetting(settingName);
        }
        return null;
    },

    hasSetting: (key) => {
        return !!SettingsService.settings[key];
    },

    hasTrueSetting: (key) => {
        const setting = SettingsService.getSetting(key);
        return !!setting && 'false' !== setting && 'FALSE' !== setting;
    },

    getSetting: (key, defaultValue) => {
        const value = SettingsService.settings[key];
        return value === undefined ? defaultValue : value;
    },

    hasUiSetting: (key) => {
        return !!SettingsService.getUiSetting(key);
    },

    hasTrueUiSetting: (key) => {
        const setting = SettingsService.getUiSetting(key);
        try {
            return JSON.parse(setting) === true || JSON.parse(setting) === 1;
        } catch (e) {
            return false;
        }
    },

    getUiSetting: (key, defaultValue) => {
        return SettingsService._getUiSettingFromUrl(key, SettingsService._getUiSettingFromConfig(key, defaultValue));
    },

    _getUiSettingFromConfig: (key, defaultValue) => {
        const uiSettings = SettingsService.getSetting('ui_settings');

        return typeof uiSettings[key] === "undefined" ? defaultValue : uiSettings[key];
    },

    _getUiSettingFromUrl: (key, defaultValue) => {
        const processedSetting = SettingsService._processGetUiSetting($_GET[`ui.${key}`]);

        return typeof processedSetting === 'undefined' ? defaultValue : processedSetting;
    },

    _processGetUiSetting: (value) => {
        if (typeof value !== "string") {
            return value;
        }

        if (value.substr(0, 5) === 'json:') {
            try {
                return JSON.parse(value.substr(5));
            } catch (e) {
                console.error(e);
                UpshowLogger.error(["SettingsService", "json_parse"], `Error parsing url GET json setting ${value}`);
                return undefined; //can't parse so it's undefined
            }
        }

        return value;
    },

    hasFeature: (key) => !!SettingsService.settings.features[key],
};

export default SettingsService;
