import moment from "moment-timezone";
import hasRollOver from "./hasRollover";
import getDayFieldName from "./getDayFieldName";

export default function getDayActiveRanges(ISODate, schedule, schedule_tz) {
    const {validFromTime, validThroughTime} = schedule;
    let ranges = [];

    // Parse date
    const currentMoment = moment.tz(ISODate, schedule_tz);
    const currentDate = currentMoment.format("YYYY-MM-DD");

    // Get day of week
    const dayIndex = currentMoment.day();
    const previousDayIndex = (dayIndex + 7 - 1) % 7;
    const dayIsActive = schedule[getDayFieldName(dayIndex)];
    const previousDayIsActive = schedule[getDayFieldName(previousDayIndex)];

    // Regular active range for current day
    if (dayIsActive) {
        const startTime = validFromTime || "00:00:00";
        const endTime = validThroughTime && !hasRollOver(schedule) ? validThroughTime : "23:59:59"; // watchout the leap second

        const range = {
            start: moment.tz(`${currentDate} ${startTime}`, schedule_tz).toISOString(),
            end: moment.tz(`${currentDate} ${endTime}`, schedule_tz).toISOString(),
        };

        ranges = [range, ...ranges];
    }

    // Rolled over range from previous day
    if (previousDayIsActive && hasRollOver(schedule)) {
        const range = {
            start: moment.tz(`${currentDate} 00:00:00`, schedule_tz).toISOString(),
            end: moment.tz(`${currentDate} ${validThroughTime}`, schedule_tz).toISOString(),
        };

        ranges = [range, ...ranges];
    }

    return ranges;
};