import moment from "moment-timezone";
import hasRollover from "./hasRollover";

export default function validThroughIsValid(currentISODate, schedule, schedule_tz) {
    let isValid = true;
    const {validThrough, validThroughTime} = schedule;

    if (validThrough) {
        const date = validThrough;
        const time = validThroughTime || "23:59:59";

        const validThroughMoment = moment.tz(`${date} ${time}`, schedule_tz);
        const currentMoment = moment(currentISODate).tz(schedule_tz);

        if (hasRollover(schedule)) {
            validThroughMoment.add(1, 'd');
        }

        isValid = !currentMoment.isAfter(validThroughMoment);
    }

    return isValid;
};