import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import Logger from "../../Logger";
import VistarService from "../../services/VistarService";
import {StatePreconditionError} from "../Errors";

export default class VistarState extends UPshowState {

    ad = null;

    get name() {
        return 'vistar';
    }

    preload() {
        //Using min duration and max duration at 15s because we don't handle the different durations
        //@todo handle the duration based on the ad data
        return VistarService.getAd(1920, 1080, 15, 15, 'full-screen-0')
            .then((ad) => {
                Logger.log(['vistar', 'ad-response'], ad);

                if (ad.advertisement === undefined || ad.advertisement.length < 1) {
                    throw new StatePreconditionError("No available ads");
                }

                this.ad = ad.advertisement[0];

                this._render(this.handlers.resolveReady);

                return this.handlers.resolveReady;

            });
    }


    _render(resolve) {

        ReactDOM.render(
            this.ad.mime_type.startsWith("image") ? this.renderImage() : this.renderVideo()
            , this.node);

        resolve();
    }

    renderImage() {
        return (
            <img src={this.ad.asset_url} alt={"Vistar Ad"} />
        )
    }

    renderVideo() {
        return (
            <video
                onError={()=>Logger.error(['vistar'], 'Error playing video')}
                src={this.ad.asset_url}
                muted={true}
                autoPlay={true}
                loop={true}
            />
        )
    }

    raiseReady(pages) {
            super.raiseReady();
    }

    raiseDone(data) {
        VistarService.sendProofOfPlay(this.ad)
            .then((r)=>Logger.log(['vistarstate','vistar', 'pop', 'response'],r))
            .catch((e)=>Logger.error(['vistarstate','vistar', 'pop'],e))
            .then(()=>super.raiseDone(data));
    }

    static appliesTo(meta) {
        return true;
    }

}

StateFactory.register('vistar', VistarState, 10);
