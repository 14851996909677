import Logger from '../Logger'
import _ from 'lodash';

import * as UP from '../Upshow';
import SpotlightService from '../services/SpotlightService';
import ContainerService from '../services/ContainerService';
import UpNextService from '../services/UpNextService';
import ScriptService from '../services/ScriptService';
import StateFactory from '../states/StateFactory';
import ScheduledMediaService from '../services/ScheduledMediaService'
import SettingsService from '../services/SettingsService'
import fit_ui_container from '../libs/fit_ui_container';
import TakeoverService from '../services/TakeoverService';
import BackgroundMusicService from './BackgroundMusicService';

// eslint-disable-next-line
import SocialZoomState from '../states/socialzoom/SocialZoomState';
// eslint-disable-next-line
import SocialGrid3State from '../states/socialgrid3/SocialGrid3State';
// eslint-disable-next-line
import SocialGrid3SmallState from '../states/socialgrid3/SocialGrid3SmallState';
// eslint-disable-next-line
import SpotlightState from '../states/spotlight/SpotlightState';
// eslint-disable-next-line
import MediaState from '../states/media/MediaState';
// eslint-disable-next-line
//#WEBVIEW
//import PlutoTVState from '../states/plutotv/PlutoTVState';
// eslint-disable-next-line
import LoadingState from '../states/loading/LoadingState';
// eslint-disable-next-line
import IframeState from '../states/iframe/IframeState';
// eslint-disable-next-line
import TriviaState from '../states/trivia/TriviaState';
// eslint-disable-next-line
import UPshowNow2State from '../states/upshownow2/UPshowNow2State';
// eslint-disable-next-line
import TouchTunes from '../states/touchtunes/TouchTunesState';
// eslint-disable-next-line
import Transparent from '../states/transparent/TransparentState';
// eslint-disable-next-line
import SteadyServState from '../states/steadyserv/SteadyServState';
// eslint-disable-next-line
import VistarState from '../states/vistar/VistarState';
// eslint-disable-next-line
import ApplicationState from '../states/application/ApplicationState';
// eslint-disable-next-line
import S2MSpotlightState from '../states/s2m_spotlight/S2MSpotlightState';
// eslint-disable-next-line
//#WEBVIEW
//import JukinState from '../states/jukin/JukinState';

import {processUpshowError, StatePreconditionError} from "../states/Errors";
import { randomStart } from '../libs/randomize_start';
import waitForUser from "../libs/waitForUser";

const DEFAULT_POLLER_INTERVAL = 60000;

const StateService = {
    content: [],
    spotlights: [],
    settings: [],
    nextStateIdx: -1,
    state: {name: 'loading', duration: 5},
    currentStateDef: {name: 'loading', duration: 10, takeover: false},
    loaded: false,
    networkStatus: true,
    newContentQueued: false,
    POLLER_INTERVAL: DEFAULT_POLLER_INTERVAL,


    updateSpotlights: function updateSpotlights() {
        Logger.log(["StateService", 'spotlights'], 'will update spotlights');
        return UP.getSpotlights().then((spotlights) => {
            StateService.spotlights = spotlights;
            SpotlightService.loadSpotlights(spotlights);
            Logger.log(["StateService", 'spotlights'], 'updated spotlights');
        });
    },
    updateScheduledMedia: function updateScheduledMedia() {
        Logger.log(["StateService", 'scheduledmedia'], 'will update scheduledmedia');
        return UP.getScheduledMedia().then((scheduledmedia) => {
            ScheduledMediaService.loadMedia(scheduledmedia);
            StateService.nextSchMediaIdx = 0;
            Logger.info(["StateService", 'scheduledmedia'], 'updated scheduledmedia');
            /*const is_current_state_takeover = _.get(StateService, 'currentStateDef.takeover', false);
            const current_schedule_type = _.get(StateService, 'currentStateDef.takeoverData.schedule_type');
            if (!!StateService.currentState 
                && !is_current_state_takeover 
                && current_schedule_type !== 'spotlight') {
                StateService.advanceState();
            }*/
        });
    },
    updateSettings: function updateSettings() {
        Logger.log(["StateService", 'settings'], 'will update settings');
        return UP.getSettings().then((settings) => {
            SettingsService.loadSettings(settings);
            const poll_every = parseInt(SettingsService.getUiSetting('poller_interval', DEFAULT_POLLER_INTERVAL), 10);
            if (this.POLLER_INTERVAL !== poll_every) {
                console.log(`Change polling from ${this.POLLER_INTERVAL} to ${poll_every}`);
                this.POLLER_INTERVAL = poll_every;
            }
            Logger.info(["StateService", 'settings'], 'updated settings');
        });
    },
    parseNewContent: function (updatedContent) {
        let removedContent = [];

        //Remove from the content stuff that is not on the content array anymore
        //This could be due to banned users, removed content, old content, etc
        let remainingContent = StateService.content.filter((post) => {
            const newPost = _.find(updatedContent, ['id', post.id]);
            if (!newPost) {
                Logger.log(['content'], 'Removing content: ' + post.id);
                removedContent.push(post);
            } else {
                //@TODO: UPDATE EVERYTHING RELEVANT HERE?
                post.gridThumbnail = newPost.gridThumbnail;
                post.thumbnail = newPost.thumbnail;
                post.zoomURL = newPost.zoomURL;

                post['user'] = post?.user ?? {};
                post.user.profilePicture = newPost.user?.profilePicture;
            }
            return !!newPost;
        });

        let addedContent = _.differenceWith(updatedContent, remainingContent, (post1, post2) => post1.id === post2.id);

        Logger.debug(['StateService', 'newcontent', 'parsenewcontent'], 'Parse new content :  added: '
            + addedContent.length + ', remaining: ' + remainingContent.length +
            ' removed: ' + removedContent.length);

        return {added: addedContent, remaining: remainingContent, removed: removedContent}

    },
    updateContent: (updatedContent, random_start = false) => {
        let parsedContent = StateService.parseNewContent(updatedContent);
        StateService.content = [...parsedContent.added, ...parsedContent.remaining];

        let addedNewContent = UpNextService.updateContent(StateService.content, parsedContent.added, parsedContent.remaining, parsedContent.removed, random_start);

        if (addedNewContent
            && !StateService.disableig
            && StateService.currentStateDef.name === 'plutotv'
            && StateService.currentState.isMediaWithIG) {
            if (parsedContent.added.length) {
                StateService.currentState.setIGContent(parsedContent.added[0]);
            } else {
                Logger.error(['StateSevice', 'updateContent'], "There is no new content for plutotv IG");
            }
        } else if (addedNewContent
            && !StateService.disableig
            && StateService.currentStateDef.name !== 'plutotv'
            && StateService.currentState.allowsIg) {
            StateService.queueState({name: 'socialzoom', duration: 5});
            StateService.advanceState();
        }
    },
    newContent: function newContent() {
        return UP.getContent().then(StateService.updateContent);
    },
    setNetworkStatus: function (status) {
        this.networkStatus = status;
        UP.setConnected(status);
        if (!this.networkStatus) {
            document.getElementById('network-down-indicator').className = "no-network";
        } else {
            document.getElementById('network-down-indicator').className = "";
        }
    },
    script: [],
    disableig: false,
    spotlightOnly: false,
    updateScripts: async () => {
        try {
            Logger.log(["StateService", 'updateScripts'], 'will update script schedules');
            const schedules = await UP.getScriptSchedules();
            ScriptService.stopScriptService();
            ScriptService.loadSchedulesScripts(schedules);
            ScriptService.startScriptService();
        } catch (error) {
            Logger.error(["StateService", 'updateScripts'], 'error trying to update script schedules');
        }
    },
    setScript: function (script) {
        StateService.nextStateIdx = -1;
        StateService.script_stringified = JSON.stringify(script);
        script = randomStart(script);
        Logger.debug(['upshowstate', 'loadscript'], StateService.script_stringified);
        console.log(['upshowstate', 'loadscript'], StateService.script_stringified);
        StateService.spotlightOnly = SettingsService.hasTrueUiSetting('spotlight_only');
        if (StateService.spotlightOnly) {
            Logger.log(['upshowstate'], 'Spotlight only flag detected, overriding script . ');
            StateService.script_stringified = JSON.stringify([{name: "spotlight", duration: 86400, spotlight_only: true}]);
            StateService.script = [{name: "spotlight", duration: 86400, spotlight_only: true}];
            StateService.disableig = true;
        } else {
            StateService.script = script.map(function (state) {
                state.name = state.state;
                state.duration = state.time;
                return state;
            });
            StateService.disableig = SettingsService.hasTrueUiSetting('disable_ig');
        }
    },
    setOverscan: function setOverscan(shouldOverscan) {
        const hasOverscan = document.body.classList.contains('overscan'); // Only used as flag
        if (shouldOverscan && !hasOverscan) {
            document.body.classList.add('overscan');
            window.overscan = true;
            fit_ui_container();
        } else if (!shouldOverscan && hasOverscan) {
            document.body.classList.remove('overscan');
            window.overscan = false;
            fit_ui_container();
        }
    },

    setFitToScreen: function setFitToScreen(fitToScreen) {
        const hasFitToScreen = document.body.classList.contains('fittoscreen'); // Only used as flag
        if (fitToScreen && !hasFitToScreen) {
            document.body.classList.add('fittoscreen');
            window.fittoscreen = true;
            fit_ui_container();
        } else if (!fitToScreen && hasFitToScreen) {
            document.body.classList.remove('fittoscreen');
            window.fittoscreen = false;
            fit_ui_container();
        }
    },

    //0,1,2,3, and hopefully n
    setBlackBackground: function setBlackBackground(blackBackground) {
        window.blackbackground = !!blackBackground;
        const hasBlackBackground = document.body.classList.contains('blackbackground'); // Only used as flag
        if (blackBackground && !hasBlackBackground) {
            document.body.classList.add('blackbackground');
        } else if (!blackBackground && hasBlackBackground) {
            document.body.classList.remove('blackbackground');
        }
    }
};

StateService.nextStateQueue = [];

StateService.queueState = function (state) {
    if (StateService.nextStateQueue.find((item) => item.name === state.name)) {
        //if we already have queued a state of the same kind, do nothing
        Logger.log(['stateservice', 'instantgratification'], 'Queuing state ' + state.name + '. Not queued. There is already same state in the queue.');
        return false;
    } else {
        let nextStateIdx = (StateService.nextStateIdx + 1) % StateService.script.length;
        let nextStateDef = Object.assign({}, StateService.script[nextStateIdx]);
        if (nextStateDef.name === state.name) {
            Logger.log(['stateservice', 'instantgratification'], 'Queuing state ' + state.name + '. Not queued. Same state is upcoming.');
            return false;
        } else {
            Logger.log(['stateservice', 'instantgratification'], 'Queuing state ' + state.name + '. Success.');
            StateService.nextStateQueue.push(state);
            return true;
        }

    }

};
StateService.correlativeNumber = 0;

StateService.nextSchMediaIdx = 0;

StateService.createNextState = function createNextState() {
    if (!StateService.loaded) {
        throw new Error("StateService was not loaded yet");
    }

    const takeoverSchedule = TakeoverService.getNextTakeOverSchedule();

    if (!!takeoverSchedule) {
        const takeoverData = _.cloneDeep(takeoverSchedule);
        StateService.currentStateDef = {
            name: takeoverData.schedule_type,
            takeover: true,
            duration: -1,
            source: 'scheduled_takeover',
            takeoverData,
            allowsIg: false,
        };
        Logger.debug(['stateservice', 'createnextstate'], 'Create next state for scheduledmedia ' + StateService.currentStateDef.name + '.');
        StateService.lastStateSource = "scheduled_takeover";
    } else if (StateService.nextStateQueue.length > 0) {
        StateService.currentStateDef = Object.assign({}, StateService.nextStateQueue.shift());
        Logger.debug(['stateservice', 'createnextstate'], 'Create next state from queue ' + StateService.currentStateDef.name + '.');
        StateService.lastStateSource = "queue";
    } else {
        StateService.nextStateIdx = (StateService.nextStateIdx + 1) % StateService.script.length;
        StateService.currentStateDef = StateService.script[StateService.nextStateIdx];
        Logger.debug(['stateservice', 'createnextstate'], 'Create next state from script ' + StateService.currentStateDef.name + '.');
        StateService.lastStateSource = "script";
    }

    let nextStateAllowsIg = !StateService.currentStateDef.disableig;

    let currentPost = Object.assign({}, UpNextService.getNext());

    if (StateService.currentStateDef.name === 'socialzoom') {
        Logger.debug(['index', 'nextstate'], 'is socialzoom, new post');
        currentPost = UpNextService.pop();
    }

    let currentMediaSpotlight = SpotlightService.currentMediaSpotlight;
    if (StateService.currentStateDef.name === 'media') {
        currentMediaSpotlight = SpotlightService.getNextMediaSpotlight();

        if (currentMediaSpotlight === null) {
            throw new StatePreconditionError("Can't create 'media' state without a media spotlight.");
        }

        nextStateAllowsIg = !currentMediaSpotlight.no_content;
        if (currentMediaSpotlight.duration) {
            StateService.currentStateDef.duration = currentMediaSpotlight.duration;
        }
    }

    let stateData = {
        contentArr: StateService.content.slice(0),
        duration: StateService.currentStateDef.duration,
        currentPost: currentPost,
        mediaspotlight: currentMediaSpotlight,
        settings: Object.assign({}, SettingsService.settings),
        state: Object.assign({}, StateService.currentStateDef),
    };

    let nextState;
    let hidden_container = ContainerService.createHiddenContainer(StateService.currentStateDef.name);
    try {
        nextState = StateFactory.create(StateService.currentStateDef.name, {}, hidden_container, stateData);
    } catch (e) {
        hidden_container.remove();
        throw e;
    }

    nextState.allowsIg = nextStateAllowsIg;
    nextState.correlativeNumber = StateService.correlativeNumber++;
    nextState.stateCreationDate = UP.getCurrentDate();

    if (Logger.isDebugEnabled()) {
        Logger.debug(['stateservice', 'debug', 'createnextstate'], 'created state',
            {name: nextState.name, correlative_number: nextState.correlativeNumber, state: stateData.state}
        );
    }
    return nextState;
};


StateService.preloadState = function preloadState(state) {

    let timeoutPromise = new Promise((resolve, reject) => setTimeout(() => reject("preloadState timeout"), 15000));

    let preloadPromise;

    try {
        preloadPromise = state.preload();
    } catch (error) {
        if (error.upshowType && error.upshowType === 'warning') {
            Logger.warn('stateservice', "State preload - Error executing preloadState for  " + state.name);
        } else {
            Logger.error('stateservice', error);
            Logger.error('stateservice', "State preload - Error executing preloadState for  " + state.name);
        }
        preloadPromise = Promise.reject(error);
    }
    return Promise.race([preloadPromise, timeoutPromise]);
};

StateService.playState = function playState(state) {

    let timeoutPromise = new Promise((resolve, reject) => setTimeout(() => reject("timeout"), 10000));

    let playPromise;

    try {
        playPromise = state.play();
    } catch (error) {
        Logger.error('stateservice', error);
        Logger.error('stateservice', "State preload - Error executing playState for  " + state.name);
        playPromise = Promise.reject(error);
    }

    return Promise.race([playPromise, timeoutPromise]);
};

StateService.stopState = function stopState(state) {

    if (!state) {
        return Promise.resolve();
    }

    Logger.info(['stateservice', 'stop'], "Stop state " + state.name);

    let timeoutPromise = new Promise((resolve, reject) => setTimeout(() => reject("timeout"), 10000));

    let stopPromise;

    try {
        stopPromise = state.stop();
    } catch (error) {
        Logger.error('stateservice', error);
        Logger.error('stateservice', "State stop - Error executing stopState for  " + state.name);
        stopPromise = Promise.reject(error);
    }

    return Promise.race([stopPromise, timeoutPromise]);
};

StateService.takeover_failed_states = [];

StateService.logFailedState = function (stateDef, reason) {
    const isTakeover = _.get(stateDef, 'takeover', false);

    if (isTakeover) {
        const fail = {
            state: _.cloneDeep(stateDef),
            reason,
            ts: Date.now(),
        }
        
        StateService.takeover_failed_states.push(fail);

        setTimeout(() => {
            StateService.takeover_failed_states = StateService.takeover_failed_states
            .filter( f => 
                f.ts !== fail.ts
            );
        }, 120*1000)
    }
}

StateService.swapState = function swapState() {
    Logger.log('stateservice', "Swap state: "
        + (StateService.currentState ? StateService.currentState.name : '') + " to "
        + (StateService.nextState ? StateService.nextState.name : ''));

    if (Logger.isDebugEnabled()) {
        Logger.debug(['stateservice', 'debug', 'swapstate'], 'SwapState ' + StateService.currentState.name +
            '  ' + StateService.currentState.correlativeNumber + ' to: ' + StateService.nextState.name +
            '  ' + StateService.nextState.correlativeNumber);
    }

    if (StateService.currentState.correlativeNumber === StateService.nextState.correlativeNumber) {
        Logger.error(['stateservice', 'error', 'swapstate'], "ERROR: Swapping from state to same state.  ");
        return;
    }

    let oldState = StateService.currentState;
    StateService.currentState = StateService.nextState;

    const state_name = _.get(StateService.currentState, 'state.state.name');
    if (state_name !== 'spotlight') {
        BackgroundMusicService.setPause(false);
    }

    StateService.stopState(oldState).catch((err) => {
        Logger.error(['stateservice', 'stop'], `Stop state ${ err?.message ?? 'NO error provided'}`);
    }).then(() => ContainerService.switchState(StateService.currentState, oldState))
        .then(() => {
            if (oldState) {

                try {
                    let unloaded = oldState.destroy();
                    Logger.info(['stateservice', 'stop', 'destroy'], "Destroy state " + oldState.name, {unloaded});
                } catch (err) {
                    // window.logException(err);
                    Logger.error(['stateservice', 'stop', 'destroy'], "Destroy state " + oldState.name + " - ERROR ", err);
                }

            }

            return StateService.playState(StateService.currentState)

        }).then((playing) => {
            StateService.currentState.doneCallback = (doneData) => {
                StateService.advanceState();
            };

            StateService.currentState.errCallback = (err) => {
                Logger.error('stateservice', `Error playing state through (will swap): ${_.get(StateService, 'currentState.name')}`);
                StateService.logFailedState(StateService.currentStateDef, 'play');
                StateService.advanceState();
            };

            return StateService.currentState.done();
        }).then((doneData) => StateService?.currentState?.doneCallback(doneData) )
        .catch((err) => {
            Logger.error('stateservice', `Error trying to play state (will swap): ${_.get(StateService, 'currentState.name')}`);

            _.invoke(StateService?.currentState, 'errCallback', err);

            StateService.logFailedState(StateService.currentStateDef, 'done');
            StateService.advanceState();
        });

};
StateService.advanceState = function advanceState() {
    let oldState = StateService.currentState;

    //We don't listen to what the old state has to say anymore
    oldState.doneCallback = null;
    oldState.errCallback = null;
    try {
        StateService.nextState = StateService.createNextState();
    } catch (e) {
        processUpshowError(e);

        setTimeout(function tryNextState() {
            StateService.logFailedState(StateService.currentStateDef, 'create');
            StateService.advanceState();
        }, 1000);
        return;
    }


    let state = StateService.nextState;

    StateService.preloadState(state).then(() => {
        Logger.info('index', "State preloaded, will swap in 1 second - " + state.name + " : " + state.mainContentUrl);
        setTimeout(function swap() {
            StateService.swapState();
        }, 1000);
    }).catch((err) => {

        if (!!state && !!state.state && !!state.state.state) {
            let maxretries = state.state.state.maxretries;
            let retryState = state.state.state;

            if (!!maxretries && maxretries > 0) {
                Logger.log('upshowstate', state.name + " failed and will retry ");
                retryState.maxretries = --maxretries;
                StateService.queueState(retryState);
            }
        }

        if (err && err.upshowType && err.upshowType === 'warning') {
            Logger.warn('upshowstate', `Failed state ${state.name} - preload warning: ${(err && err.message) ? err.message : err}`);
        } else {
            Logger.error('upshowstate', `Failed state ${state.name} - preload error: ${(err && err.message) ? err.message : err}`);
        }

        try {
            if (state && state.destroy) {
                state.destroy();
            }
        } catch (error) {
            Logger.error('upshowstate', "Error unloading failed state " + state.name);
        }

        setTimeout(function tryNextState() {
            StateService.logFailedState(StateService.currentStateDef, 'preload');
            StateService.advanceState();
        }, 1000);
    });
};

StateService.createLoadingState = function createLoadingState() {
    StateService.loaded = true;
    return StateFactory.create('loading', {}, ContainerService.createContainer(), {});
};

StateService.showLoadingState = function showLoadingState() {
    let currentState = StateService.createLoadingState();
    StateService.currentState = currentState;

    currentState.preload()
        .then(() => currentState.play())
        .then(() => currentState.done())
        .catch((err) => {
            Logger.error(['StateService', 'Loading'], err)
        })
};

StateService.getDebugInfo = async what => {
    switch (what) {
        case 'spotlights':
            return SpotlightService.spotlights;
        case 'active_spotlights':

            var currentIsoDate = UP.getCurrentISODate();
            var allActiveSpotlights = SpotlightService.filterSpotlights(SpotlightService.spotlights, currentIsoDate, null);
            return allActiveSpotlights;

        case 'timezone':
            return UP.getTimezone();
        default:
            throw new Error(`Unknown StateService.getDebugInfo "${what}"`);
    }

}

StateService.debug = what =>
    StateService.getDebugInfo(what)
        .then(debug_info => console.log("DEBUG", what, debug_info))
        .catch(err => console.error("DEBUG", err));

StateService.poll = async () => {
    await waitForUser;

    try {
        await StateService.updateSettings();
        await StateService.updateScheduledMedia();
        await StateService.newContent();
        await StateService.updateSpotlights();
        await StateService.updateScripts();
    } catch(error) {
        console.error(error);
    }
    setTimeout(StateService.poll, StateService.POLLER_INTERVAL);
};

export default StateService;
