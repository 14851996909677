import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';

import Iframe from '../iframe/Iframe';
import IframeMedia from '../iframe/IframeMedia';
import steadyservWhisper from './WhisperSteadyServ';

import SteadyServService from '../../services/SteadyServService';

import UpshowLogger from '../../Logger';
import ScreenService from "../../services/ScreenService";

export default class SteadyServState extends UPshowState {

    iframe = null;

    preload() {
        performance.mark(`state-preload-${this.name}`);

        return super.preload();
    }

    get name() {
        return 'steadyserv';
    }

    play() {
        UpshowLogger.debug('SteadyServState', `Will load url ${this.state.state.src}`);
        try {
            const timeOutPromise = new Promise((r, e) => setTimeout(() => e("timeout"), 5000));
            return Promise.race([this.iframePromise, timeOutPromise])
                .then((r) => {
                    UpshowLogger.debug('SteadyServState', `Called play and got ${r}`);

                    this.state.playing = true;

                    this._playDuration();

                    return this.raisePlaying();
                })
                .catch((e) => {
                    UpshowLogger.error('SteadyServState', `errored with ${e}`);
                    this.raiseError("error iframe play");
                });
        } catch (e) {
            UpshowLogger.error('SteadyServState', `Error ${e} calling play`);
            this.raiseError("error iframe play");
        }
    }

    _render(resolve) {
        let IframeComponent = this.state.state.layout === 'media' ? IframeMedia : Iframe;

        IframeComponent = steadyservWhisper(IframeComponent);

        ReactDOM.render(<IframeComponent
            ref={ (el) => this.iframe = el }
            src={this.state.state.src}
            onReady={this.raiseReady}
            onDone={this.raiseDone}
            onError={this.raiseError}
            onPlay={this.raisePlaying}
            customLogo={this.state.settings.custom_logo}
            content={this.state.contentArr}
        />, this.node);
    }

    raiseReady(pages) {
        performance.mark(`state-ready-${this.name}`);

        performance.measure('state-ready', `state-preload-${this.name}`, `state-ready-${this.name}`);

        const measures = performance.getEntriesByName("state-ready");
        const measure = measures[0];
        console.log("state-ready measure milliseconds:", measure.duration)

        UpshowLogger.log(['state-ready-measure', this.name], `${this.name} state ready measure millis: ${measure.duration}`, {duration:measure.duration});

        // Clean up the stored markers.
        performance.clearMarks();
        performance.clearMeasures();

        if (!Number.isNaN(Number.parseInt(pages, 10))) {
            SteadyServService.load(Number.parseInt(pages, 10));
            let page = SteadyServService.nextPage();
            this.iframePromise = this.iframe.invoke("play", page);
            super.raiseReady();
        } else {
            this.raiseError('SteadyServState: Pages is not a number.');
        }
    }

    destroy(){
        this.iframe = null;
        return super.destroy();
    }

    static appliesTo() {
        return !ScreenService.isVertical;
    }

}

StateFactory.register('steadyserv', SteadyServState, 10);
