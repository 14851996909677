import Media from "../media/Media";
import React from 'react';
import './IframeMedia.scss';

export default class IframeMedia extends Media {
    getIframeRef() {
        return this.iframe;
    }

    _bigBox() {
        return (
            <iframe className="media_iframe" ref={(iframe) => { this.iframe = iframe; }} src={this.props.src} title={this.props.src}/>
        );
    }

    componentWillUnmount(){
        this.iframe = null;
    }
}
