import React from "react";
import ReactDOM from "react-dom";
import UPshowState from "../UPshowState";
import StateFactory from "../StateFactory";
import SettingsService from "../../services/SettingsService";
import S2MSpotlight from "./S2MSpotlight";
import {getDeviceCode} from "../../Upshow";
import _get from "lodash/get";

export default class S2MSpotlightState extends UPshowState {
  get name() {
    return "s2m_spotlight";
  }

  preload() {
    this.upshowAppsUrl =
      SettingsService.getUiSetting("app_url") ||
      SettingsService.getUiSetting("default_app_url");
    return getDeviceCode().then( deviceCode => {
        this.deviceCode = _get(deviceCode, 'code', false);
        if(!this.deviceCode){
            super.raiseError("Failed preload on S2MSpotlight: Error tring to get device code");
        }
        return super.preload();
    });
  }

  _render(resolve) {
    ReactDOM.render(
      <S2MSpotlight
        deviceCode={this.deviceCode}
        upshowAppsUrl={this.upshowAppsUrl}
      />,
      this.node
    );
    super._render(resolve);
  }
}

StateFactory.register("s2m_spotlight", S2MSpotlightState, 10);
