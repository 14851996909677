class ScreenService {

    constructor(screenWidth, screenHeight) {
        this._screenWidth = screenWidth;
        this._screenHeight = screenHeight;
        this.orientationChangeEvent = new Event('screen_orientation_change');
    }

    setScreenDimensions(width, height) {
        const wasVertical = this.isVertical;

        this._screenWidth = width;
        this._screenHeight = height;

        if(wasVertical !== this.isVertical) {
            window.dispatchEvent(this.orientationChangeEvent);
        }
    }

    get screenWidth() {
        return this._screenWidth;
    }

    get screenHeight() {
        return this._screenHeight;
    }

    // Vertical is not supported in this webview
    get isVertical() {
        return false; // this.screenWidth < this.screenHeight;
    }

    get screenRatio() {
        return this.screenWidth / this.screenHeight
    }

    get uiWidth() {
        return this.isVertical ? 1080 : 1920;
    }

    get uiHeight() {
        return this.isVertical ? 1920 : 1080;
    }

    get uiRatio() {
        return this.uiWidth / this.uiHeight;
    }

}

export default new ScreenService(1920, 1080);