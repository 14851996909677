import validFromIsValid from "./validFromIsValid";
import validThroughIsValid from "./validThroughIsValid";
import getDayActiveRanges from "./getDayActiveRanges";
import timeRangeIsValid from "./timeRangeIsValid";

export default function scheduleIsValid(ISODate, schedule, default_tz) {

    const schedule_tz = schedule.tz ? schedule.tz : default_tz;

    return validFromIsValid(ISODate, schedule, schedule_tz) &&
        validThroughIsValid(ISODate, schedule, schedule_tz) &&
        getDayActiveRanges(ISODate, schedule, schedule_tz)
            .some(r => timeRangeIsValid(ISODate, r, schedule_tz));
}