import React from 'react';
import './Loading.scss';
import Lottie from '@upshow/lottie';
import horizontalAnimation from './horizontal/data.json';
import verticalAnimation from './vertical/data.json';
import ScreenService from '../../services/ScreenService';

const messages = ["Gaining consciousness...",
    "Self actualizing...",
    "Artificializing intelligence...",
    "Grabbing coffee with Siri...",
    "Remembering to hydrate...",
    "Stretching...",
    "Waking up the Internet...",
    "Flip-flopping the hoppledinger...",
    "Starting our engines..."];

const __RANDOM_MESSAGE__ = "__RANDOM_MESSAGE__";

class Loading extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: messages[Math.floor(Math.random() * messages.length)],
        };
    }

    render() {
        let animationTemplate = ScreenService.isVertical ? verticalAnimation : horizontalAnimation;
        let animationAsString = JSON.stringify(animationTemplate);
        let animationData = JSON.parse(animationAsString.replace(__RANDOM_MESSAGE__, this.state.message));

        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          };

        return (
            <div className="Loading">
                <Lottie options={defaultOptions} />
            </div>
        );
    }
}



export default Loading;