import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import Loading from './Loading';
import Start from './Start';

class LoadingState extends UPshowState {

  get name() {
    return 'loading';
  }


  preload() {

    ReactDOM.render(<Loading />, this.node);

    setTimeout(this._renderStart, 4000);

    return super.preload();

  }

  _renderStart = () => {
    ReactDOM.render(<Start />, this.node);
  }

}

StateFactory.register('loading', LoadingState, 10);

export default LoadingState;
