import Logger from '../Logger'
import * as UP from '../Upshow';

const HwFeatureService = {
    
    isSupported: function isSupported(feature) {
        if (typeof HwFeatureService.getFeatureMap()[feature] === "undefined") {
            Logger.log("HwFeatureService", `Feature '${feature}' support status is undefined`);
            return false;
        }

        return HwFeatureService.getFeatureMap()[feature];
    },

    getFeatureMap: function getFeatureMap() {
        const deviceInfo = UP.getDeviceInfo();
        
        if (deviceInfo.model.startsWith("AF")) {
            return {
                "cta-animations": false,
                "video-tile": false,
                "tile-flip": true,
                "blur-background": false,
                "caption_flip": false,
                "tile-flip-with-media": false,
                "pre-render": false,
                "video-in-media-layout": false,
                "media_no_crop": false,
                "overscan": false,
                "upshownow_videos": false,
                "upshownow_intro": false,
                "background_music": false
            };
        } else if (deviceInfo.model.startsWith("BrightSign")) {
            return {
                "cta-animations": false,
                "video-tile": false,
                "tile-flip": false,
                "blur-background": false,
                "caption_flip": false,
                "tile-flip-with-media": false,
                "pre-render": false,
                "video-in-media-layout": false,
                "media_no_crop": false,
                "overscan": false,
                "upshownow_videos": false,
                "upshownow_intro": false,
                "background_music": false
            };
        } else if (deviceInfo.userAgent && deviceInfo.userAgent.includes("iPhone")) {
            return {
                "cta-animations": true,
                "video-tile": false,
                "tile-flip": true,
                "blur-background": true,
                "caption_flip": true,
                "tile-flip-with-media": true,
                "pre-render": true,
                "video-in-media-layout": true,
                "media_no_crop": true,
                "overscan": true,
                "upshownow_videos": false,
                "upshownow_intro": true,
                "background_music": true
            };
        }

        return {
            "cta-animations": true,
            "video-tile": true,
            "tile-flip": true,
            "blur-background": true,
            "caption_flip": true,
            "tile-flip-with-media": true,
            "pre-render": true,
            "video-in-media-layout": true,
            "media_no_crop": true,
            "overscan": true,
            "upshownow_videos": true,
            "upshownow_intro": true,
            "background_music": true
        };
    }
};

export default HwFeatureService;
