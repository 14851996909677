import '../Containers.scss';
import { Animators } from './Animations';
import UpshowLogger from "../Logger";

const ContainerService = {
    rootWrapper: document.getElementById('root'),
    createContainer() {
        const container = document.createElement('div');
        container.className = 'container';
        ContainerService.rootWrapper.appendChild(container);
        return container;
    },
    createHiddenContainer() {
        const container = this.createContainer();
        container.className = 'container hidden';
        return container;
    },
    switchState(current, old) {
        //This function will return a promise that always resolved, either by itself or bu returning a fallaback animation
        //promise. The reason is because an error in or the lack of animation should not halt the state switch.
        //In sum, state switch *must* always succeed.

        return new Promise((resolve, reject)=>{
            try {
                Animators.replace.animate(current, old);
                current.node.classList.remove('hidden');
                resolve()
            } catch (e) {
                console.error(e);
                UpshowLogger.error(["ContainerService", "switchState"], e);
                return ContainerService.fallbackSwitch(current, old).then(resolve);
            }
        });

    },
    fallbackSwitch(current, old) {
        if (current.node) {
            current.node.classList.remove('hidden');
        }
        
        if (old.node) {
            old.node.classList.add('hidden');
        }

        return Promise.resolve();
    },
};

window.containers = ContainerService;

export default ContainerService;
