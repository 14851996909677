import React from "react";
import "./S2MSpotlight.scss";

export default class S2MSpotlight extends React.Component {
  render() {
    const { upshowAppsUrl, deviceCode } = this.props;
    const cleanUrl = upshowAppsUrl.replace(/^http(s?):\/\//gi, '');
    return (
      <div className="S2MSpotlight">
        <div className="cta">
            <div className="top">
                <h1>Want to learn more <br /> about llamas?</h1>
                <h2>Yeah, we thought you did.</h2>
            </div>
            <div className="bottom">
                <div>Go to</div>
                <div className="url">{cleanUrl}</div>

                <div>And enter code</div>
                <div className="code">{deviceCode}</div>
            </div>
        </div>
          <div className="image"></div>
      </div>
    );
  }
}
