import Logger from '../Logger'
import * as UP from '../Upshow';

const UPshowNowService = {
    loadChannelContent: (channel) => {
        //basically in-memory network first cache
        const previousPromise = channel.contentPromise;

        channel.contentPromise = UP.getUpshowNowChannelContent(channel.id)
            .then((content) => {
                channel.content = content;
                return content;
            }).catch((err) => {
                Logger.error(['upshownow'], err);
                channel.contentPromise = previousPromise;
                return channel.contentPromise;
            });

        return channel.contentPromise;
    },
    getChannel: (id) => {
        return UP.getUpshowNowChannel(id);
    },
    getChannelContent: (channel) => {
        if (!channel.contentPromise) {
            return UPshowNowService.loadChannelContent(channel);
        }
        else {
            return channel.contentPromise;
        }
    },
};

export default UPshowNowService;

