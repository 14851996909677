const SwearJarService = {
    badWords: ["anus","arse","arsehole","ass","ass-hat","ass-pirate","assbag",
        "assbandit","assbanger","assbite","assclown","asscock","asscracker","assface","assfuck",
        "assfucker","assgoblin","asshat","asshead","asshole","asshopper","assjacker","asslick",
        "asslicker","assmonkey","assmunch","assmuncher","assnigger","asspirate","assshit","assshole",
        "asssucker","asswad","asswipe","bampot","bastard","beaner","beastial","beastiality","beastility",
        "bestial","bestiality","bitch","bitchass","bitcher","bitchin","bitching","bitchtit","bitchy",
        "blow job","blowjob","bollocks","bollox","boner","bullshit","butt plug","camel toe","choad","chode",
        "clit","clitface","clitfuck","clusterfuck","cock","cockbite","cockburger","cockface","cockfucker",
        "cockhead","cockmonkey","cocknose","cocknugget","cockshit","cocksuck","cocksucked","cocksucker",
        "cocksucking","cocksucks","coochie","coochy","cooter","cum","cumbubble","cumdumpster","cummer",
        "cumming","cumshot","cumslut","cumtart","cunillingus","cunnie","cunnilingus","cunt","cuntface",
        "cunthole","cuntlick","cuntlicker","cuntlicking","cuntrag","cuntslut","cyberfuc","cyberfuck",
        "cyberfucked","cyberfucker","cyberfucking","dago","damn","deggo","dick","dickbag","dickbeaters",
        "dickface","dickfuck","dickhead","dickhole","dickjuice","dickmilk","dickslap","dickwad","dickweasel",
        "dickweed","dickwod","dildo","dink","dipshit","doochbag","dookie","douche","douche-fag","douchebag",
        "douchewaffle","dumass","dumb ass","dumbass","dumbfuck","dumbshit","dumshit","ejaculate","ejaculated",
        "ejaculates","ejaculating","ejaculation","fag","fagbag","fagfucker","fagging","faggit","faggot",
        "faggotcock","faggs","fagot","fags","fagtard","fart","farted","farting","farty","fatass","felatio",
        "fellatio","feltch","fingerfuck","fingerfucked","fingerfucker","fingerfucking","fingerfucks","fistfuck",
        "fistfucked","fistfucker","fistfucking","flamer","fuck","fuckass","fuckbag","fuckboy","fuckbrain",
        "fuckbutt","fucked","fucker","fuckersucker","fuckface","fuckhead","fuckhole","fuckin","fucking",
        "fuckme","fucknut","fucknutt","fuckoff","fuckstick","fucktard","fuckup","fuckwad","fuckwit","fuckwitt",
        "fudgepacker","fuk","gangbang","gangbanged","goddamn","goddamnit","gooch","gook","gringo","guido",
        "handjob","hardcoresex","heeb","hell","ho","hoe","homo","homodumbshit","honkey","horniest","horny",
        "hotsex","humping","jackass","jap","jigaboo","jism","jiz","jizm","jizz","jungle bunny","junglebunny",
        "kike","kock","kondum","kooch","kootch","kum","kumer","kummer","kumming","kums","kunilingus","kunt",
        "kyke","lezzie","lust","lusting","mcfagget","mick","minge","mothafuck","mothafucka","mothafuckaz",
        "mothafucked","mothafucker","mothafuckin","mothafucking","mothafucks","motherfuck","motherfucked",
        "motherfucker","motherfuckin","motherfucking","muff","muffdiver","munging","negro","nigga","nigger",
        "niglet","nut sack","nutsack","orgasim","orgasm","paki","panooch","pecker","peckerhead","penis",
        "penisfucker","penispuffer","phonesex","phuk","phuked","phuking","phukked","phukking","phuks",
        "phuq","pis","pises","pisin","pising","pisof","piss","pissed","pisser","pisses","pissflaps",
        "pissin","pissing","pissoff","polesmoker","pollock","poon","poonani","poonany","poontang",
        "porch monkey","porchmonkey","porn","porno","pornography","pornos","prick","punanny","punta",
        "pusies","pussies","pussy","pussylicking","pusy","puto","renob","rimjob","ruski","sandnigger",
        "schlong","scrote","shit","shitass","shitbag","shitbagger","shitbrain","shitbreath","shitcunt",
        "shitdick","shited","shitface","shitfaced","shitfull","shithead","shithole","shithouse","shiting",
        "shitspitter","shitstain","shitted","shitter","shittiest","shitting","shitty","shity","shiz",
        "shiznit","skank","skeet","skullfuck","slut","slutbag","sluts","smeg","smut","snatch","spic",
        "spick","splooge","spunk","tard","testicle","thundercunt","tit","tits","titfuck","tittyfuck",
        "twat","twatlips","twatwaffle","unclefucker","va-j-j","vag","vagina","vjayjay","wank","wetback",
        "whore","whorebag","whoreface","btch",
        "fook",
        "fookin",
        "fooking",
        "fck",
        "fcking",
        "af",
        "shite",
        "balls",
        "fecker",
        "mf",
        "puss",
        "ngga",
        "n*gga",
        "fack",
        "thot",
        "thot momma",
        "b*tch",
        "thotianna",
        "flamerbeast",
        "ta-tas",
        "tid ol biddies",
        "twot",
        "smegma",
        "titties",
        "titty",
        "tiddies",
        "hoohas",
        "jugs",
        "bazoogas",
        "shart",
        "knockers",
        "mthrfckr",
        "motherfcker",
        "motherfckr",
        "fckr",
        "fuckr",
        "f*ck",
        "boobz",
        "mua fucka",
        "ma fucka",
        "muddafucka",
        "muddafukka",
        "boobies",
        "boobs",
        "duh fuq",
        "basicbitch",
        "basicbitches",
        "basicbtch",
        "sloot",
        "slutty",
        "slooty",
        "slooze",
        "sloozey",
        "drunk",
        "hammered",
        "stoned",
        "high",
        "pothead",
        "stoner"],


    hasSwears: function hasSwears(text) {
        const regex = /\w+/g;
        let match = regex.exec(text);

        while (match) {
            const word = match[0];
            const key  = word.toLowerCase();

            if (SwearJarService.badWords.includes(key)) {
                return true;
            }

            match = regex.exec(text);
        }

        return false;
    }
};

export default SwearJarService;