import React from 'react';
import PropTypes from 'prop-types';
import ReactGridLayout from "react-grid-layout";

import './TileGrid3.scss';
import TileGridService from "../../services/TileGridService";
import DefaultCta from "../Cta/DefaultCta/DefaultCta";
import TileService3 from "../../services/TileService3";
import ScreenService from "../../services/ScreenService";
import Tile3 from "./Tile3/Tile3";
import SettingsService from "../../services/SettingsService";


class TileGrid3 extends React.Component {

    constructor(props) {
        super(props);

        const {content} = props;

        this.tileService = new TileService3(content);
        this.initializeGrid(props);

        this.state = {
            boxes: this.tileGridService.boxes,
        };

        this.refBoxes = [];
    }

    componentWillReceiveProps(nextProps) {
        const ctaSizeChanged = this.props.ctaWidth !== nextProps.ctaWidth || this.props.ctaHeight !== nextProps.ctaHeight;
        const gridSizeChanged = this.props.columns !== nextProps.columns || this.props.rows !== nextProps.rows;

        if (ctaSizeChanged || gridSizeChanged) {
            this.initializeGrid(nextProps);
            this.setState({boxes: this.tileGridService.boxes});
        }

        if (this.props.playing !== nextProps.playing){
            this.refreshBoxes(nextProps.playing);
            this.setState({boxes: this.tileGridService.boxes});
        }

    }

    componentWillUnmount(){
        this.refBoxes = [];
    }

    refreshBoxes(playing){
        // Set box content
        if (playing){
            this.refBoxes.forEach(box => {
                if (box && typeof box.play === 'function'){
                    box.play();
                }
            })
        } else {
            this.refBoxes.forEach(box => {
                if (box && typeof box.stop === 'function'){
                    box.stop();
                }
            })
        }
        
    }

    initializeGrid(props) {
        const {ctaWidth, ctaHeight, columns, rows, showCta, maxBoxSize, shouldTileFlip, relaxed} = props;
        let flipAllowed = shouldTileFlip;
        const noFlip = SettingsService.hasTrueUiSetting('disable_tile_flips'); // this ui setting disables flips, period
        if (noFlip) flipAllowed = false;

        let gridWidth = columns;
        let gridHeight = rows;
        // Create grid service
        this.tileGridService = new TileGridService({w: gridWidth, h: gridHeight}, !!maxBoxSize ? maxBoxSize : undefined);

        // Place CTA box
        if(showCta){
            const ctaSize = {w: ctaWidth, h: ctaHeight};
            const ctaComponent = (
                <DefaultCta
                    type='grid'
                    relaxed={relaxed}
                    onCtaImageError={this.props.onCtaImageError}
                />
            );
            const bottomRightCorner = {x: gridWidth - 1, y: gridHeight - 1, w: 1, h: 1};

            this.tileGridService.placeBoxRandomly(ctaSize, ctaComponent, bottomRightCorner);
        }

        // Fill remaining boxes
        this.tileGridService.fillWithRandomBoxes();

        // Set box content
        let flipDelay = 3;

        this.tileGridService.boxes.forEach(box => {
            if (!box.content) {
                const tileContent = this.tileService.getNextTileContent();

                box.content = (
                    <Tile3
                        ref={ el => this.refBoxes.push(el) }
                        content={tileContent}
                        tileService={this.tileService}
                        flipDelay={flipDelay}
                        flip={flipAllowed}
                    />
                );

                if (tileContent.length > 1) {
                    flipDelay += 2;
                }
            }
        })
    }

    render() {
        const {columns, rows } = this.props;
        let { boxMargin, gridWidth, gridHeight} = this.props;

        const {boxes} = this.state;

        boxMargin = boxMargin ? boxMargin : 3;
        gridWidth = gridWidth ? gridWidth : ScreenService.uiWidth;
        gridHeight = gridHeight ? gridHeight : ScreenService.uiHeight;
        const rowHeight = gridHeight / rows - (boxMargin + boxMargin / rows);
        
        return (
            <div className="TileGrid3">

                <ReactGridLayout
                    cols={columns}
                    rowHeight={rowHeight}
                    width={gridWidth}
                    isDraggable={false}
                    isResizable={false}
                    margin={[boxMargin, boxMargin]}
                >
                    {boxes.map((box) => (
                        <div
                            className='box'
                            key={`${box.x},${box.y},${box.w},${box.h}`}
                            data-grid={box}
                        >
                            {box.content}
                        </div>
                    ))}
                </ReactGridLayout>

            </div>
        );
    }

}

const ContentType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf([1, 2]).isRequired,
    gridThumbnail: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

TileGrid3.propTypes = {
    content: PropTypes.arrayOf(ContentType).isRequired,
    showCta: PropTypes.bool.isRequired,
    rows: PropTypes.number,
    columns: PropTypes.number,
    ctaHeight: PropTypes.number,
    ctaWidth: PropTypes.number,
    duration: PropTypes.number.isRequired,
    logo: PropTypes.string,
    onCtaImageError: PropTypes.func,
    relaxed: PropTypes.bool.isRequired,
};

TileGrid3.defaultProps = {
    columns: 7,
    rows: 4,
    ctaWidth: 3,
    ctaHeight: 3,
    boxMargin: 3,
    onCtaImageError: () => null,
    maxBoxSize: false,
    shouldTileFlip: true,
    relaxed: false
};

export default TileGrid3;