import UpshowLogger from './Logger'
import ContainerService from './services/ContainerService'
import fit_ui_container from "./libs/fit_ui_container";
import {detachMessageListener, clearTeslaCache, spaceQuotaTeslaCache} from "./Upshow";

class CommandProcessor {

    upshowState = null;

    constructor(upshowState) {
        this.upshowState = upshowState;
    }

    process(command) {
        if (!command.hasOwnProperty("command")) {
            UpshowLogger.debug(this.constructor.name, "Command does not have correct format");
            return;
        }

        UpshowLogger.log(this.constructor.name, "Processing " + command.command);

        switch (command.command) {
            case 'identify':
                let element = document.createElement('div');
                element.setAttribute('id', 'identify_notification');
                element.innerHTML = '<i/>';
                document.body.insertBefore(element, document.getElementById("root"));
                window.setTimeout(() => {
                    document.body.removeChild(element)
                }, 15000);
                break;
            case "restart":
                //let's add the restart reason
                const restartUrl = `${window.location.href}${window.location.href.indexOf('?')===-1?'?':'&'}boot_source=ui-restart`;
                window.location = restartUrl;
                break;
            case "new_content":
                this.upshowState.newContent();
                break;
            case "delete":
                //The token was deleted. We'll activate on reload @todo review
                window.location.reload();
                break;
            case "spotlights_updated":
                this.upshowState.updateSpotlights();
                break;
            case "plutotv_schedule_updated":
            case "media_schedule_updated":
                this.upshowState.updateScheduledMedia();
                break;
            case "script_updated":
                this.upshowState.updateScripts();
                break;
            case "settings_updated":
                this.upshowState.updateSettings();
                break;
            case "toggle_debug_layout":
                ContainerService.debugMode = !ContainerService.debugMode;
                break;
            case "overscan":
                window.overscan = command.parameters.enabled;
                fit_ui_container();
                break;
            case "clear_teslacache":
            case "clearteslacache":
                clearTeslaCache();
                break;
            case "spacequota":
                spaceQuotaTeslaCache();
                break;
            case "cache_clear":
                try {
                    //The token was deleted. We'll activate on reload @todo review
                    UpshowLogger.log(['CommandProcessor', 'cache_clear'], "Will clear localstorage ");
                    const currentToken = window.localStorage.getItem('access-token');

                    window.localStorage.clear();
                    UpshowLogger.log(['CommandProcessor', 'cache_clear'], "Cleared localstorage ");

                    if (currentToken) {
                        try {
                            window.localStorage.setItem('access-token', currentToken);
                        } catch (err) {
                            UpshowLogger.errorg(['CommandProcessor', 'cache_clear'], "Error saving access token after cache_clear ");
                        }

                    }

                } catch (clearCacheErr) {
                    UpshowLogger.log(['CommandProcessor', 'cache_clear'], clearCacheErr);

                }
                break;
            case "deponger":
                detachMessageListener();
                break;
            case "advance_state":
                this.upshowState.advanceState();
                break;
            default:
                UpshowLogger.info('CommandProcessor', "Skipping unknown command: " + command.command);
                break;
        }
    }
}

export default CommandProcessor;
