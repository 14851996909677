import UaParser from 'ua-parser-js';

class BrowserInfoService {

    constructor() {
        this._browserInfo = UaParser();
        console.log(this._browserInfo);
    }

    get supportsObjectFitWithTransform() {
        const {major, name} = this._browserInfo.browser;
        return !(name === 'Chrome' && (major === '44' || major === '45'));
    }

    get supportsApplication() {
        const {major, name} = this._browserInfo.browser;
        return !(name === 'Chrome' && (major === '44' || major === '45'));
    }

}

export default new BrowserInfoService();