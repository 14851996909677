import React from 'react';
import Media from "../media/Media";

export default function wrapInMediaLayout(WrappedComponent) {
    return class MediaLayout extends Media {

        playTile(){
            this.setState({playing : true});
        }

        _bigBox() {
            return (
                <WrappedComponent {...this.props}/>
            );
        }
    }
}