import _ from 'lodash';

export default (command, isVertical) => {
    const requiredProperties =  isVertical ? 'clockwise_rotated' : 'fullscreen';
    const spotlight = _.get(command, 'payload', null) ?? _.get(command, 'spotlight', null);
    const properties = _.get(spotlight, 'properties', []);

    if (!spotlight || !properties.find(prop => requiredProperties === prop)){
        return null;
    }

    spotlight.spotlightUrl = _.get(spotlight, 'background.url', '');
    spotlight.background = {
        ..._.get(spotlight, 'background', {}),
        backgroundUrl:_.get(spotlight, 'background.url', ''),
        backgroundType:_.get(spotlight, 'background.type', '')
    };
    return spotlight;
}