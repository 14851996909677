import React from 'react';
import "./Start.scss"

class Start extends React.Component {

    render() {
        return (
            <div className="frame">
                <div className="button" onClick={()=>window.dispatchEvent(new Event('start-clicked'))}>
                    <span>Join Now</span>
                    <svg>
                        <polyline className="o1" points="0 0, 900 0, 900 110, 0 110, 0 0"></polyline>
                        <polyline className="o2" points="0 0, 900 0, 900 110, 0 110, 0 0"></polyline>
                    </svg>
                </div>

            </div>
        );
    }
}

export default Start;