import React from "react";
import PropTypes from "prop-types";

import "./DefaultImageCta.scss";
import UPshowLogger from "../../../../Logger";
import {teslaCacheX} from "../../../../Upshow";

class DefaultImageCta extends React.Component {

    constructor(props) {
        super(props);
        this.onImageError = this._onImageError.bind(this);
    }

    _onImageError(error) {
        UPshowLogger.error('DefaultImageCta', 'Error loading cta custom image. ' + error.message);
        this.props.onError();
    }

    render() {
        const {className, imageUrl} = this.props;

        let url = teslaCacheX(imageUrl);

        return (
            <div className={`DefaultImageCta ${className}`}>
                <img src={url} onError={this.onImageError} alt=''/>
            </div>
        );
    }

}

DefaultImageCta.propTypes = {
    className: PropTypes.string,
    imageUrl: PropTypes.string.isRequired,
    onError: PropTypes.func,
};

DefaultImageCta.defaultProps = {
    className: '',
    onError: () => null,
};

export default DefaultImageCta;