import React from "react";
import PropTypes from "prop-types";
import { Textfit } from "react-textfit";
import SlimAnimatedGridCta from "./SlimAnimatedGridCta";

import "./DefaultTextCta.scss";

const LongMediaCta = ({isWithBoth, isAltCta, className, headerText, hashtags }) => (
        <div className={`DefaultTextCta ${className} LongMedia`} >
            <div className="header" >
                <Textfit
                    className="CtaText"
                    max={1000}>
                        {headerText} { !isAltCta && `Post With ${ isWithBoth ? "Both " : " "}`}
                </Textfit>
            </div>
            <div className="footer" >
                    <div className="bottom">
                        <Textfit
                            className="CtaHashtags"
                            max={1000}>
                            <span className="hashtags long_cta">
                                {hashtags.map((hashtag, i) => <div key={i}>#{hashtag}</div>)}
                            </span>
                        </Textfit>
                    </div>
                    <div className="top" >
                        <span className="icon network-tw" />
                        <span className="icon network-ig" />
                    </div>
            </div>  
        </div>
);

const AltTextMediaOrZoomCta = ({ type, className, headerText, hashtags }) => {
    const isMedia = type === 'media';
    const isZoom = type === 'zoom';
    return (<div style={ isZoom ? { margin: '0' } : {} } className={`DefaultTextCta ${className} altCta`} >
            <div className="header" >
                <Textfit
                    className={'CtaText'}
                    max={1000}>
                        {headerText}
                </Textfit>
            </div>
            <div className="footer" style={ isMedia ? { height: '150px', marginTop: '30px' } : {} } >
                    <div className="top" style={ isMedia ? { marginRight : '25px' } : { }}>
                        <span style={ isMedia ? { fontSize: '60px' } : {} } className="icon network-ig" />
                        <span style={ isMedia ? { fontSize: '60px' } : {} } className="icon network-tw" />
                    </div>
                    <div className="bottom">
                        <Textfit
                            className="CtaHashtags"
                            max={1000}>
                            <span className="hashtags">
                                {hashtags.map((hashtag, i) => <div key={i}>#{hashtag}</div>)}
                            </span>
                        </Textfit>
                    </div>
            </div>  
        </div>)
}

const ZoomOrMediaCta = (props) => {
        const { isWithBoth, type, isAltCta, className, headerText, hashtags, longCta } = props;
        return longCta && type !== 'zoom' ? <LongMediaCta {...props} /> :
                isAltCta ? <AltTextMediaOrZoomCta {...props} /> :
                <div className={`DefaultTextCta ${className} ${type} `} >
                    <div className="header" >
                        <Textfit
                            className={'CtaText'}
                            max={1000}>
                                {headerText}
                        </Textfit>
                    </div>
                    <div className="footer" >
                            <div className="top" >
                                <Textfit max={1000} className="postWith" mode="single" >POST WITH { isWithBoth ? "BOTH " : " "}</Textfit>
                                <div className="vBar" />
                                <span className="icon network-tw" />
                                <span className="icon network-ig" />
                            </div>
                            <div className="bar"  />
                            <div className="bottom">
                                <Textfit
                                    className="CtaHashtags"
                                    max={1000}>
                                    <span className="hashtags">
                                        {hashtags.map((hashtag, i) => <div key={i}>#{hashtag}</div>)}
                                    </span>
                                </Textfit>
                            </div>
                    </div>  
                </div>
};

class DefaultTextCta extends React.Component {

    render() {
        const { type, operator, hashtags } = this.props;

        const isWithBoth = operator === 'and' && hashtags.length === 2;

        switch(type){
            case 'default':
            case 'grid':
                return <SlimAnimatedGridCta {...this.props} isWithBoth={isWithBoth} />
            case 'zoom':
            case 'media':
                return <ZoomOrMediaCta {...this.props} isWithBoth={isWithBoth} />
            default:
                return <SlimAnimatedGridCta {...this.props} isWithBoth={isWithBoth} />
        }
    }
}

DefaultTextCta.propTypes = {
    className: PropTypes.string,
    headerText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    hashtags: PropTypes.arrayOf(PropTypes.string).isRequired,
    longCta: PropTypes.bool,
    isAltCta: PropTypes.bool,
    relaxed: PropTypes.bool,
};

DefaultTextCta.defaultProps = {
    className: '',
    ctaText: 'GET ON</br>THIS</br>SCREEN!',
    longCta: false,
    isAltCta: false,
    relaxed: false,
};

export default DefaultTextCta;