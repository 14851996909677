import Logger from "../Logger";

const TrackerService = {
  appEvents: null,
  trackEvent: (number, event) => {
    if (!TrackerService.appEvents) {
      Logger.error(
        "Tracking events when Tracker Service is not initialized",
        event
      );
    } else {
      TrackerService.appEvents.logAnalyticsEvent(number, event);
    }
  }
};

export default TrackerService;
