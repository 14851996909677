import {v4 as uuidv4} from 'uuid';
import md5 from 'blueimp-md5';
import UpshowLogger from './Logger';
import HwFeatureService from './services/HwFeatureService';
import SettingsService from "./services/SettingsService";

var pjson = require('../package.json');
var appVersion = '';
var uiVersion = pjson.version;
var connected = true;
var isChromeOs = false;
var isBrightSign = false;
var userAgent = '';
var activator;
var localUrlsCache = new Map();

var deviceCodes = [];

const start_time = (new Date()).getTime();

export function getUptime() {
    return (new Date()).getTime() - start_time;
}

export const ERROR_CACHE_NOT_FOUND = "Cache Not Found";
export const ERROR_DEACTIVATED = "Api Key Deactivated";

//Shamelessly copied from http://stackoverflow.com/questions/12049620/how-to-get-get-variables-value-in-javascript
export var $_GET = {};
if (document.location.toString().indexOf('?') !== -1) {
    var query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, '')
        .split('&');

    for (var i = 0, l = query.length; i < l; i++) {
        var aux = query[i].split('=');
        $_GET[aux[0]] = decodeURIComponent(aux[1]);
    }
}

if (typeof $_GET['app_version'] !== 'undefined') {
    //override the model
    appVersion = $_GET['app_version'];
}

var deviceId = $_GET['deviceId'];

var account_timezone = "America/Chicago";

export function setTimezone(new_timezone) {
    UpshowLogger.log(['upshow', 'settings', 'timezone'], "Set timezone to " + new_timezone);
    account_timezone = new_timezone;
}

function isTrueParam(param) {
    try {
        return JSON.parse(param) === true || JSON.parse(param) === 1;
    } catch (e) {
        return false;
    }

}

var disableCache = isTrueParam($_GET['ui.disable_cache']);


console.log("API Cache : ", !disableCache);
// If this variable is set to true we are going to use the static backend and disable app events polling
window.st = ($_GET['st'] === "1");

// Override appVersion
if (process.env.REACT_APP_APP_VERSION) {
    uiVersion = process.env.REACT_APP_APP_VERSION;
}
// We are in development mode so we are appending -dev to the end of the version
if (process.env.NODE_ENV === 'development') {
    uiVersion += '-dev';
}


console.info({"appVersion": appVersion, "uiVersion": uiVersion});

try {
    userAgent = navigator.userAgent;

    isChromeOs = /\bCrOS\b/.test(userAgent);

    isBrightSign = /^BrightSign/.test(userAgent);

}
catch (e) {
    console.error(e);
}


var model = 'WebView';
var make = 'WebView';

if (isChromeOs) {
    model = 'ChromeOS';
    //Asus means "Chromebit" for PlutoTV
    make = 'Asus';
}

if (isBrightSign) {
    model = 'BrightSign';
    make = 'BrightSign';

    var parts = userAgent.split('/');
    if (parts.length >= 3) {
        deviceId = parts[1];
    }
}

var modelProvided = false;
export var sessionId = uuidv4();

let fireOsModels = [
    "AFTRS",    //tv
    "AFTT",     // gen2 stick
    "AFTS",     // gen2 fire tv
    "AFTM",     // gen1 stick
    "AFTB",     // gen1 fire tv
];

for (let i = 0; i < fireOsModels.length; i++) {
    if (userAgent.indexOf(fireOsModels[i]) > -1) {
        model = fireOsModels[i];
        make = "Amazon";
    }
}

document.documentElement.className += " " + model;


var fetchHeaders = new Headers();
var fetchInit = {
    method: 'GET',
    headers: fetchHeaders,
    mode: 'cors',
    cache: 'default'
};

var apiToken = '';

function setupAjax(token) {
    fetchHeaders = new Headers();
    fetchHeaders.append('x-access-token', token);
    fetchHeaders.append('x-app-version', appVersion);


    if (window.st) {
        fetchHeaders.append('x-static', true);
    }
    fetchInit = {
        method: 'GET',
        headers: fetchHeaders,
        mode: 'cors',
        cache: 'default'
    };
}

export function setConnected(connectedStatus) {
    connected = connectedStatus;
}

export function isConnected() {
    return !!connected;
}

function getCachedResponse(url) {

    if (disableCache) {
        return Promise.reject(ERROR_CACHE_NOT_FOUND);
    }

    const cachedToken = window.localStorage.getItem('cachedtoken-' + url);
    if (cachedToken && apiToken !== cachedToken) {
        return Promise.reject(ERROR_CACHE_NOT_FOUND);
    }
    const cachedResponse = window.localStorage.getItem('cachedresponse-' + url);
    if (cachedResponse) {
        return Promise.resolve(cachedResponse);
    } else {
        return Promise.reject(ERROR_CACHE_NOT_FOUND);
    }
}


function cacheResponse(url, response) {
    return Promise.resolve().then(() => {
        window.localStorage.setItem('cachedtoken-' + url, apiToken);
        window.localStorage.setItem('cachedresponse-' + url, response);
        return response;
    });
}


function bailOrFail(url, err) {
    console.error(['upshow', 'bailOrFail'], err);
    UpshowLogger.error(['upshow', 'bailOrFail'], " :url: " + url);
    UpshowLogger.error(['upshow', 'bailOrFail', 'err'], err);

    if (err && err.status && err.status === 430) {
        const hasWrapper = isChromeOs || make === 'Amazon';

        activator
            .cleanup()
            .then(() => {
                if (!hasWrapper) {
                    window.location.reload();
                } else {
                    detachMessageListener();
                }
            })
        throw ERROR_DEACTIVATED;
    } else {
        throw err;
    }
}

function cachingFetchJson(url, fetchInit, cacheIt = true, bailOnError = true) {


    const errorHandler = bailOnError ?
        err => bailOrFail(url, err) :
        err => {
            UpshowLogger.error(['upshow', 'cachingFetchJson', 'fetch', 'response'], err);
            throw err;
        };
    return fetch(url, fetchInit)
        .then(response => {
            if (response.ok) {
                return response.json()
                    .then(json => {
                        if (cacheIt) {
                            try {
                                cacheResponse(url, JSON.stringify(json)).catch(err => {
                                    UpshowLogger.error(['upshow', 'cachingFetchJson', 'fetch'], `FETCH FAILED - error saving ${url} on localStorage ${err}`);
                                });
                            }
                            catch (cachingErr) {
                                UpshowLogger.error(['upshow', 'cachingFetchJson', 'fetch'], `FETCH FAILED - error trying to save ${url} on localStorage ${cachingErr}`);
                            }
                        }
                        return json;
                    })
                    ;
            } else {
                UpshowLogger.error(['upshow', 'cachingFetchJson', 'fetch'], `FETCH FAILED - Got Error Response on api call ${url} ${response}`);
                //If response is not OK, it is an error, period
                return Promise.reject(response);
            }
        })
        .catch(errorHandler);
}


//Libraries
function classifyContent(res, isUPshowNow = false) {
    return Promise.resolve(res.reduce(function (sum, post) {
        if (post.isDeleted) {
            return sum;
        }

        post.url = post.url.replace(/^http:\/\//i, 'https://');
        if (post.type !== 2) {
            post.thumbnail = post.url;
        } else {
            post.thumbnail = post.thumbnail.replace(/^http:\/\//i, 'https://');
        }

        post.gridThumbnail = post.thumbnail;

        if (post.type === 2) {
            if (!HwFeatureService.isSupported("upshownow_videos") && isUPshowNow) {
                return sum;
            }

            if (post.network === 'instagram') {
                post.zoomURL = post.thumbnail;
            } else {
                post.zoomURL = post.thumbnail;
            }
        } else {
            post.zoomURL = post.url;
        }

        post.description = post.description.replace(/[^\s]#/g, ' #')

        sum.push(post);
        return sum;
    }, []));
}


function processSpotlights(data) {
    return data.spotlights;
}

const apiUrls = {
    scripts_schedules: process.env.REACT_APP_REST_URL + '/v4/scripts_schedules',
    spotlights: process.env.REACT_APP_REST_URL + '/v4/spotlights',
    content: process.env.REACT_APP_REST_URL + '/v4/content/latest',
    upshownow: process.env.REACT_APP_REST_URL + '/v4/media/upshownow/',
    upshownow_schedules: process.env.REACT_APP_REST_URL + '/v4/upshownow_schedules/',
    settings: process.env.REACT_APP_REST_URL + '/v4/settings_v2',
    all_schedules: process.env.REACT_APP_REST_URL + '/v4/all_schedules',
    device_code: process.env.REACT_APP_REST_URL + '/v4/device_code',

    application_instance : process.env.REACT_APP_REST_URL + '/v4/media/application_instance/', // {application_instance, error, server_error}
    jukin_v2 : process.env.REACT_APP_REST_URL + '/v4/media/jukin_v2/', // {jukin_channel_content, error, server_error}
    plutotv : process.env.REACT_APP_REST_URL + '/v4/media/plutotv/', // {pluto_channel, error, server_error}
    upshownow_v2 : process.env.REACT_APP_REST_URL + '/v4/media/upshownow_v2/', // {upshownow_channel, error, server_error}
    spotlight : process.env.REACT_APP_REST_URL + '/v4/media/spotlight/', // {spotlight, error, server_error}
};

export function getDeviceCode(app_instance_id) {
    //No time travel for activation
    let currentDateTs = Math.round((new Date()).getTime() / 1000);

    const cacheKey = app_instance_id !== undefined ? app_instance_id : "global";
    const cachedDeviceCode = deviceCodes[cacheKey];

    if (cachedDeviceCode !== undefined && cachedDeviceCode.expiration_ts > currentDateTs) {
        return Promise.resolve(cachedDeviceCode);
    } else {
        return fetch(`${apiUrls.device_code}${app_instance_id ? '?app_instance_id=' + app_instance_id : ''}`, fetchInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    UpshowLogger.error(['upshow', 'fetch_device_code', 'fetch'], `FETCH FAILED - Got Error Response on api call ${apiUrls.device_code} ${response}`);
                    return Promise.reject(response);
                }
            })
            .then(data => {
                deviceCodes[cacheKey] = data;
                return data;
            })
    }
}

export async function getContent() {
    return SettingsService.hasFeature('social')
        ? getApiResourceJson('content')
            .then(data => data.content)
            .then(classifyContent)
        : [];
}

export async function getCachedContent() {
    return SettingsService.hasFeature('social')
        ? getCachedApiResourceJson('content')
            .then(data => data.content)
            .then(classifyContent)
        : [];
}

export async function getSpotlights() {
    return SettingsService.hasFeature('spotlights')
        ? getApiResourceJson('spotlights')
            .then(processSpotlights)
        : [];
}

export async function getCachedSpotlights() {
    return SettingsService.hasFeature('spotlights')
        ? getCachedApiResourceJson('spotlights')
            .then(processSpotlights)
        : [];
}

export function getScheduledMedia() {
    return fetchScheduledMedia(getApiResourceJson);
}

export async function getCachedScheduledMedia() {
    return fetchScheduledMedia(getCachedApiResourceJson);
}

export function fetchScheduledMedia(apiCall) {
    return Promise.all([
        apiCall('upshownow_schedules'),
        apiCall('all_schedules')
    ])
        .then(
            ([
                upshownow_schedules,
                all_schedules
            ]) => processScheduleMedia(upshownow_schedules, all_schedules));
}

export function processScheduleMedia(upshownow_schedules, all_schedules) {
    const mappedUpshownowOrgSchedules = (upshownow_schedules && upshownow_schedules.schedules) ? upshownow_schedules.schedules.map(sch => ({
        ...sch,
        schedule_type: 'upshownow'
    })) : [];

    let schedules = [];

    if (!!all_schedules) {
        Object.keys(all_schedules).forEach((key) => {
            all_schedules[key].forEach(sch => schedules.push({
                ...sch,
                schedule_type: key,
            }));
        });
    }

    return [...schedules, ...mappedUpshownowOrgSchedules];
}

export async function getCachedApiResourceJson(resourceName) {
    return getCachedResponse(apiUrls[resourceName]).then(JSON.parse);

}

export function getApiResourceJson(resourceName, cacheIt = true) {
    return cachingFetchJson(apiUrls[resourceName], fetchInit, cacheIt);
}


export function getUpshowNowChannelContent(channelId) {
    return cachingFetchJson(apiUrls['upshownow'] + channelId, fetchInit, false)
        .then((res) => classifyContent(res, true));
}

export function getUpshowNowChannel(channelId) {
    return cachingFetchJson(apiUrls['upshownow_v2'] + channelId, fetchInit, false)
        .then( async (response) => {
            let upshownow_channel = response?.upshownow_channel ?? {};
            const content = await classifyContent(upshownow_channel.content ?? [], true);
            return {
                ...response,
                upshownow_channel: {
                    ...upshownow_channel,
                    content
                }
            };
        });
}

export function getJukinChannelData(channelId) {
    return cachingFetchJson(apiUrls['jukin_v2'] + channelId + "?limit=100", fetchInit, false);
}

export function getApplicationInstance(id) {
    return cachingFetchJson(apiUrls['application_instance'] + id, fetchInit, false);
}

export function getPlutoChannel(channelId) {
    return cachingFetchJson(apiUrls['plutotv'] + channelId, fetchInit, false);
}

export function getSpotlightData(spotlightId) {
    return cachingFetchJson(apiUrls['spotlight'] + spotlightId, fetchInit, false);
}

export function getSettings(bailOnError = true) {
    return cachingFetchJson(apiUrls['settings'], fetchInit, true, bailOnError)
        .then(data => data.settings);
}

export function getScriptSchedules(bailOnError = true) {
    return cachingFetchJson(apiUrls['scripts_schedules'], fetchInit, true, bailOnError);
}

export async function getCachedSettings() {
    return getCachedApiResourceJson('settings')
        .then(data => data.settings)
}

export function getDeviceInfo() {
    if (typeof $_GET['model'] !== 'undefined') {
        //overrride the model
        model = $_GET['model'];
        modelProvided = true;
    }

    if (typeof deviceId !== 'undefined') {
        return {
            version: appVersion,
            uiVersion: uiVersion,
            connected: connected,
            model: model,
            modelProvided: modelProvided,
            userAgent: userAgent,
            serial: deviceId,
            sessionId: sessionId,
            make: make,
            DeviceUptime: getUptime()
        };
    }

    return {
        version: appVersion,
        uiVersion: uiVersion,
        connected: connected,
        model: model,
        modelProvided: modelProvided,
        userAgent: userAgent,
        sessionId: sessionId,
        make: make,
        DeviceUptime: getUptime()
    };
}

export function setToken(token) {
    apiToken = token;
    setupAjax(apiToken);
}

export function getToken() {
    return apiToken;
}

export function setActivator(a) {
    activator = a;
}

export function getLocationQueryParam(name) {
    return $_GET[name];
}

const upshow_paths_regexp = /https:\/\/((cdn|spotlightscdn)\.upshow\.tv)\//;

export async function teslaCacheY(given_url) {
    const sleep = async () => new Promise((resolve, reject) => {
        setTimeout(function () {
            resolve()  // Yay! Everything went well!
        }, 5000);
    });

    let attempt = 0;
    while (attempt++ < 10) {
        UpshowLogger.debug(['upshow', 'teslaCacheY'], `teslaCacheY attempt ${attempt - 1} for url ${given_url}`);

        const teslaUrl = teslaCacheX(given_url, (err)=>{throw new Error(err)});

        if (teslaUrl !== given_url) {
            return teslaUrl;
        }

        await sleep();
    }

    throw new Error();
}
export function teslaCacheX(given_url, errorCallback) {
    const onError = (err) => {
        if (typeof errorCallback === "function") {
            errorCallback(err);
        }
    };

    // This is a general replacement from static to CDN
    given_url = given_url
        .replace('https://s3.amazonaws.com/static.upshow.tv/', 'https://cdn.upshow.tv/')
        .replace('https://s3.amazonaws.com/spotlights.upshow.tv/', 'https://spotlightscdn.upshow.tv/');

    let parsedUrl = new URL(given_url);
    if (upshow_paths_regexp.test(given_url)) {
        if (deviceId) {
            parsedUrl.searchParams.append('device_serial', deviceId);
        }
        parsedUrl.searchParams.append('organization_id', SettingsService.getSetting('organization_id'));
        parsedUrl.searchParams.append('device_id', SettingsService.getSetting('device_id'));
    }
    const url = parsedUrl.toString();

    const urlMd5 = md5(url);
    const localUrl = localUrlsCache.get(urlMd5);

    if (localUrl) {
        UpshowLogger.info(['upshow', 'teslaCacheX', 'localUrl'], `${url} - ${localUrl}`);
        return localUrl;
    } else {
        if (!!window.proxyBridge && !!window.proxyOrigin) {
            let msg = {action: "teslaCache", url: url};
            window.proxyBridge.postMessage(msg, window.proxyOrigin);
        } else {
            onError('Tesla Cache is OFF on this Chromebit');
        }

        return url;
    }
}

/**
 * Tesla Cache XI
 * @param {*} given_url 
 * @param {*} errorCallback 
 * 
 * @returns { url: String , from_cache: Boolean } url_info
 * 
 */
export function teslaCacheXI(given_url, errorCallback) {
    const onError = (err) => {
        if (typeof errorCallback === "function") {
            errorCallback(err);
        }
    };

    // This is a general replacement from static to CDN
    given_url = given_url
        .replace('https://s3.amazonaws.com/static.upshow.tv/', 'https://cdn.upshow.tv/')
        .replace('https://s3.amazonaws.com/spotlights.upshow.tv/', 'https://spotlightscdn.upshow.tv/');

    let parsedUrl = new URL(given_url);
    if (upshow_paths_regexp.test(given_url)) {
        if (deviceId) {
            parsedUrl.searchParams.append('device_serial', deviceId);
        }
        parsedUrl.searchParams.append('organization_id', SettingsService.getSetting('organization_id'));
        parsedUrl.searchParams.append('device_id', SettingsService.getSetting('device_id'));
    }
    const url = parsedUrl.toString();
    const url_info = { url , from_cache: false };

    const urlMd5 = md5(url);
    const localUrl = localUrlsCache.get(urlMd5);

    if (localUrl) {
        UpshowLogger.info(['upshow', 'teslaCacheX', 'localUrl'], `${url} - ${localUrl}`);
        url_info.url = localUrl;
        url_info.from_cache = true;
        return url_info;
    } else {
        if (!!window.proxyBridge && !!window.proxyOrigin) {
            let msg = {action: "teslaCache", url: url};
            window.proxyBridge.postMessage(msg, window.proxyOrigin);
        } else {
            onError('Tesla Cache is OFF on this Chromebit');
        }

        return url_info;
    }
}

export function clearTeslaCache() {
    localUrlsCache.clear();
    if (!!window.proxyBridge && !!window.proxyOrigin) {
        let msg = {action: "removecache"};
        window.proxyBridge.postMessage(msg, window.proxyOrigin);
    }
}

export function spaceQuotaTeslaCache() {
    if (!!window.proxyBridge && !!window.proxyOrigin) {
        let msg = {action: "spacequota"};
        window.proxyBridge.postMessage(msg, window.proxyOrigin);
    }
}

export function windowMessageListener(event) {
    if (event.data === "ping") {
        event.source.postMessage("pong",
            event.origin);
    } else if ((event.data.action && event.data.action === "pingTeslaCache")
        || event.data === "pingTeslaCache") {

        if (!window.proxyBridge || !window.proxyOrigin) {
            window.proxyBridge = event.source;
            window.proxyOrigin = event.origin;
        }

        if (localUrlsCache.size === 0) {
            let msg = {action: "getTeslaCache"};
            window.proxyBridge.postMessage(msg, window.proxyOrigin);
        }

    } else if (event.data.action && event.data.action === "teslaCacheResponse") {
        const { teslaCache } = event.data;
        if (Array.isArray(teslaCache)) {
            teslaCache.forEach( cacheElement => {
                const { localUrl, urlMd5 } = cacheElement;
                localUrlsCache.set(urlMd5, localUrl);
            });
        } else {
            const { localUrl, urlMd5 } = event.data;
            localUrlsCache.set(urlMd5, localUrl);
        }

    } else if (event.data.action && event.data.action === "teslaCacheRemoveKey") {
        const { key } = event.data;
        localUrlsCache.delete(key);
    }
};

export function attachMessageListener() {
    //Respond to keep alive only after initialization success
    window.addEventListener("message", windowMessageListener, false);
}

export function detachMessageListener() {
    //Respond to keep alive only after initialization success
    window.removeEventListener("message", windowMessageListener);
    localUrlsCache.clear();
}

/**
 * Process boot reason extracting the info form the $_GET and modifying the URL so it doesn't parse the same boot
 * reason on reload
 */
export function processBootSource() {
    let rawSource = typeof $_GET['boot_source'] !== 'undefined' ? $_GET['boot_source'] : 'n/a';
    const source = rawSource.toLowerCase();

    let bootEvent = {type: "boot", source: source, error: false};

    switch (source) {
        case 'frozen':
        case 'crash':
            bootEvent.error = true;
            break;
        case 'boot':
        case 'restart':
        case 'update':
        case 'user':
        default:
            break;
    }


    UpshowLogger.log(["tt-analytics", "boot"], bootEvent, {source: bootEvent.source, is_error: bootEvent.error});
    //set clean url

    const regex = /((boot_source=)([^&]+)&?)|((\?boot_source=)([^&]+)$)|(&(boot_source=)([^&]+)$)/g;
    const newUrl = document.location.href.replace(regex, '');

    window.history.replaceState({}, 'UPshow', newUrl)
}


export function getTimezone() {
    return account_timezone;
}

let timestampOffset = 0;

export function setTimestampOffset(offset) {
    timestampOffset = offset;
}

export function setCurrentISODate(isoString) {
    UpshowLogger.log(['upshow', 'settings', 'timestamp'], "Set current date to " + isoString);

    const newDate = new Date(isoString);
    const offset = newDate.getTime() - Date.now();
    setTimestampOffset(offset);

}

export function hasTimestampOffset() {
    return timestampOffset !== 0;
}

export function canReportTts() {
    // Disable TTS reporting when timestamp is forced
    return !hasTimestampOffset();
}
export function getCurrentTimestamp() {
    return Date.now() + timestampOffset;
}

export function getCurrentDate() {
    return new Date(getCurrentTimestamp());
}

export function getCurrentISODate() {
    return getCurrentDate().toISOString();
}
