import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import Transparent from "./Transparent";
import * as UP from '../../Upshow';

export default class TransparentState extends UPshowState {

    get name() {
        return 'transparent';
    }

    _render(resolve) {
        ReactDOM.render(<Transparent debug={UP.getDeviceInfo()}/>, this.node);

        resolve();
        this.raiseReady(); this.raisePlaying();
    }

}

StateFactory.register('transparent', TransparentState, 10);
