
const SteadyServService = {
    pages: 0,
    currentPage: 1,

    load: (pages) => {
        SteadyServService.pages = pages;
    },

    nextPage: () => {
        if (SteadyServService.pages === 0) {
            throw new Error("SteadyServ loaded without any pages!!!");
        }

        if(SteadyServService.currentPage > SteadyServService.pages){
            SteadyServService.currentPage = 1;
        }
        return SteadyServService.currentPage++;
    }
};
export default SteadyServService;