class AbstractState {
    constructor(node, state) {
        this.node = node;
        this.state = state;

    }

    get name() {
        throw Error('name needs to be implemented');
    }


    get mainContentUrl() {
        throw Error('mainContentUrl needs to be implemented');
    }


    preload() {
        throw Error('preload needs to be implemented and return a promise');

    }

    play() {
        throw Error('play needs to be implemented and return a promise that resolves when state starts playing');
    }

    done() {
        throw Error('done needs to be implemented and return a promise that resolves when state completes playing');

    }


    pause() {
        throw Error('pause needs to be implemented and return a promise that resolves when state completes pausing');
    }

    stop() {
        throw Error('pause needs to be implemented and return a promise that resolves when state completes stopping');
    }

    destroy() {
        throw Error('destroy is a sync method, and needs to perform all needed cleanup');
    }

    static appliesTo(stateOptions) {
        throw Error('appliesto needs to return true only if the state can handle a given set of options');
    }

}

export default AbstractState;
