import * as boh_validations from './BOH/helpers/boh_validations';
export default function validate(spotlight) {
  // Checks for BOH Spotlights
  const isBohSpotlight = !!spotlight.properties.find(p => p === 'boh_spotlight');

  if (isBohSpotlight) {
    const boh_type = spotlight.properties.find(p => p === null || p === void 0 ? void 0 : p.startsWith('boh_type_'));
    let check = true;

    switch (boh_type) {
      case 'boh_type_training_course':
        check = boh_validations.training_status_validations(spotlight.boh_data);
        break;

      case 'boh_type_location_avg_ticket':
        check = boh_validations.avg_ticket_validations(spotlight.boh_data);
        break;

      case 'boh_type_customer_comments':
        check = boh_validations.customer_comments_validations(spotlight.boh_data);
        break;

      case 'boh_type_shift_log':
        check = boh_validations.shift_logs_validations(spotlight.boh_data);
        break;

      case 'boh_type_car_count':
        check = boh_validations.car_count_validations(spotlight.boh_data);
        break;

      case 'boh_type_nps':
        check = boh_validations.nps_validations(spotlight.boh_data, spotlight.metadata);
        break;

      case 'boh_type_drive_thru_sos':
        check = boh_validations.drive_thru_sos_validations(spotlight.boh_data);
        break;

      case 'boh_type_locations_sales_growth':
        // apparently this has no checks
        break;

      case 'boh_type_huddle_board':
        check = boh_validations.huddle_board_validations(spotlight.boh_data, spotlight.metadata);
        break;

      case 'boh_type_avg_check_size':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;

      case 'boh_type_product_sales_leaderboard':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;

      case 'boh_type_avg_guest_spend':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;

      case 'boh_type_thermometer_sales':
        check = boh_validations.daily_sales_validations(spotlight.boh_data);
        break;

      case 'boh_type_percentage_to_goal':
        check = boh_validations.daily_sales_validations(spotlight.boh_data);
        break;

      case 'boh_type_same_store_sales':
        check = boh_validations.same_store_sales_validations(spotlight.boh_data);
        break;

      case 'boh_type_product_sales_by_store':
      case 'boh_type_blazin_by_store':
        check = boh_validations.locations_leaderboard_validations(spotlight.boh_data);
        break;

      case 'boh_type_bio':
        break;

      case 'boh_type_blazin_attachment':
        check = boh_validations.rewards_attachment_validations(spotlight.boh_data);
        break;

      default:
        return {
          error: `Invalid boh_type ${boh_type}`
        };
    }

    if (check !== true) return check;
  } // By default every spotlight is valid


  return true;
}