import React from 'react';
import _ from 'lodash';
import { IframeBridge } from 'wisper-rpc';
import {teslaCacheX} from "../../Upshow";
import UpshowLogger from "../../Logger";

export default function iframeWithWhisper(WrappedComponent) {
    return class WhisperIframe extends React.Component {
        bridge = null;
        watchdogTimeout = null;

        invoke(method, ...args) {
            if (this.bridge === null) {
                return Promise.reject("Iframe not ready");
            }

            return this.bridge.invoke(method, [...args]);
        }

        componentDidMount() {
            this.bridge = new IframeBridge(this.iframe.getIframeRef().contentWindow);

            if (typeof this.props.onMounted === 'function') {
                this.props.onMounted();
            }

            this.bridge.exposeFunction('onReady', () => {
                _.invoke(this.props, 'onReady');

                return true;
            });

            this.bridge.exposeFunction('onPlay', () => {
                _.invoke(this.props, 'onPlay');
                
                return true;
            });

            this.bridge.exposeFunction('onError', (error) => {
                UpshowLogger.error(['whisper', 'iframe'], `onError has been called with error: '${JSON.stringify(error, Object.getOwnPropertyNames(error))}'`);
                _.invoke(this.props, 'onError');
                
                return true;
            });

            this.bridge.exposeFunction('onDone', () => {
                _.invoke(this.props, 'onDone');

                return true;
            });
            
            this.bridge.exposeFunction('teslaCacheX', teslaCacheX);

            if (this.props.disableWatchdog !== true) {
                this._watchdog();
            }

        }

        componentWillUnmount() {
            this.bridge.close();
            this.iframe = null;
            this.bridge = null;
            clearTimeout(this.watchdogTimeout);
        }

        _watchdog() {
            this.watchdogTimeout = setTimeout(()=>{
                let timeoutPromise = new Promise((resolve, reject)=>setTimeout(reject, 1000));
                Promise
                    .race([this.invoke("ping"), timeoutPromise])
                    .then(()=>this._watchdog())
                    .catch((e)=>_.invoke(this.props, 'onError'));
            }, 30000);
        }

        render() {
            return <WrappedComponent ref={(iframe)=>{this.iframe = iframe}} {...this.props} />
        }
    }
}
