import React from "react";
import PropTypes from "prop-types";
import {teslaCacheX} from "../../../Upshow";

import "./TileFace.scss";
import SettingsService from "../../../services/SettingsService";
import HwFeatureService from "../../../services/HwFeatureService";

class TileFace extends React.Component {

    constructor(props) {
        super(props);
        this.setContainerRef = this._setContainerRef.bind(this);
        this.onContentError = this._onContentError.bind(this);

        this.state = {
            playing: this.props.playing
        };
    }

    componentWillReceiveProps(newProps) {
        if (this.props.playing !== newProps.playing) {
            if (this.video && newProps.playing) {
                this.video.play();
            } else if (this.video && !newProps.playing) {
                this.video.pause();
            }
        }
    }

    _setContainerRef(el) {
        const {id, containerRef} = this.props;
        containerRef(el, id);
    }

    _onContentError() {
        const {id, onError} = this.props;
        onError(id);
    }

    render() {
        const {thumbnail, type, url, typeCount} = this.props;

        // Flags
        const supportsVideo = HwFeatureService.isSupported('video-tile');
        const supportsNoCrop = HwFeatureService.isSupported('media_no_crop');
        const supportsBlurBackground = HwFeatureService.isSupported('blur-background');
        const teslaCacheSocialGrid = !(SettingsService.getUiSetting('no_socialgrid_teslacache') === "1");

        // Video Handling
        const isVideo = type === 'video';
        const isImage = type === 'image';
        const maxVideos = Number.parseInt(SettingsService.getUiSetting('socialgrid_max_videos', '4'), 10);
        const shouldPlayVideo = isVideo && supportsVideo && typeCount <= maxVideos;
        const videoUrl = shouldPlayVideo && teslaCacheSocialGrid ? teslaCacheX(url) : url;

        // No media crop
        const videosNoCrop = SettingsService.hasTrueUiSetting('socialgrid_videos_no_crop');
        const imagesNoCrop = SettingsService.hasTrueUiSetting('socialgrid_images_no_crop');
        const noCrop = supportsNoCrop && ((isVideo && videosNoCrop) || (isImage && imagesNoCrop));
        const mediaClass = noCrop ? 'no-crop' : '';
        const backgroundClass = supportsBlurBackground ? ' blur' : ' opacity';

        return (
            <div
                ref={this.setContainerRef}
                className="TileFace"
            >
                {noCrop && (
                    <img
                        className={`background ${backgroundClass}`}
                        src={teslaCacheX(thumbnail)}
                        alt=""
                    />
                )}

                {shouldPlayVideo ? (
                    <video
                        className={mediaClass}
                        ref={el => this.video = el}
                        onError={this.onContentError}
                        src={videoUrl}
                        loop={true}
                        muted={true}
                    />
                ) : (
                    <img
                        className={mediaClass}
                        src={teslaCacheX(thumbnail)}
                        onError={this.onContentError}
                        alt=""
                    />
                )}

            </div>
        );

    }
}

TileFace.propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['image', 'video']).isRequired,
    thumbnail: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    typeCount: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    containerRef: PropTypes.func.isRequired,
};

export default TileFace;